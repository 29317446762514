import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';
import { Alert } from 'src/app/models/alert.model';

@Component({
  selector: 'app-panelpayment',
  templateUrl: './panelpayment.component.html',
  styleUrls: ['./panelpayment.component.scss']
})
export class PanelpaymentComponent implements OnInit {

  userData: UsersData.User = {};
  histories: UsersData.HistoryBalance[] = [];
  clients: UsersData.UserData[] = [];
  alerts: Alert[] = [];
  currentPrice: string = '';

  payments: any[] = [
	{
		icon: "/assets/img/platnosci_ikona_1.png",
		price: "7,49 zł"
	},
	{
		icon: "/assets/img/platnosci_ikona_2.png",
		price: "6,99 zł"
	},
	{
		icon: "/assets/img/platnosci_ikona_3.png",
		price: "6,49 zł"
	},
	{
		icon: "/assets/img/platnosci_ikona_4.png",
		price: "5,99 zł"
	},
	{
		icon: "/assets/img/platnosci_ikona_5.png",
		price: "5,49 zł"
	},
	{
		icon: "/assets/img/platnosci_ikona_6.png",
		price: "4,99 zł"
	}
  ];

  constructor(private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) {
	this.getUserData();
	if(!this.cookieService.get('sessionToken'))
        this.router.navigate(['/logowanie']);
  }

  ngOnInit(): void {
  }

  getUserData(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getUserData(token).subscribe(
	  (userData: UsersData.UserData) => {
		_self.userData.user = userData;
		_self.getHistoryBalance();
		// _self.getClients();
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  getHistoryBalance(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getHistoryBalance(this.userData.user.id, token).subscribe(
	  (histories: UsersData.HistoryBalance[]) => {
		_self.histories = histories;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  getClients(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getClients(token).subscribe(
	  (clients: UsersData.UserData[]) => {
		_self.clients = clients;
		if(clients.length < 2)
			_self.currentPrice = (clients.length*7.49)+' zł / miesiąc';
		else if(clients.length < 3)
			_self.currentPrice = (clients.length*6.99)+' zł / miesiąc';
		else if(clients.length < 4)
			_self.currentPrice = (clients.length*6.49)+' zł / miesiąc';
		else if(clients.length < 5)
			_self.currentPrice = (clients.length*5.99)+' zł / miesiąc';
		else if(clients.length < 6)
			_self.currentPrice = (clients.length*5.49)+' zł / miesiąc';
		else
			_self.currentPrice = (clients.length*4.99)+' zł / miesiąc';
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { UsersData } from 'src/app/models/users.namespace';
import { LoginService } from 'src/app/client/services/login/login.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isLogged: boolean = false;
  userData: UsersData.User = {};
  socials: UsersData.Socials = {
	'facebook': '',
	'twitter': '',
	'instagram': '',
	'youtube': ''
  };

  constructor(private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) {
	if(this.cookieService.get( 'sessionToken')) {
		this.isLogged = true;
		this.getUserData();
	}
	this.getSocials();
  }
  
  getSocials() {
    var _self = this;
    this._loginService.getSocials().subscribe(
		(socials: UsersData.Socials) => {
			_self.socials = socials;
		},
	    (error: HttpErrorResponse) => {
		},
    );
  }

  getUserData(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getUserData(token).subscribe(
	  (userData: UsersData.UserData) => {
		_self.userData.user = userData;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  ngOnInit(): void {
  }
  
  goToTrainerPanel(): void {
  	if(this.isLogged && this.userData?.user?.isTrainer)
		this.router.navigate(['/panel/trener/lista']);
	else
		this.router.navigate(['/logowanie']);
  }
  
  goToClientPanel(): void {
  	if(this.isLogged && !this.userData?.user?.isTrainer)
		this.router.navigate(['/panel/podopieczny/mojakarta']);
	else
		this.router.navigate(['/logowanie']);
  }
}

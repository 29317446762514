import { Component, OnInit } from '@angular/core';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';
import {MatDialog} from '@angular/material/dialog';
import { ConfirmdialogComponent } from 'src/app/client/page/components/confirmdialog/confirmdialog.component';
import { MessagedialogComponent } from '../components/messagedialog/messagedialog.component';
import { Alert } from 'src/app/models/alert.model';

@Component({
  selector: 'app-paneladmincontents',
  templateUrl: './paneladmincontents.component.html',
  styleUrls: ['./paneladmincontents.component.scss']
})
export class PaneladmincontentsComponent implements OnInit {
	
  displayedColumns: string[] = ['id', 'name', 'actions'];
  contents: UsersData.Content[] = [];
  alerts: Alert[] = [];

  constructor(private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) {
	if(!this.cookieService.get('sessionToken') || (!this.cookieService.get('sessionFA') || this.cookieService.get('sessionFA') !== '1'))
		this.router.navigate(['/logowanie']);
  }

  ngOnInit(): void {
	this.getContents();
  }
  
  getContents() {
    var _self = this;
    this._loginService.getContents().subscribe(
		(contents: UsersData.Content[]) => {
			_self.contents = contents;
		},
	    (error: HttpErrorResponse) => {
		},
    );
  }

}

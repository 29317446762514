import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({  providedIn: 'root'})
export class TranslationService {
  constructor(private translateService: TranslateService) {}
  
  init(locale = 'pl') {
    this.translateService.addLangs(['pl']);
    this.translateService.use(locale);
  }
}
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';
import {MatDialog} from '@angular/material/dialog';
import { IngredientdialogComponent } from '../components/ingredientdialog/ingredientdialog.component';
import { MealdialogComponent } from '../components/mealdialog/mealdialog.component';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { ConfirmdialogComponent } from 'src/app/client/page/components/confirmdialog/confirmdialog.component';
import { CopydietdialogComponent } from 'src/app/client/page/components/copydietdialog/copydietdialog.component';
import { Alert } from 'src/app/models/alert.model';

@Component({
  selector: 'app-paneltrainerdiet',
  templateUrl: './paneltrainerdiet.component.html',
  styleUrls: ['./paneltrainerdiet.component.scss']
})
export class PaneltrainerdietComponent implements OnInit {

  clientid: number = 0;
  client: UsersData.UserData = {};
  serie: UsersData.Serie = {};
  serieStart: string = '';
  serieEnd: string = '';
  listOfDate: string[] = [];
  numbers: number[] = [];
  ingredients: UsersData.Ingredient[] = [];
  originalIngredients: UsersData.Ingredient[] = [];
  meals: UsersData.Meal[] = [];
  mealsToSave: UsersData.Meal[] = [];
  public mealsForm: FormGroup;
  seriesDates: UsersData.SeriesDatesItem[] = [];
  alerts: Alert[] = [];
  adminIngredients: UsersData.Ingredient[] = [];
  selectedIngredientData: number = 0;
  typeOfMeal: number = 1;
  width: number = 210;
  tabs: UsersData.SerieTime[] = [];

  constructor(public dialog: MatDialog, private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) {
	this.mealsForm = new FormGroup({
      'type': new FormControl('1', [])
    });
	var _self = this;
    this.activatedRoute.params.subscribe(params => {
      _self.clientid = params['clientid'];
    });
    if(!this.cookieService.get('sessionToken'))
        this.router.navigate(['/logowanie']);
    this.width = this.convertRemToPixels(20);
  }

  convertRemToPixels(rem: number): number {    
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }

  getTabs(): void {
	this.tabs = [];
	for(var i = 0; i < this.serie?.serietime_list.length; i++) {
		if(this.serie?.serietime_list[i].number !== this.serie?.number && this.tabs.length < 4)
			this.tabs.push(this.serie?.serietime_list[i]);
	}
  }

  getSeriesDatesItem(number: number): string {
	for(var i = 0; i < this.seriesDates.length; i++) {
		if(this.seriesDates[i].number == number)
			return this.seriesDates[i].date;
	}
	return '';
  }

  ngOnInit(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getClientData(this.clientid, token).subscribe(
	  (client: UsersData.UserData) => {
		_self.client = client;
		if(!_self.client.trainingstart || parseInt(_self.cookieService.get('sessionNumber')) != _self.client.idTrainer)
        	_self.router.navigate(['/panel/profil']);
		_self.getSeriesDates();
		_self.getDimensions(_self.client?.defaultSerie ? _self.client?.defaultSerie : 1);
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  getSeriesDates(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken')
	this._loginService.getSeriesDates(token, this.clientid).subscribe(
	  (seriesDates: UsersData.SeriesDatesItem[]) => {
		_self.seriesDates = seriesDates;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }
  
  change(): void {
	this.getIngredients();
  }

  changeSerie(serie: number): void {
	this.getDimensions(serie);
  }

  getDimensions(serie: number): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken')
	this._loginService.getDimensions(serie, this.clientid, token).subscribe(
	  (serie: UsersData.Serie) => {
		_self.serie = serie;
		_self.numbers = Array(_self.serie.serietime).fill(_self.serie.serietime).map((x,i)=>i);
		_self.listOfDate = [];
		if(_self.client?.trainingstart && serie?.number && _self.serie?.serietime)
			for(var i = 0; i < _self.numbers.length; i++) {
				var date = new Date(_self.client?.trainingstart);
				date.setDate(date.getDate() + (i+((serie.number-1)*_self.serie.serietime)));
				_self.listOfDate.push(date.getDate()+'.'+(date.getMonth()+1));
				if(i == 0)
					_self.serieStart = date.getDate()+'.'+(date.getMonth()+1);
				if(i == _self.numbers.length-1)
					_self.serieEnd = date.getDate()+'.'+(date.getMonth()+1);
			}
			if(_self.serie?.id)
				_self.getMeals();
		_self.getTabs();
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  getMeals() {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	if(this.serie?.id)
		this._loginService.getMeals(token, this.serie?.id).subscribe(
		  (meals: UsersData.Meal[]) => {
			_self.meals = meals;
			if(_self.serie?.number) {
				_self.getIngredients();
				_self.getModeratorIngredients();
			}
		  },
		  (error: HttpErrorResponse) => {
			
		  },
		);
  }

  getIngredients() {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getIngredients(token, this.typeOfMeal).subscribe(
	  (ingredients: UsersData.Ingredient[]) => {
		_self.ingredients = ingredients;
		_self.originalIngredients = ingredients;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  getModeratorIngredients() {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getModeratorIngredients(token, this.typeOfMeal).subscribe(
	  (ingredients: UsersData.Ingredient[]) => {
		_self.adminIngredients = ingredients;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  deleteAdminIngredient(id: number) {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.deleteIngredient(id, token).subscribe(
	  (messageFromAPI: UsersData.MessageFromAPI) => {
		if(messageFromAPI.result === 'success') {
			_self.alerts = [];
		  	_self.alerts.push({message: messageFromAPI.message, type: 'success'});
			_self.getMeals();
	  		window.scroll(0,0);
		}
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  addIngredient() {
	var _self = this;
    var ingredient = {
		type: this.typeOfMeal
	};
  	const dialogRef = this.dialog.open(IngredientdialogComponent, {
      data: ingredient
    });
  	dialogRef.afterClosed().subscribe(result => {
		if(this.serie?.number) {
			_self.getIngredients();
			_self.getModeratorIngredients();
		}
    });
  }

  editMeal(meal: UsersData.Meal) {
	var _self = this;
	meal.clientid = this.clientid;
	meal.serieid = this.serie.id;
  	const dialogRef = this.dialog.open(MealdialogComponent, {
      data: meal
    });
  	dialogRef.afterClosed().subscribe(result => {
		if(this.serie?.id)
			_self.getMeals();
    });
  }

  addMeal(seriedate: number) {
	var _self = this;
    var meal = {
		seriedate: seriedate,
		serieid: this.serie.id,
		clientid: this.clientid,
		position: (this.meals.length ? this.meals.length : 0)
	};
  	const dialogRef = this.dialog.open(MealdialogComponent, {
      data: meal
    });
  	dialogRef.afterClosed().subscribe(result => {
		if(this.serie?.id) {
			if(this.meals.length > 0)
				_self.saveChanges()
			else
				_self.getMeals();
		}
    });
  }

  getMealsForSerieDate(seriedate: number): UsersData.Meal[] {
	var meals: UsersData.Meal[] = [];
	if(this.meals)
		for(var i = 0; i < this.meals.length; i++) {
			if(this.meals[i].seriedate == seriedate) {
				meals.push(this.meals[i]);
			}
		}
	return meals;
  }

  getMealsForSerieDateEmptyCount(seriedate: number): number {
	var meals: UsersData.Meal[] = [];
	if(this.meals)
		for(var i = 0; i < this.meals.length; i++) {
			if(this.meals[i].seriedate == seriedate) {
				meals.push(this.meals[i]);
			}
		}
	return (3 - meals.length > 0 ? 3 - meals.length : 0);
  }

  itemChange(id: number, value: string) {
	for(var i = 0; i < this.meals.length; i++) {
		if(this.meals[i].id == id) {
			this.meals[i].type = parseInt(value);
			break;
		}
	}
  }

  drop(event: CdkDragDrop<UsersData.Ingredient[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
    this.getIngredients();
    this.getModeratorIngredients();
  }
  
  deleteIngredient(meal_index: number, ingredient_id: number, seriedate: number) {
    var index = 0;
	if(this.meals)
		for(var j = 0; j < this.meals.length; j++) {
			if(this.meals[j].seriedate == seriedate) {
				if(index == meal_index) {
				  var mealingredients: UsersData.MealIngredient[] = [];
				  if(typeof this.meals[j].ingredients !== 'undefined')
					  for(var i = 0; i < (this.meals[j]?.ingredients?.length || 0); i++) {
						  if((this.meals[j]?.ingredients[i]?.id || 0) !== ingredient_id)
							  mealingredients.push(this.meals[j]?.ingredients[i]);
					  }
				  this.meals[j].ingredients = mealingredients;
				}
				else 
					index++;
			}
		}
  }
  
  addIngredientFromList(selectedIngredientData: number) {
	  if(selectedIngredientData && selectedIngredientData > 0) {
		  var newIngredients: UsersData.Ingredient[] = [];
  		  newIngredients.push(this.adminIngredients[selectedIngredientData-1]);
		  for(var i = 0; i < this.originalIngredients.length; i++) {
	  		  newIngredients.push(this.originalIngredients[i]);
		  }
		  this.ingredients = newIngredients;
	  }
  }

  getForMeal(meal: UsersData.Meal, ingredient_type: number): number {
	var value = 0;
	if(meal.ingredients)
		for(var i = 0; i < meal.ingredients.length; i++) {
			if(ingredient_type == 1)
				value += meal.ingredients[i].kcal || 0;
			else if(ingredient_type == 2)
				value += meal.ingredients[i].protein || 0;
			else if(ingredient_type == 3)
				value += meal.ingredients[i].carbohydrates || 0;
			else
				value += meal.ingredients[i].fat || 0;
		}
	return value;
  }

  getForMeals(meals: UsersData.Meal[], ingredient_type: number): number {
	var value = 0;
	for(var i = 0; i < meals.length; i++) {
		if(meals[i].ingredients)
			// @ts-ignore: Object is possibly 'null'.
			for(var j = 0; j < meals[i].ingredients.length; j++) {
				if(ingredient_type == 1)
				// @ts-ignore: Object is possibly 'null'.
					value += meals[i].ingredients[j].kcal || 0;
				else if(ingredient_type == 2)
				// @ts-ignore: Object is possibly 'null'.
					value += meals[i].ingredients[j].protein || 0;
				else if(ingredient_type == 3)
				// @ts-ignore: Object is possibly 'null'.
					value += meals[i].ingredients[j].carbohydrates || 0;
				else
				// @ts-ignore: Object is possibly 'null'.
					value += meals[i].ingredients[j].fat || 0;
			}
	}
	return value;
  }

  copyDiet() {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
  	const dialogRef = this.dialog.open(CopydietdialogComponent, {
      data: {serieid: _self.serie?.id, serieNumber: _self.serie?.number, tabs: _self.tabs}
    });
  	dialogRef.afterClosed().subscribe(result => {
		if(result) {
		  	this.alerts = [];
			this.alerts.push({message: 'Cykl skopiowany poprawnie', type: 'success'});
	  		window.scroll(0,0);
		}
    });
  }

  clearDiet() {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
    var confirm_item = {
		title: "Czy na pewno chcesz wyczyścić cykl ?"
	};
  	const dialogRef = this.dialog.open(ConfirmdialogComponent, {
      data: confirm_item
    });
  	dialogRef.afterClosed().subscribe(result => {
		if(result) {
			this._loginService.clearDiet(token, _self.serie?.id || 0).subscribe(
			  (messageFromAPI: UsersData.MessageFromAPI) => {
				if(this.serie?.number)
					_self.getDimensions(this.serie.number);
			  },
			  (error: HttpErrorResponse) => {
				
			  },
			);
		}
    });
  }

  saveChanges() {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.updateMeals(token, this.meals, (this.serie.id ? this.serie.id : 0)).subscribe(
      (messageFromAPI: UsersData.MessageFromAPI) => {
  		_self.alerts = [];
  		_self.alerts.push({message: 'Zmiany zostały zapisane', type: 'success'});
  		window.scroll(0,0);
		_self.getMeals();
      },
      (error: HttpErrorResponse) => {

      },
    );
  }

  onSubmit(event: any): void {
	
  }
}

<div class="confirmdialog">
	<div class="row">
		<div class="col-sm-12">
			<div class="confirmdialog-content">
				<div class="confirmdialog-form">
					<h1 class="confirmdialog-form-title">
						{{ confirm_item.title }}
					</h1>
					<div class="col-sm-12">
						<button class="confirmdialog-form-button" type="button" (click)="closeDialogAndAccept()">Tak</button>
						<button class="confirmdialog-form-button" type="button" (click)="closeDialog()">Nie</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="practicedialog">
	<div class="row">
		<div class="col-sm-12">
			<div class="practicedialog-content">
				<form class="practicedialog-form" [formGroup]="practiceForm" (ngSubmit)="onSubmit($event)">
					<h1 class="practicedialog-form-title">
						Dodaj ćwiczenie
					</h1>
					<div class="col-sm-12 practicedialog-form-field-textarea">
						<label class="practicedialog-form-input-label">Nazwa ćwiczenia</label>
						<input formControlName="name" name="name" class="practicedialog-form-input">
					</div>
					<div class="col-sm-12">
						<button class="practicedialog-form-button" type="submit">dodaj</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
<div class="container">
	<div class="row">
		<div class="col-sm-12">
			<app-page-header></app-page-header>
		</div>
		<div class="col-sm-12">
			<div class="row content">
				<app-alert [alerts]="alerts" style="width: 100%;"></app-alert>
				<img class="login-background" src="/assets/img/logowanie.jpg" alt="image"/>
				<div class="login-text">
					<span class="login-text-logo">FITBOOK.pro </span><span class="login-text-normal">to doskonałe narzędzie<br/>dla trenerów personalnych i podopiecznych,<br/></span>
					<span class="login-text-bold">trening nigdy nie był prostszy!<br/></span>
				</div>
				<a [routerLink]="['/rejestracja']" class="login-button"><span class="login-button-link">zarejestruj się</span></a>
				<div class="login-content">
					<form class="login-form" *ngIf="!remind_password && !change_password && !change_password_code" [formGroup]="loginForm" (ngSubmit)="onSubmit($event)">
						<div class="login-form-title">
							<img class="login-logo" src="/assets/img/logo_logowanie.jpg" alt="image"/>
						</div>
						<div class="col-sm-12 login-form-field">
							<mat-form-field class="login-form-input" floatLabel="never">
								<span matPrefix><img class="login-form-icon" src="/assets/img/login_icon.png" alt="image"/></span>
								<input formControlName="login" type="text" name="login" matInput placeholder="login">
							</mat-form-field>
						</div>
						<div class="col-sm-12 login-form-field">
							<mat-form-field class="login-form-input" floatLabel="never">
								<span matPrefix><img class="login-form-icon" src="/assets/img/password_icon.png" alt="image"/></span>
								<input formControlName="password" type="password" name="password" matInput placeholder="hasło">
							</mat-form-field>
						</div>
						<div class="col-sm-12 login-form-field">
							<mat-slide-toggle>zapamiętaj mnie</mat-slide-toggle>
						</div>
						<div class="col-sm-12">
							<button class="login-form-button" type="submit">zaloguj się</button>
						</div>
						<div class="col-sm-12 text-center" (click)="remindPassword()">
							<a class="login-form-link">przypomnij hasło</a>
						</div>
					</form>
					<form class="login-form" *ngIf="remind_password" [formGroup]="remindPasswordForm" (ngSubmit)="onSubmitRemindPassword($event)">
						<div class="login-form-title">
							<img class="login-logo" src="/assets/img/logo.jpg" alt="image"/>
						</div>
						<div class="col-sm-12 login-form-field">
							<mat-form-field class="login-form-input" floatLabel="never">
								<span matPrefix><img class="login-form-icon" src="/assets/img/login_icon.png" alt="image"/></span>
								<input formControlName="login" type="text" name="login" matInput placeholder="login">
							</mat-form-field>
						</div>
						<div class="col-sm-12">
							<button class="login-form-button" type="submit">przypomnij hasło</button>
						</div>
					</form>
					<form class="login-form" *ngIf="change_password_code" [formGroup]="codeForm" (ngSubmit)="onCheckCode($event)">
						<div class="login-form-title">
							<img class="login-logo" src="/assets/img/logo.jpg" alt="image"/>
						</div>
						<div class="col-sm-12 login-form-field">
							<mat-form-field class="login-form-input" floatLabel="never">
								<span matPrefix><img class="login-form-icon" src="/assets/img/login_icon.png" alt="image"/></span>
								<input formControlName="code" type="text" name="code" matInput placeholder="wpisz kod z e-maila">
							</mat-form-field>
						</div>
						<div class="col-sm-12">
							<button class="login-form-button" type="submit">zmień hasło</button>
						</div>
					</form>
					<form class="login-form" *ngIf="change_password" [formGroup]="passwordForm" (ngSubmit)="onChangePassword($event)">
						<div class="login-form-title">
							<img class="login-logo" src="/assets/img/logo.jpg" alt="image"/>
						</div>
						<div class="col-sm-12 login-form-field">
							<mat-form-field class="login-form-input" floatLabel="never">
								<span matPrefix><img class="login-form-icon" src="/assets/img/login_icon.png" alt="image"/></span>
								<input formControlName="password" type="text" name="password" matInput placeholder="hasło">
							</mat-form-field>
						</div>
						<div class="col-sm-12 login-form-field">
							<mat-form-field class="login-form-input" floatLabel="never">
								<span matPrefix><img class="login-form-icon" src="/assets/img/login_icon.png" alt="image"/></span>
								<input formControlName="repeatpassword" type="text" name="repeatpassword" matInput placeholder="powtórz hasło">
							</mat-form-field>
						</div>
						<div class="col-sm-12">
							<button class="login-form-button" type="submit">zmień hasło</button>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div class="col-sm-12 px-0">
			<app-page-footer></app-page-footer>
		</div>
	</div>
</div>
import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Alert } from 'src/app/models/alert.model';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';

@Component({
  selector: 'app-seriedatedialog',
  templateUrl: './seriedatedialog.component.html',
  styleUrls: ['./seriedatedialog.component.scss']
})
export class SeriedatedialogComponent implements OnInit {
	
  public seriedateForm: FormGroup;
  seriedate: UsersData.Seriedate;

  constructor(private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService, public dialogRef: MatDialogRef<SeriedatedialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UsersData.Seriedate) {
    this.seriedate = data;
    this.seriedateForm = new FormGroup({
      'numberOfDay': new FormControl('', [
        
      ])
    });
  }

  ngOnInit(): void {
	  this.seriedateForm.controls['numberOfDay'].setValue(this.seriedate?.numberOfDay);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit(event: any): void {
    var _self = this;
    this.seriedate.numberOfDay = this.seriedateForm.value.numberOfDay;
	this._loginService.addSerieDate(this.cookieService.get( 'sessionToken'), this.seriedate.numberOfDay, this.seriedate.serie.id).subscribe(
      (messageFromAPI: UsersData.MessageFromAPI) => {
		this.dialogRef.close();
      },
      (error: HttpErrorResponse) => {

      },
    );
  }
}

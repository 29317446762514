<div class="mealdialog">
	<div class="row">
		<div class="col-sm-12">
			<div class="mealdialog-content">
				<form class="mealdialog-form" [formGroup]="seriedateForm" (ngSubmit)="onSubmit($event)">
					<h1 class="mealdialog-form-title">
						Dodaj posiłek
					</h1>
					<div class="col-sm-12 mealdialog-form-field-textarea">
						<label class="mealdialog-form-input-label">Data</label>
						<select formControlName="numberOfDay" class="mealdialog-form-select">
							<option *ngFor="let seriedateitem of data.seriedateitems;" [value]="seriedateitem.numberOfDay">{{ seriedateitem.date }}</option>
						</select>
					</div>
					<div class="col-sm-12">
						<button *ngIf="!seriedate?.id" class="mealdialog-form-button" type="submit">dodaj</button>
						<button *ngIf="seriedate?.id" class="mealdialog-form-button" type="submit">zapisz</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
<div class="invitedialog">
	<div class="row">
		<div class="col-sm-12">
			<div class="invitedialog-content">
				<form class="invitedialog-form" [formGroup]="inviteForm" (ngSubmit)="onSubmit($event)">
					<h1 class="invitedialog-form-title">
						Wyślij zaproszenie
					</h1>
					<div class="col-sm-12 invitedialog-form-field-textarea">
						<label class="invitedialog-form-input-label">Treść wiadomości</label>
						<textarea formControlName="message" name="message" class="invitedialog-form-input" rows="5"></textarea>
					</div>
					<div class="col-sm-12">
						<button class="invitedialog-form-button" type="submit">wyślij</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
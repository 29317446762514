import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Alert } from 'src/app/models/alert.model';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';

@Component({
  selector: 'app-supplementdialog',
  templateUrl: './supplementdialog.component.html',
  styleUrls: ['./supplementdialog.component.scss']
})
export class SupplementdialogComponent implements OnInit {

  public supplementForm: FormGroup;
  supplement: UsersData.Supplement;
  alerts: Alert[] = [];

  constructor(private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService, public dialogRef: MatDialogRef<SupplementdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UsersData.Supplement) {
    this.supplement = data;
    this.supplementForm = new FormGroup({
      'name': new FormControl('', [
        Validators.required
      ]),
      'grams': new FormControl('', [
        Validators.required
      ]),
      'times': new FormControl('', [
        Validators.required
      ]),
      'comment': new FormControl('', [
      ])
    });
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit(event: any): void {
    var _self = this;
  	this.alerts = [];
	if (this.supplementForm.invalid) {
		this.alerts.push({message: 'Prosimy uzupełnić wymagane pola', type: 'danger'});
	  return;
	}
    this.supplement.name = this.supplementForm.value.name;
    this.supplement.grams = this.supplementForm.value.grams;
    this.supplement.times = this.supplementForm.value.times;
    this.supplement.comment = this.supplementForm.value.comment;
	this._loginService.addSupplement(this.cookieService.get( 'sessionToken'), this.supplement).subscribe(
      (messageFromAPI: UsersData.MessageFromAPI) => {
		this.dialogRef.close();
      },
      (error: HttpErrorResponse) => {

      },
    );
  }
}

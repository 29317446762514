import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Alert } from 'src/app/models/alert.model';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';

@Component({
  selector: 'app-qualificationdialog',
  templateUrl: './qualificationdialog.component.html',
  styleUrls: ['./qualificationdialog.component.scss']
})
export class QualificationdialogComponent implements OnInit {

	public qualificationForm: FormGroup;
	qualification: UsersData.Qualification;
	alerts: Alert[] = [];
	
	constructor(private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService, public dialogRef: MatDialogRef<QualificationdialogComponent>,
	  @Inject(MAT_DIALOG_DATA) public data: UsersData.Qualification) {
	  this.qualification = data;
	  this.qualificationForm = new FormGroup({
	    'name': new FormControl(this.qualification?.name, [
	      Validators.required
	    ])
	  });
	}
	
	ngOnInit(): void {
	}
	
	closeDialog(qualification: UsersData.Qualification) {
	  this.dialogRef.close(qualification);
	}
	
	onSubmit(event: any): void {
		this.alerts = [];
		if (this.qualificationForm.invalid) {
			let formErrors = this.getErrors(this.qualificationForm);
			if(formErrors?.value && formErrors?.value?.required)
				this.alerts.push({message: 'Prosimy wypełnić wszystkie pola', type: 'danger'});
		  return;
		}
	  this.qualification.name = this.qualificationForm.value.name;
	  this.closeDialog(this.qualification);
	}
	
	getErrors = (formGroup: FormGroup, errors: any = {}) => {
		  Object.keys(formGroup.controls).forEach(field => {
		    const control = formGroup.get(field);
		    if (control instanceof FormControl) {
		      errors[field] = control.errors;
		    } else if (control instanceof FormGroup) {
		      errors[field] = this.getErrors(control);
		    }
		  });
		  return errors;
	}

}

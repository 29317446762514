import { Component, OnInit } from '@angular/core';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginService } from 'src/app/client/services/login/login.service';

@Component({
  selector: 'app-social-icons',
  templateUrl: './social-icons.component.html',
  styleUrls: ['./social-icons.component.scss']
})
export class SocialIconsComponent implements OnInit {

  socials: UsersData.Socials = {
	'facebook': '',
	'twitter': '',
	'instagram': '',
	'youtube': ''
  };

  constructor(private _loginService: LoginService) { }

  ngOnInit(): void {
	  this.getSocials();
  }
  
  getSocials() {
    var _self = this;
    this._loginService.getSocials().subscribe(
		(socials: UsersData.Socials) => {
			_self.socials = socials;
		},
	    (error: HttpErrorResponse) => {
		},
    );
  }

}

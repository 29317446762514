<div class="container">
	<div class="row">
		<div class="col-sm-12">
			<app-page-header></app-page-header>
			<div class="breadcrumb">
				<div class="breadcrumb-content">
					<a [routerLink]="['/']"><span class="breadcrumb-content-main">Strona główna</span></a>
					<span class="breadcrumb-content-separator">></span>
					<span class="breadcrumb-content-page">Baza trenerów</span>
				</div>
			</div>
		</div>
	</div>
	<div class="row d-flex flex-column flex-xl-row flex-nowrap">	
		<div class="col-12 col-xl-2 clientslist-column-filters">
			<app-filters [isTrainerList]="false" (changeFilters)="changeFilters($event)"></app-filters>
		</div>
		<div class="col-12 col-xl-10 clientslist-column-content">
			<div>
				<div class="clientslist col-sm-12">
					<div class="clientslist-title">
						<h1 class="clientslist-title-h1">Oferta dla trenera</h1>
						<div class="clientslist-title-pagination">
							<select id="sortBy" name="sortBy" (change)="onChangeOrder($event.target.value)" class="clientslist-title-pagination-sort">
								<option [value]="'datecreatestamp:DESC'">od najnowszych</option>
								<option [value]="'datecreatestamp:ASC'">od najstarszych</option>
								<option [value]="'firstname:ASC'">od A do Z</option>
								<option [value]="'firstname:DESC'">od Z do A</option>
							</select>
							<div class="clientslist-title-pagination-pages">
								<span (click)="changePage(1)" *ngIf="(currentPage) > 1" class="clientslist-title-pagination-page">1</span>
								<span *ngIf="(currentPage) > 1" class="clientslist-title-pagination-page-arrows">&lt;&lt;</span>
								<span (click)="changePage(currentPage-1)" *ngIf="(currentPage) > 2" class="clientslist-title-pagination-page">{{currentPage-1}}</span>
								<span *ngIf="(currentPage) > 2" class="clientslist-title-pagination-page-arrows">&lt;</span>
								<span class="clientslist-title-pagination-page-current">{{currentPage}}</span>
								<span *ngIf="pages > (currentPage+1)" class="clientslist-title-pagination-page-arrows">&gt;</span>
								<span (click)="changePage(currentPage+1)" *ngIf="pages > (currentPage+1)" class="clientslist-title-pagination-page">{{currentPage+1}}</span>
								<span *ngIf="pages > currentPage" class="clientslist-title-pagination-page-arrows">&gt;&gt;</span>
								<span (click)="changePage(pages)" *ngIf="pages > currentPage" class="clientslist-title-pagination-page">{{pages}}</span>
							</div>
						</div>
					</div>
					<div class="clientslist-table row">
						<app-alert [alerts]="alerts" style="width: 100%;"></app-alert>
						<div *ngFor="let item of clients" class="col-sm-12 clientslist-table-item-content">
							<div class="clientslist-table-item">
								<div class="row">
									<div class="col-sm-12 col-xl-7 clientslist-table-item-left">
										<div class="clientslist-table-item-photo">
											<img class="clientslist-table-item-photo-img" *ngIf="item.photo" src="{{item.photo}}"/>
											<img class="clientslist-table-item-photo-img" *ngIf="!item.photo" src="/assets/img/default_img.svg"/>
										</div>
										<div class="clientslist-table-item-info row">
											<div class="clientslist-table-item-info-name col-sm-12"><span class="clientslist-table-item-info-name-span">{{item.firstname}} {{item.lastname}}</span></div>
											<div class="col-sm-12 text-left px-0">
												<div class="clientslist-table-item-info-id"><span class="clientslist-table-item-info-id-span"><strong>ID</strong>: {{item.id}}</span></div>
											</div>
											<div class="clientslist-table-item-info-desc col-sm-12"><span class="clientslist-table-item-info-desc-span">{{item.short_description}}</span></div>
										</div>
									</div>
									<div class="col-sm-12 col-xl-5 clientslist-table-item-right">
										<div class="row clientslist-table-item-info-row">
											<div class="col-sm-2 clientslist-table-item-info-column-padding"></div>
											<div class="col-sm-5 clientslist-table-item-info-column-left">
												<div class="clientslist-table-item-info-additional" *ngIf="item.gender">
													<svg class="clientslist-table-item-info-additional-svg-male" *ngIf="item.gender === 'male'" id="Capa_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m276.956 0v57.674h136.589l-101.389 101.389c-32.544-24.144-72.837-38.431-116.471-38.431-108.074 0-195.685 87.61-195.685 195.684 0 108.073 87.611 195.684 195.684 195.684s195.684-87.611 195.684-195.684c0-43.634-14.287-83.928-38.431-116.472l101.389-101.388v136.589h57.674v-235.045zm-81.272 447.552c-72.48 0-131.237-58.757-131.237-131.237s58.757-131.237 131.237-131.237 131.237 58.757 131.237 131.237c0 72.481-58.757 131.237-131.237 131.237z"/></g></svg>
													<svg class="clientslist-table-item-info-additional-svg-female" *ngIf="item.gender !== 'male'" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"><g><g><path d="M376.264,290.173c66.314-66.293,66.314-174.16,0-240.453c-66.314-66.294-174.214-66.294-240.529,0c-66.314,66.293-66.314,174.16,0,240.453c28.07,28.061,63.591,44.24,100.254,48.546v56.923h-40.018c-11.051,0-20.009,8.956-20.009,20.003s8.958,20.003,20.009,20.003h40.018v56.348c0.001,11.047,8.959,20.003,20.011,20.003c11.051,0,20.009-8.956,20.009-20.003v-56.348h40.019c11.051,0,20.009-8.956,20.009-20.003s-8.958-20.003-20.009-20.003h-40.019V338.72C312.673,334.413,348.194,318.234,376.264,290.173z M164.033,261.884c-50.711-50.695-50.711-133.181,0-183.876c50.71-50.693,133.221-50.696,183.934,0c50.711,50.695,50.711,133.181,0,183.876C297.256,312.578,214.744,312.578,164.033,261.884z"/></g></g></svg>
													<span class="clientslist-table-item-info-additional-span">{{item.gender === 'male' ? 'Mężczyzna' : 'Kobieta'}}, {{item.age}} lat</span>
												</div>
											</div>
											<div class="col-sm-5 clientslist-table-item-info-column-right">
												<div class="clientslist-table-item-info-address">
													<svg class="clientslist-table-item-info-address-svg" height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Pin-2" data-name="Pin"><path d="m25.0464 8.4834a10 10 0 0 0 -7.9116-5.4258 11.3644 11.3644 0 0 0 -2.2691 0 10.0027 10.0027 0 0 0 -7.9121 5.4253 10.8062 10.8062 0 0 0 1.481 11.8936l6.7929 8.2588a1 1 0 0 0 1.545 0l6.7929-8.2588a10.8055 10.8055 0 0 0 1.481-11.8931zm-9.0464 8.5166a4 4 0 1 1 4-4 4.0047 4.0047 0 0 1 -4 4z"/></g></svg>
													<span class="clientslist-table-item-info-address-span">{{item?.address?.city}}, {{item?.address?.state}}</span>
												</div>
											</div>
										</div>
										<ul class="clientslist-table-item-attrs">
											<li class="clientslist-table-item-attrs-attr">
												<div class="clientslist-table-item-attrs-attr-name">Miasto, w którym trenuję:</div>
												<div class="clientslist-table-item-attrs-attr-value">{{getPlaces(item?.attributes)}}</div>
											</li>
											<li class="clientslist-table-item-attrs-attr">
												<div class="clientslist-table-item-attrs-attr-name">Preferowane siłownie:</div>
												<div class="clientslist-table-item-attrs-attr-value">{{getGyms(item?.attributes)}}</div>
											</li>
											<li class="clientslist-table-item-attrs-attr">
												<div class="clientslist-table-item-attrs-attr-name">Trening online:</div>
												<div class="clientslist-table-item-attrs-attr-value">{{item.isOnline ? 'Tak' : 'Nie'}}</div>
											</li>
										</ul>
										<a class="clientslist-table-item-info-button-profile col-sm-6" (click)="goToClientPage(item.id || 0)">
											zobacz<br/>szczegóły
											<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
												viewBox="0 0 512.002 512.002" style="enable-background:new 0 0 512.002 512.002;" xml:space="preserve">
												<g>
													<g>
														<path d="M388.425,241.951L151.609,5.79c-7.759-7.733-20.321-7.72-28.067,0.04c-7.74,7.759-7.72,20.328,0.04,28.067l222.72,222.105
															L123.574,478.106c-7.759,7.74-7.779,20.301-0.04,28.061c3.883,3.89,8.97,5.835,14.057,5.835c5.074,0,10.141-1.932,14.017-5.795
															l236.817-236.155c3.737-3.718,5.834-8.778,5.834-14.05S392.156,245.676,388.425,241.951z"/>
													</g>
												</g>
											</svg>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-sm-12 px-0">
			<app-page-footer></app-page-footer>
		</div>
	</div>
</div>
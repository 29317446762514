import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Alert } from 'src/app/models/alert.model';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';

@Component({
  selector: 'app-invitedialog',
  templateUrl: './invitedialog.component.html',
  styleUrls: ['./invitedialog.component.scss']
})
export class InvitedialogComponent implements OnInit {

  public inviteForm: FormGroup;
  invite: UsersData.Invite;

  constructor(private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService, public dialogRef: MatDialogRef<InvitedialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UsersData.Invite) {
    this.invite = data;
    this.inviteForm = new FormGroup({
      'message': new FormControl('', [
        
      ])
    });
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  onSubmit(event: any): void {
    var _self = this;
    this.invite.message = this.inviteForm.value.message;
    this._loginService.invite(this.cookieService.get( 'sessionToken'), this.invite).subscribe(
      (invite: UsersData.Invite) => {
	    this.dialogRef.close(invite);
      },
      (error: HttpErrorResponse) => {

      },
    );
  }

}

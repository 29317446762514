<div class="container">
	<div class="row">
		<div class="col-sm-12">
			<app-page-header></app-page-header>
		</div>
		<app-alert [alerts]="alerts" style="width: 100%;"></app-alert>
		<div class="col-sm-12 px-0">
			<div class="paneltrainerlist-header">
				<div class="paneltrainerlist-header-photo">
					<img class="paneltrainerlist-header-photo-img" *ngIf="userData.user?.photo" src="{{userData.user?.photo}}"/>
					<img class="paneltrainerlist-header-photo-img" *ngIf="!userData.user?.photo" src="/assets/img/default_img.svg"/>
				</div>
				
				<div class="paneltrainerlist-header-title">
					<div class="paneltrainerlist-header-title-name"><span>{{userData.user?.firstname}} {{userData.user?.lastname}}</span></div>
					<div class="paneltrainerlist-header-title-info"><span>trener personalny</span></div>
				</div>
				<div class="paneltrainerlist-header-content">
					<div class="paneltrainerlist-header-content-info">
						<div class="paneltrainerlist-header-content-info-rating"><svg height="511pt" viewBox="0 -10 511.98685 511" width="511pt" xmlns="http://www.w3.org/2000/svg"><path d="m114.59375 491.140625c-5.609375 0-11.179688-1.75-15.933594-5.1875-8.855468-6.417969-12.992187-17.449219-10.582031-28.09375l32.9375-145.089844-111.703125-97.960937c-8.210938-7.167969-11.347656-18.519532-7.976562-28.90625 3.371093-10.367188 12.542968-17.707032 23.402343-18.710938l147.796875-13.417968 58.433594-136.746094c4.308594-10.046875 14.121094-16.535156 25.023438-16.535156 10.902343 0 20.714843 6.488281 25.023437 16.511718l58.433594 136.769532 147.773437 13.417968c10.882813.980469 20.054688 8.34375 23.425782 18.710938 3.371093 10.367187.253906 21.738281-7.957032 28.90625l-111.703125 97.941406 32.9375 145.085938c2.414063 10.667968-1.726562 21.699218-10.578125 28.097656-8.832031 6.398437-20.609375 6.890625-29.910156 1.300781l-127.445312-76.160156-127.445313 76.203125c-4.308594 2.558594-9.109375 3.863281-13.953125 3.863281zm141.398438-112.875c4.84375 0 9.640624 1.300781 13.953124 3.859375l120.277344 71.9375-31.085937-136.941406c-2.21875-9.746094 1.089843-19.921875 8.621093-26.515625l105.472657-92.5-139.542969-12.671875c-10.046875-.917969-18.6875-7.234375-22.613281-16.492188l-55.082031-129.046875-55.148438 129.066407c-3.882812 9.195312-12.523438 15.511718-22.546875 16.429687l-139.5625 12.671875 105.46875 92.5c7.554687 6.613281 10.859375 16.769531 8.621094 26.539062l-31.0625 136.9375 120.277343-71.914062c4.308594-2.558594 9.109376-3.859375 13.953126-3.859375zm-84.585938-221.847656s0 .023437-.023438.042969zm169.128906-.0625.023438.042969c0-.023438 0-.023438-.023438-.042969zm0 0"/></svg>{{userData.user?.rating}} / 5</div>
						<div class="paneltrainerlist-header-content-info-comments">
							<svg height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Layer_2" data-name="Layer 2"><path d="m12 1a10.984 10.984 0 0 0 -9.632 16.293l-1.326 4.42a1 1 0 0 0 .958 1.287 1.019 1.019 0 0 0 .288-.042l4.42-1.326a11 11 0 1 0 5.292-20.632zm0 20a8.966 8.966 0 0 1 -4.648-1.306 1.008 1.008 0 0 0 -.519-.144.973.973 0 0 0 -.287.042l-3.054.917.916-3.055a1 1 0 0 0 -.1-.8 8.992 8.992 0 1 1 7.692 4.346z"/></g></svg>
							<strong>{{userData.user?.reviews?.length}}</strong> opinii
						</div>
						<div class="paneltrainerlist-header-content-info-clients">{{clients.length || 0}} podopiecznych</div>
						<div class="paneltrainerlist-header-content-info-list">
							<a class="paneltrainerlist-header-content-info-list-messages" [routerLink]="['/panel/poczta']" matTooltip="wiadomości">
								<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
									 viewBox="0 0 477.867 477.867" style="enable-background:new 0 0 477.867 477.867;" xml:space="preserve">
								<g>
									<g>
										<path d="M426.667,0.002H51.2C22.923,0.002,0,22.925,0,51.202v273.067c0,28.277,22.923,51.2,51.2,51.2h60.587l-9.284,83.456
											c-1.035,9.369,5.721,17.802,15.09,18.837c4.838,0.534,9.674-1.023,13.292-4.279l108.919-98.014h186.863
											c28.277,0,51.2-22.923,51.2-51.2V51.202C477.867,22.925,454.944,0.002,426.667,0.002z M443.733,324.269
											c0,9.426-7.641,17.067-17.067,17.067H233.25c-4.217,0.001-8.284,1.564-11.418,4.386l-80.452,72.414l6.434-57.839
											c1.046-9.367-5.699-17.809-15.067-18.856c-0.63-0.07-1.263-0.106-1.897-0.105H51.2c-9.426,0-17.067-7.641-17.067-17.067V51.202
											c0-9.426,7.641-17.067,17.067-17.067h375.467c9.426,0,17.067,7.641,17.067,17.067V324.269z"/>
									</g>
								</g>
								</svg>
								<span class="count">{{countMessages}}</span>
							</a>
							<a class="paneltrainerlist-header-content-info-list-alerts" [routerLink]="['/panel/powiadomienia']" matTooltip="powiadomienia">
								<svg id="Capa_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m450.201 407.453c-1.505-.977-12.832-8.912-24.174-32.917-20.829-44.082-25.201-106.18-25.201-150.511 0-.193-.004-.384-.011-.576-.227-58.589-35.31-109.095-85.514-131.756v-34.657c0-31.45-25.544-57.036-56.942-57.036h-4.719c-31.398 0-56.942 25.586-56.942 57.036v34.655c-50.372 22.734-85.525 73.498-85.525 132.334 0 44.331-4.372 106.428-25.201 150.511-11.341 24.004-22.668 31.939-24.174 32.917-6.342 2.935-9.469 9.715-8.01 16.586 1.473 6.939 7.959 11.723 15.042 11.723h109.947c.614 42.141 35.008 76.238 77.223 76.238s76.609-34.097 77.223-76.238h109.947c7.082 0 13.569-4.784 15.042-11.723 1.457-6.871-1.669-13.652-8.011-16.586zm-223.502-350.417c0-14.881 12.086-26.987 26.942-26.987h4.719c14.856 0 26.942 12.106 26.942 26.987v24.917c-9.468-1.957-19.269-2.987-29.306-2.987-10.034 0-19.832 1.029-29.296 2.984v-24.914zm29.301 424.915c-25.673 0-46.614-20.617-47.223-46.188h94.445c-.608 25.57-21.549 46.188-47.222 46.188zm60.4-76.239c-.003 0-213.385 0-213.385 0 2.595-4.044 5.236-8.623 7.861-13.798 20.104-39.643 30.298-96.129 30.298-167.889 0-63.417 51.509-115.01 114.821-115.01s114.821 51.593 114.821 115.06c0 .185.003.369.01.553.057 71.472 10.25 127.755 30.298 167.286 2.625 5.176 5.267 9.754 7.861 13.798z"/></svg>
								<span class="count">{{countAlerts}}</span>
							</a>
						</div>
					</div>
				</div>
			</div>
			<div class="paneltrainerlist-left-column">
				<div class="paneltrainerlist-title">
					Twoi podopieczni
				</div>
				<div class="paneltrainerlist-table">
					<ul class="paneltrainerlist-table-header">
						<li class="paneltrainerlist-table-header-item">
						</li>
						<li class="paneltrainerlist-table-header-item">
							podopieczny/a
						</li>
						<li class="paneltrainerlist-table-header-item">
							termin raportu
						</li>
						<li class="paneltrainerlist-table-header-item">
							raport od podopiecznego
						</li>
						<li class="paneltrainerlist-table-header-item">
							dieta
						</li>
						<li class="paneltrainerlist-table-header-item">
							trening
						</li>
						<li class="paneltrainerlist-table-header-item">
							akcje
						</li>
					</ul>
					<ul class="paneltrainerlist-table-list" *ngFor="let client of clients">
						<li class="paneltrainerlist-table-list-item">
							<img src="{{client?.photo}}" />
						</li>
						<li class="paneltrainerlist-table-list-item clickable" (click)="goToClientSettings(client.id)">
							{{client?.firstname}} {{client?.lastname}}
						</li>
						<li class="paneltrainerlist-table-list-item">
							{{client?.report?.date}}
						</li>
						<li class="paneltrainerlist-table-list-item">
							<div class="paneltrainerlist-table-list-item-report" *ngIf="!client?.report?.sendReport">
								<svg height="365.696pt" viewBox="0 0 365.696 365.696" width="365.696pt" xmlns="http://www.w3.org/2000/svg"><path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0"/></svg>
								<span>niedostarczony</span>
							</div>
							<div class="paneltrainerlist-table-list-item-report send" *ngIf="client?.report?.sendReport">
								<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
									 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
									<g>
										<g>
											<path d="M416,16c-88.637,95.933-151.68,187.167-215.936,306.749C160.573,271.324,128.799,237.757,96,208H0
												c74.947,87.68,115.267,154.911,192,288h64c61.44-167.521,117.443-285.507,256-480H416z"/>
										</g>
									</g>
								</svg>
								<span>dostarczony</span>
							</div>
						</li>
						<li class="paneltrainerlist-table-list-item">
							<a [routerLink]="['/panel/trener/dieta/'+client?.id]">
								<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
									 viewBox="0 0 477.867 477.867" style="enable-background:new 0 0 477.867 477.867;" xml:space="preserve">
								<g>
									<g>
										<path d="M426.667,0.002H51.2C22.923,0.002,0,22.925,0,51.202v273.067c0,28.277,22.923,51.2,51.2,51.2h60.587l-9.284,83.456
											c-1.035,9.369,5.721,17.802,15.09,18.837c4.838,0.534,9.674-1.023,13.292-4.279l108.919-98.014h186.863
											c28.277,0,51.2-22.923,51.2-51.2V51.202C477.867,22.925,454.944,0.002,426.667,0.002z M443.733,324.269
											c0,9.426-7.641,17.067-17.067,17.067H233.25c-4.217,0.001-8.284,1.564-11.418,4.386l-80.452,72.414l6.434-57.839
											c1.046-9.367-5.699-17.809-15.067-18.856c-0.63-0.07-1.263-0.106-1.897-0.105H51.2c-9.426,0-17.067-7.641-17.067-17.067V51.202
											c0-9.426,7.641-17.067,17.067-17.067h375.467c9.426,0,17.067,7.641,17.067,17.067V324.269z"/>
									</g>
								</g>
								</svg>
								<svg class="active" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
										 viewBox="0 0 477.867 477.867" style="enable-background:new 0 0 477.867 477.867;" xml:space="preserve">
									<g>
										<g>
											<path d="M426.667,0.002H51.2C22.923,0.002,0,22.925,0,51.202v273.067c0,28.277,22.923,51.2,51.2,51.2h60.587l-9.284,83.456
												c-1.035,9.369,5.721,17.802,15.09,18.837c4.838,0.534,9.674-1.023,13.292-4.279l108.919-98.014h186.863
												c28.277,0,51.2-22.923,51.2-51.2V51.202C477.867,22.925,454.944,0.002,426.667,0.002z"/>
										</g>
									</g>
								</svg>
							</a>
						</li>
						<li class="paneltrainerlist-table-list-item">
							<a [routerLink]="['/panel/trener/trening/'+client?.id]">
								<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
									 viewBox="0 0 477.867 477.867" style="enable-background:new 0 0 477.867 477.867;" xml:space="preserve">
								<g>
									<g>
										<path d="M426.667,0.002H51.2C22.923,0.002,0,22.925,0,51.202v273.067c0,28.277,22.923,51.2,51.2,51.2h60.587l-9.284,83.456
											c-1.035,9.369,5.721,17.802,15.09,18.837c4.838,0.534,9.674-1.023,13.292-4.279l108.919-98.014h186.863
											c28.277,0,51.2-22.923,51.2-51.2V51.202C477.867,22.925,454.944,0.002,426.667,0.002z M443.733,324.269
											c0,9.426-7.641,17.067-17.067,17.067H233.25c-4.217,0.001-8.284,1.564-11.418,4.386l-80.452,72.414l6.434-57.839
											c1.046-9.367-5.699-17.809-15.067-18.856c-0.63-0.07-1.263-0.106-1.897-0.105H51.2c-9.426,0-17.067-7.641-17.067-17.067V51.202
											c0-9.426,7.641-17.067,17.067-17.067h375.467c9.426,0,17.067,7.641,17.067,17.067V324.269z"/>
									</g>
								</g>
								</svg>
								<svg class="active" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
										 viewBox="0 0 477.867 477.867" style="enable-background:new 0 0 477.867 477.867;" xml:space="preserve">
									<g>
										<g>
											<path d="M426.667,0.002H51.2C22.923,0.002,0,22.925,0,51.202v273.067c0,28.277,22.923,51.2,51.2,51.2h60.587l-9.284,83.456
												c-1.035,9.369,5.721,17.802,15.09,18.837c4.838,0.534,9.674-1.023,13.292-4.279l108.919-98.014h186.863
												c28.277,0,51.2-22.923,51.2-51.2V51.202C477.867,22.925,454.944,0.002,426.667,0.002z"/>
										</g>
									</g>
								</svg>
							</a>
						</li>
						<li class="paneltrainerlist-table-list-item">
							<div class="menu">
								<div mat-button [matMenuTriggerFor]="menu">
									<svg version="1.1" id="Capa_1" fill="#b6b6b6" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
										 viewBox="0 0 477.867 477.867" style="enable-background:new 0 0 477.867 477.867;" xml:space="preserve">
									<g>
										<g>
											<circle cx="68.267" cy="238.933" r="68.267"/>
										</g>
									</g>
									<g>
										<g>
											<circle cx="238.933" cy="238.933" r="68.267"/>
										</g>
									</g>
									<g>
										<g>
											<circle cx="409.6" cy="238.933" r="68.267"/>
										</g>
									</g>
									</svg>
								</div>
				                <mat-menu #menu="matMenu">
								  <button class="menu-button-sendmessage" mat-menu-item (click)="goToClientSettings(client.id)">Karta podopiecznego</button>
								  <button class="menu-button-sendmessage" mat-menu-item (click)="messageDialogOpen(client?.id || 0)">Wyślij wiadomość</button>
								  <button class="menu-button-report" mat-menu-item>Zgłoś</button>
								  <button class="menu-button-deleteclient" *ngIf="client.id" mat-menu-item (click)="deleteClient(client.id)">Usuń podopiecznego</button>
								</mat-menu>
				            </div>
						</li>
					</ul>
				</div>
				<div class="paneltrainerlist-add-client">
					<a class="paneltrainerlist-add-client-button" [routerLink]="['/ofertadlatrenerow']">dodaj PODOPIECZNEGO</a>
				</div>
			</div>
			<div class="paneltrainerlist-right-column">
				<ul class="paneltrainerlist-right-column-menu">
					<li class="paneltrainerlist-right-column-menu-item">
						<a [routerLink]="['/panel/profil']">
							<svg viewBox="-42 0 512 512.002" xmlns="http://www.w3.org/2000/svg"><path d="m210.351562 246.632812c33.882813 0 63.222657-12.152343 87.195313-36.128906 23.972656-23.972656 36.125-53.304687 36.125-87.191406 0-33.875-12.152344-63.210938-36.128906-87.191406-23.976563-23.96875-53.3125-36.121094-87.191407-36.121094-33.886718 0-63.21875 12.152344-87.191406 36.125s-36.128906 53.308594-36.128906 87.1875c0 33.886719 12.15625 63.222656 36.132812 87.195312 23.976563 23.96875 53.3125 36.125 87.1875 36.125zm0 0"/><path d="m426.128906 393.703125c-.691406-9.976563-2.089844-20.859375-4.148437-32.351563-2.078125-11.578124-4.753907-22.523437-7.957031-32.527343-3.308594-10.339844-7.808594-20.550781-13.371094-30.335938-5.773438-10.15625-12.554688-19-20.164063-26.277343-7.957031-7.613282-17.699219-13.734376-28.964843-18.199219-11.226563-4.441407-23.667969-6.691407-36.976563-6.691407-5.226563 0-10.28125 2.144532-20.042969 8.5-6.007812 3.917969-13.035156 8.449219-20.878906 13.460938-6.707031 4.273438-15.792969 8.277344-27.015625 11.902344-10.949219 3.542968-22.066406 5.339844-33.039063 5.339844-10.972656 0-22.085937-1.796876-33.046874-5.339844-11.210938-3.621094-20.296876-7.625-26.996094-11.898438-7.769532-4.964844-14.800782-9.496094-20.898438-13.46875-9.75-6.355468-14.808594-8.5-20.035156-8.5-13.3125 0-25.75 2.253906-36.972656 6.699219-11.257813 4.457031-21.003906 10.578125-28.96875 18.199219-7.605469 7.28125-14.390625 16.121094-20.15625 26.273437-5.558594 9.785157-10.058594 19.992188-13.371094 30.339844-3.199219 10.003906-5.875 20.945313-7.953125 32.523437-2.058594 11.476563-3.457031 22.363282-4.148437 32.363282-.679688 9.796875-1.023438 19.964844-1.023438 30.234375 0 26.726562 8.496094 48.363281 25.25 64.320312 16.546875 15.746094 38.441406 23.734375 65.066406 23.734375h246.53125c26.625 0 48.511719-7.984375 65.0625-23.734375 16.757813-15.945312 25.253906-37.585937 25.253906-64.324219-.003906-10.316406-.351562-20.492187-1.035156-30.242187zm0 0"/></svg>
							<span>mój profil</span>
						</a>
					</li>
					<li class="paneltrainerlist-right-column-menu-item">
						<a [routerLink]="['/kontakt']">
							<svg id="Layer_3" height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 3"><path d="m31.348 13.8a15.5 15.5 0 0 0 -30.721 4.215 15.614 15.614 0 0 0 13.31 13.351 16.058 16.058 0 0 0 2.08.136 15.351 15.351 0 0 0 7.972-2.217 1.5 1.5 0 0 0 -1.548-2.57 12.5 12.5 0 1 1 -4.789-23.109 12.5 12.5 0 0 1 10.162 16.488 2.166 2.166 0 0 1 -2.079 1.406 2.238 2.238 0 0 1 -2.235-2.235v-9.265a1.5 1.5 0 0 0 -3 0v.014a7.5 7.5 0 1 0 .541 11.523 5.224 5.224 0 0 0 4.694 2.963 5.167 5.167 0 0 0 4.914-3.424 15.535 15.535 0 0 0 .699-7.276zm-15.348 6.7a4.5 4.5 0 1 1 4.5-4.5 4.505 4.505 0 0 1 -4.5 4.5z"/></svg>
							<span>kontakt</span>
						</a>
					</li>
				</ul>
			</div>
		</div>
		<div class="col-sm-12 px-0">
			<app-page-footer></app-page-footer>
		</div>
	</div>
</div>
<div class="ingredientdialog">
	<div class="row">
		<div class="col-sm-12">
			<div class="ingredientdialog-content">
				<div class="ingredientdialog-form">
					<h1 class="ingredientdialog-form-title">
						Wybierz sylwetkę
					</h1>
					<div class="row ingredientdialog-form-field-textarea">
						<div *ngFor="let figure of figures;" class="col-sm-6 col-md-3">
							<img src="{{figure?.img}}" (click)="selectFigure(figure?.id || 0)" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
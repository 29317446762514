import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';
import { Alert } from 'src/app/models/alert.model';

@Component({
  selector: 'app-panelpassword',
  templateUrl: './panelpassword.component.html',
  styleUrls: ['./panelpassword.component.scss']
})
export class PanelpasswordComponent implements OnInit {
  userData: UsersData.User = {};
  public passwordForm: FormGroup;
  alerts: Alert[] = [];
  preferredAgeMin: number = 20;
  preferredAgeMax: number = 40;

  constructor(private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) {
	this.passwordForm = new FormGroup({
      'password': new FormControl('', [
        Validators.required
      ]),
      'repeatPassword': new FormControl('', [
        Validators.required
      ])
    });
	if(!this.cookieService.get('sessionToken') || (!this.cookieService.get('sessionFA') || this.cookieService.get('sessionFA') !== '1'))
        this.router.navigate(['/logowanie']);
  }

  ngOnInit(): void {
  }

  onSubmit(event: any): void {
  	this.alerts = [];
	if (this.passwordForm.invalid) {
		this.alerts.push({message: 'Prosimy wypełnić wszystkie wymagane pola', type: 'danger'});
		for (var key in this.passwordForm.errors) {
			this.alerts.push({message: key, type: 'danger'});
		}
	  return;
	}
	if (this.passwordForm.value.password !== this.passwordForm.value.repeatPassword) {
		this.alerts.push({message: 'Podane hasła nie są identyczne', type: 'danger'});
	  return;
	}
	if (this.passwordForm.value.password.length < 8) {
		this.alerts.push({message: 'Hasło musi posiadać minimum 8 znaków', type: 'danger'});
	  return;
	}
	
    var _self = this;
	var token = this.cookieService.get('sessionToken');
    this._loginService.adminPassword(this.passwordForm.value.password, token).subscribe(
      (messageFromAPI: UsersData.MessageFromAPI) => {
		this.alerts.push({message: messageFromAPI.message || '', type: messageFromAPI.result || 'success'});
		window.scroll(0,0);
      },
      (error: HttpErrorResponse) => {
		this.alerts.push({message: 'Wystąpił błąd podczas zmiany hasła', type: 'danger'});
		window.scroll(0,0);
      },
    );
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { MenuElement } from 'src/app/models/menu.model';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  isLogged: boolean = false;

  @Input() menuElements: MenuElement[] = [
    {
      url: '/',
      name: 'Strona główna',
    },
    {
      url: '/cms/1',
      name: 'O nas',
    },
    {
      url: '/cms/2',
      name: 'Cennik',
    },
    {
      url: '/kontakt',
      name: 'Kontakt',
    },
  ];

  constructor(private cookieService: CookieService) {
    if(this.cookieService.get( 'sessionToken')) {
		this.isLogged = true;
	}
  }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';
import {MatDialog} from '@angular/material/dialog';
import { ConfirmdialogComponent } from 'src/app/client/page/components/confirmdialog/confirmdialog.component';
import { MessagedialogComponent } from '../components/messagedialog/messagedialog.component';
import { Alert } from 'src/app/models/alert.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-paneladminsocials',
  templateUrl: './paneladminsocials.component.html',
  styleUrls: ['./paneladminsocials.component.scss']
})
export class PaneladminsocialsComponent implements OnInit {

  alerts: Alert[] = [];
  userData: UsersData.User = {};
  socials: UsersData.Socials = {
	'facebook': '',
	'twitter': '',
	'instagram': '',
	'youtube': ''
  };
  public socialForm: FormGroup;

  constructor(private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) {
	if(!this.cookieService.get('sessionToken') || (!this.cookieService.get('sessionFA') || this.cookieService.get('sessionFA') !== '1'))
		this.router.navigate(['/logowanie']);
	this.socialForm = new FormGroup({
      'facebook': new FormControl('', []),
      'instagram': new FormControl('', []),
      'twitter': new FormControl('', []),
      'youtube': new FormControl('', []),
    });
  }
  ngOnInit(): void {
	  this.getSocials();
  }
  
  onSubmit(event: any): void {
  	this.alerts = [];
	if (this.socialForm.invalid) {
		this.alerts.push({message: 'Prosimy poprawnie wypełnić wszystkie wymagane pola', type: 'danger'});
		for (var key in this.socialForm.errors) {
			this.alerts.push({message: key, type: 'danger'});
		}
	  return;
	}
    var _self = this;
	var token = this.cookieService.get('sessionToken');
    var date = {
		token: token,
		facebook: this.socialForm.value.facebook,
		instagram: this.socialForm.value.instagram,
		twitter: this.socialForm.value.twitter,
		youtube: this.socialForm.value.youtube
    }
    this._loginService.setSocials(date).subscribe(
		(messageFromAPI: UsersData.MessageFromAPI) => {
			if(messageFromAPI?.result === 'success') {
				this.alerts.push({message: (messageFromAPI?.message ? messageFromAPI?.message : ''), type: 'success'});
			}
			else
				this.alerts.push({message: (messageFromAPI?.message ? messageFromAPI?.message : ''), type: 'danger'});
	    },
	    (error: HttpErrorResponse) => {
			this.alerts.push({message: 'Wystąpił błąd podczas zapisu danych', type: 'danger'});
		},
    );
  }
  
  getSocials() {
    var _self = this;
    this._loginService.getSocials().subscribe(
		(socials: UsersData.Socials) => {
			_self.socials = socials;
		    _self.socialForm.controls['facebook'].setValue(socials.facebook);
		    _self.socialForm.controls['twitter'].setValue(socials.twitter);
		    _self.socialForm.controls['instagram'].setValue(socials.instagram);
		    _self.socialForm.controls['youtube'].setValue(socials.youtube);
		},
	    (error: HttpErrorResponse) => {
			this.alerts.push({message: 'Wystąpił błąd podczas pobierania danych', type: 'danger'});
		},
    );
  }

}

import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Alert } from 'src/app/models/alert.model';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';
import {NgbRatingConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-reviewdialog',
  templateUrl: './reviewdialog.component.html',
  styleUrls: ['./reviewdialog.component.scss']
})
export class ReviewdialogComponent implements OnInit {

  public reviewForm: FormGroup;
  review: UsersData.Review;
  alerts: Alert[] = [];
  rating: number = 5;

  constructor(config: NgbRatingConfig, private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService, public dialogRef: MatDialogRef<ReviewdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UsersData.Review) {
    this.review = data;
    this.reviewForm = new FormGroup({
      'message': new FormControl('', [
        Validators.required
      ])
    });
	config.max = 5;
  }

  ngOnInit(): void {
  }

  closeDialog(messageFromAPI: UsersData.MessageFromAPI) {
    this.dialogRef.close(messageFromAPI);
  }

  onSubmit(event: any): void {
  	this.alerts = [];
	if (this.reviewForm.invalid) {
		let formErrors = this.getErrors(this.reviewForm);
			
		if(formErrors?.message && formErrors?.message?.required)
			this.alerts.push({message: 'Prosimy wypełnić wszystkie pola', type: 'danger'});
	  return;
	}
    var _self = this;
    this.review.message = this.reviewForm.value.message;
    this.review.rating = this.rating;
    this._loginService.addReview(this.cookieService.get( 'sessionToken'), this.review).subscribe(
      (messageFromAPI: UsersData.MessageFromAPI) => {
		//this.message = message;
		this.closeDialog(messageFromAPI);
      },
      (error: HttpErrorResponse) => {
		this.closeDialog({result: 'failed', message: 'Błąd połączenia'});
      },
    );
  }

  getErrors = (formGroup: FormGroup, errors: any = {}) => {
	  Object.keys(formGroup.controls).forEach(field => {
	    const control = formGroup.get(field);
	    if (control instanceof FormControl) {
	      errors[field] = control.errors;
	    } else if (control instanceof FormGroup) {
	      errors[field] = this.getErrors(control);
	    }
	  });
	  return errors;
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {
	
  payments: any[] = [
	{
		icon: "/assets/img/platnosci_ikona_1.png",
		price: "7,49 zł"
	},
	{
		icon: "/assets/img/platnosci_ikona_2.png",
		price: "6,99 zł"
	},
	{
		icon: "/assets/img/platnosci_ikona_3.png",
		price: "6,49 zł"
	},
	{
		icon: "/assets/img/platnosci_ikona_4.png",
		price: "5,99 zł"
	},
	{
		icon: "/assets/img/platnosci_ikona_5.png",
		price: "5,49 zł"
	},
	{
		icon: "/assets/img/platnosci_ikona_6.png",
		price: "4,99 zł"
	}
  ];

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';
import { Alert } from 'src/app/models/alert.model';

@Component({
  selector: 'app-panelpreferences',
  templateUrl: './panelpreferences.component.html',
  styleUrls: ['./panelpreferences.component.scss']
})
export class PanelpreferencesComponent implements OnInit {

  client: UsersData.User = {};
  userData: UsersData.User = {};
  clientid: number = 0;
  public profileForm: FormGroup;
  alerts: Alert[] = [];

  constructor(private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) {
	this.profileForm = new FormGroup({
      'tastepreferences': new FormControl(this.client.user?.tastepreferences, [
      ]),
      'pastdiseases': new FormControl(this.client.user?.pastdiseases, [
      ]),
      'pastinjuries': new FormControl(this.client.user?.pastinjuries, [
      ]),
      'trainingnotes': new FormControl(this.client.user?.trainingnotes, [
      ]),
      'dietnotes': new FormControl(this.client.user?.dietnotes, [
      ]),
      'contraindications': new FormControl(this.client.user?.contraindications, [
      ])
    });
	var _self = this;
    this.activatedRoute.params.subscribe(params => {
      _self.clientid = params['clientid'];
    });
	this.getUserData();
	if(!this.cookieService.get('sessionToken'))
        this.router.navigate(['/logowanie']);
  }

  ngOnInit(): void {
  }

  onSubmit(event: any): void {
  	this.alerts = [];
	if (this.profileForm.invalid) {
		this.alerts.push({message: 'Prosimy wypełnić wszystkie wymagane pola', type: 'danger'});
		for (var key in this.profileForm.errors) {
			this.alerts.push({message: key, type: 'danger'});
		}
	  return;
	}

    var _self = this;
	var token = this.cookieService.get('sessionToken');
	var user = {
		tastepreferences: this.profileForm.value.tastepreferences ? this.profileForm.value.tastepreferences : this.client.user?.tastepreferences,
		pastdiseases: this.profileForm.value.pastdiseases ? this.profileForm.value.pastdiseases : this.client.user?.pastdiseases,
		pastinjuries: this.profileForm.value.pastinjuries ? this.profileForm.value.pastinjuries : this.client.user?.pastinjuries,
		trainingnotes: this.profileForm.value.trainingnotes ? this.profileForm.value.trainingnotes : this.client.user?.trainingnotes,
		dietnotes: this.profileForm.value.dietnotes ? this.profileForm.value.dietnotes : this.client.user?.dietnotes,
		contraindications: this.profileForm.value.contraindications ? this.profileForm.value.contraindications : this.client.user?.contraindications,
	};
    this._loginService.editUser(user, token).subscribe(
      (message: UsersData.MessageFromAPI) => {
		if(message?.result === 'success') {
			this.alerts.push({message: (message?.message ? message?.message : ''), type: 'success'});
			window.scroll(0,0);
		}
		else
			this.alerts.push({message: (message?.message ? message?.message : ''), type: 'danger'});
      },
      (error: HttpErrorResponse) => {
		this.alerts.push({message: 'Wystąpił błąd podczas zapisu danych', type: 'danger'});
      },
    );
  }

  getUserData(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getUserData(token).subscribe(
	  (userData: UsersData.UserData) => {
		_self.userData.user = userData;
		if(userData?.isTrainer)
			_self.getClientData();
		else
			_self.client.user = userData;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  getClientData(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getClientData(this.clientid, token).subscribe(
	  (userData: UsersData.UserData) => {
		_self.client.user = userData;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }
}

<div class="container">
	<div class="row">
		<div class="col-sm-12">
			<app-page-header></app-page-header>
		</div>
		<div class="col-sm-12 content">
			<div class="row">
				<div class="col-xl-2"></div>
				<div class="col-sm-12 col-xl-8">
					<div class="row payments">
						<div class="col-sm-12 payments-title"><img src="/assets/img/platnosci.png"/><span>Płatności</span></div>
						<div class="col-sm-12">
							<ul class="row payments-list">
								<li class="col-sm-6 col-md-4 col-lg-3 col-xl-2 payments-list-item" *ngFor="let payment of payments">
									<div class="payments-list-item-img"><img src="{{payment.icon}}"/></div>
									<p class="payments-list-item-price">{{payment.price}}</p>
									<p class="payments-list-item-label">za podopiecznego</p>
								</li>
							</ul>
						</div>
						<div class="col-sm-12 payments-subtitle">
							<div class="payments-subtitle-content">
								<img src="/assets/img/pytanie.png"/><span>Jak to działa?</span>
							</div>
						</div>
						<div class="col-sm-12 payments-text">
							<p class="payments-text-content">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
								<br/><br/>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
							</p>
						</div>
						<div class="col-sm-12 payments-button">
							<a class="payments-button-link" [routerLink]="['/rejestracja']">zarejestruj się jako TRENER</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-sm-12 px-0">
			<app-page-footer></app-page-footer>
		</div>
	</div>
</div>
<div class="container">
	<div class="row">
		<div class="col-sm-12">
			<app-page-header></app-page-header>
		</div>
		<div class="col-sm-12 px-0">
			<app-panelheader [id_tab]="2" [id_client]="clientid"></app-panelheader>
		</div>
		<div class="col-sm-12">
			<form class="panelclientdiet row" (ngSubmit)="onSubmit($event)" cdkDropListGroup>
				<app-alert [alerts]="alerts" style="width: 100%;"></app-alert>
				<div class="col-sm-12 col-xl-4">
					<div class="panelclientdiet-column row">
						<div class="col-sm-5">
							<ul class="panelclientdiet-column-tabs">
								<li class="panelclientdiet-column-tabs-tab col-sm-6" *ngFor="let item of tabs" (click)="changeSerie(item?.number)"><span class="panelclientdiet-column-tabs-tab-title">Cykl {{item?.number}} ({{getSeriesDatesItem(item?.number)}})</span><span class="panelclientdiet-column-tabs-tab-date"></span></li>
							</ul>
						</div>
						<div class="col-sm-7">
							<div class="row">
								<div class="panelclientdiet-column-box">
									<div class="panelclientdiet-column-box-title">Cykl {{serie.number}} ({{serieStart}} - {{serieEnd}})</div>
									<div class="panelclientdiet-column-box-content">
										<div class="panelclientdiet-column-box-content-title">Dieta od trenera</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="panelclientdiet-content-form col-sm-12 col-xl-8">
					<carousel class="panelclientdiet-content-form-calendar" [cellWidth]="width" [height]="920">
						<li class="carousel-cell" *ngFor="let date of listOfDate; let i = index;">
							<div class="panelclientdiet-content-form-calendar-date">
								<span class="panelclientdiet-content-form-calendar-date-title">{{date}}</span>
								<ul class="panelclientdiet-content-form-calendar-date-list">
									<li class="panelclientdiet-content-form-calendar-date-list-item" *ngFor="let meal of getMealsForSerieDate(i); let meal_index = index;">
										<div class="panelclientdiet-content-form-calendar-date-list-item-title">
											<select disabled (change)="itemChange((meal.id ? meal.id : 0), $event.target.value)" class="panelclientdiet-content-form-calendar-date-list-item-title-select">
												<option [value]="1" [selected]="meal.type == 1">Śniadanie</option>
												<option [value]="2" [selected]="meal.type == 2">Obiad</option>
												<option [value]="3" [selected]="meal.type == 3">Kolacja</option>
												<option [value]="4" [selected]="meal.type == 4">Przekąska</option>
												<option [value]="5" [selected]="meal.type == 5">Przed Treningiem</option>
												<option [value]="6" [selected]="meal.type == 6">Po Treningu</option>
											</select>
											<span class="panelclientdiet-content-form-calendar-date-list-item-title-time"><svg id="Capa_1" enable-background="new 0 0 359.286 359.286" height="512" viewBox="0 0 359.286 359.286" width="512" xmlns="http://www.w3.org/2000/svg"><g><g><path d="m179.643 359.286c99.043 0 179.643-80.6 179.643-179.643s-80.599-179.643-179.643-179.643-179.643 80.6-179.643 179.643 80.6 179.643 179.643 179.643zm0-335.334c85.869 0 155.691 69.821 155.691 155.691s-69.821 155.691-155.691 155.691-155.691-69.821-155.691-155.691 69.822-155.691 155.691-155.691z"/><path d="m232.039 236.89c2.216 1.796 4.85 2.635 7.485 2.635 3.533 0 7.006-1.557 9.341-4.491 4.132-5.15 3.293-12.695-1.856-16.827l-55.39-44.312v-90.061c0-6.587-5.389-11.976-11.976-11.976s-11.976 5.389-11.976 11.976v95.81c0 3.653 1.677 7.066 4.491 9.341z"/></g></g></svg><span>{{meal.time}}</span></span>
										</div>
										<ul class="panelclientdiet-content-form-calendar-date-list-item-series" *ngIf="meal.ingredients">
											<li class="panelclientdiet-column-box-content-ingredients-item row" *ngFor="let ingredient of meal.ingredients">
												<div class="panelclientdiet-column-box-content-ingredients-item-desc col-sm-8 col-xl-9">
													<div class="panelclientdiet-column-box-content-ingredients-item-desc-name">{{ingredient?.ingredient?.name || ingredient?.name}}</div>
													<div class="panelclientdiet-column-box-content-ingredients-item-desc-list">
														<div class="panelclientdiet-column-box-content-ingredients-item-desc-list-element"><b>k</b>-{{ingredient.kcal}}</div>
														<div class="panelclientdiet-column-box-content-ingredients-item-desc-list-element"><b>b</b>-{{ingredient.protein}}</div>
														<div class="panelclientdiet-column-box-content-ingredients-item-desc-list-element"><b>w</b>-{{ingredient.carbohydrates}}</div>
														<div class="panelclientdiet-column-box-content-ingredients-item-desc-list-element"><b>t</b>-{{ingredient.fat}}</div>
													</div>
												</div>
												<div class="panelclientdiet-column-box-content-ingredients-item-field col-sm-4 col-xl-3">
													<input disabled type="text" [(ngModel)]="ingredient.amount" (change)="saveChanges()" [ngModelOptions]="{standalone: true}" class="panelclientdiet-column-box-content-ingredients-item-field-input" value="{{ingredient.amount}}"/>
													<span class="panelclientdiet-column-box-content-ingredients-item-field-label">{{ingredient?.ingredient?.unit || ingredient?.unit}}</span>
												</div>
											</li>
										</ul>
										<div class="panelclientdiet-content-form-calendar-date-list-item-ingredients">
											<label class="panelclientdiet-content-form-calendar-date-list-item-ingredients-label">Razem:</label>
											<div class="panelclientdiet-content-form-calendar-date-list-item-ingredients-list">
												<div class="panelclientdiet-content-form-calendar-date-list-item-ingredients-list-element"><b>k</b>-{{getForMeal(meal, 1)}}</div>
												<div class="panelclientdiet-content-form-calendar-date-list-item-ingredients-list-element"><b>b</b>-{{getForMeal(meal, 2)}}</div>
												<div class="panelclientdiet-content-form-calendar-date-list-item-ingredients-list-element"><b>w</b>-{{getForMeal(meal, 3)}}</div>
												<div class="panelclientdiet-content-form-calendar-date-list-item-ingredients-list-element"><b>t</b>-{{getForMeal(meal, 4)}}</div>
											</div>
										</div>
									</li>
								</ul>
							</div>
							<div class="panelclientdiet-content-form-calendar-date-box">
								<div class="panelclientdiet-content-form-calendar-date-box-item">
									<label class="panelclientdiet-content-form-calendar-date-box-item-label">Podsumowanie:</label>
									<div class="panelclientdiet-content-form-calendar-date-box-item-list">
										<div class="panelclientdiet-content-form-calendar-date-box-item-list-element"><b>k</b>-{{getForMeals(getMealsForSerieDate(i), 1)}}</div>
										<div class="panelclientdiet-content-form-calendar-date-box-item-list-element"><b>b</b>-{{getForMeals(getMealsForSerieDate(i), 2)}}</div>
										<div class="panelclientdiet-content-form-calendar-date-box-item-list-element"><b>w</b>-{{getForMeals(getMealsForSerieDate(i), 3)}}</div>
										<div class="panelclientdiet-content-form-calendar-date-box-item-list-element"><b>t</b>-{{getForMeals(getMealsForSerieDate(i), 4)}}</div>
									</div>
								</div>
							</div>
						</li>
					</carousel>
				</div>
			</form>
		</div>
		<div class="col-sm-12 px-0 short-margin-for-footer">
			<app-page-footer></app-page-footer>
		</div>
	</div>
</div>
import {HttpClient, HttpParams} from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UsersData } from '../../../models/users.namespace';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
    app_url: string = 'https://api.fitbook.pro/api';

  constructor(private _httpClient: HttpClient) { }

  register(user: UsersData.RegisterUser): Observable<UsersData.MessageFromAPI> {
	var body = JSON.stringify(user);
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/users/create`, body);
  }
  
  login(userlogin: string, userpassword: string): Observable<UsersData.Login> {
    return this._httpClient.get<UsersData.Login>(`${this.app_url}/users/login?login=${userlogin}&password=${userpassword}`);
  }

  getUserData(token: string): Observable<UsersData.UserData> {
    return this._httpClient.get<UsersData.UserData>(`${this.app_url}/users/userdata?token=${token}`);
  }

  invite(token: string, invite: UsersData.Invite): Observable<UsersData.Invite> {
  	invite.token = token;
  	var body = JSON.stringify(invite);
    return this._httpClient.post<UsersData.Invite>(`${this.app_url}/messages/invite`, body);
  }

  acceptInvite(token: string, invite: UsersData.Invite): Observable<UsersData.MessageFromAPI> {
  	invite.token = token;
  	var body = JSON.stringify(invite);
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/messages/invite/accept`, body);
  }

  uploadUserPhoto(photo: any, token: string): Observable<UsersData.MessageFromAPI> {
	var body = JSON.stringify(photo);
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/users/file?token=${token}`, body);
  }

  editUser(user: UsersData.UserData, token: string) {
    var body = JSON.stringify(user);
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/users/edit?token=${token}`, body);
  }

  saveImages(file: any, token: string) {
    const endpoint = `${this.app_url}/users/file`;
	var body = JSON.stringify({file: file, token: token});
    return this._httpClient.post(endpoint, body);
  }

  deleteImage(token: string) {
    const endpoint = `${this.app_url}/users/file/delete`;
	var body = JSON.stringify({token: token});
    return this._httpClient.post(endpoint, body);
  }

  addAttributeValue(attribute: UsersData.AttributeValue, token: string) {
    const endpoint = `${this.app_url}/users/attr`;
	var body = JSON.stringify({attribute: attribute, token: token});
    return this._httpClient.post(endpoint, body);
  }

  deleteAttributeValue(id: number, token: string) {
    const endpoint = `${this.app_url}/users/attr/delete`;
	var body = JSON.stringify({id: id, token: token});
    return this._httpClient.post(endpoint, body);
  }

  getDimensions(serie: number, clientid: number, token: string): Observable<UsersData.Serie> {
    return this._httpClient.get<UsersData.Serie>(`${this.app_url}/users/dimensions?serie=${serie}&clientid=${clientid}&token=${token}`);
  }

  updateDimensions(token: string, dimensions: any, serie_id: number): Observable<UsersData.MessageFromAPI> {
    var body = JSON.stringify({token: token, dimensions: dimensions, serie_id: serie_id});
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/users/dimensions/update`, body);
  }

  getMessages(token: string): Observable<UsersData.Invite[]> {
    return this._httpClient.get<UsersData.Invite[]>(`${this.app_url}/messages/messages?token=${token}`);
  }

  getAllUsers(token: string, page: number, limit: number): Observable<any> {
    return this._httpClient.get<any>(`${this.app_url}/users/all?token=${token}&page=${page}&limit=${limit}`);
  }

  getClients(token: string): Observable<UsersData.UserData[]> {
    return this._httpClient.get<UsersData.UserData[]>(`${this.app_url}/users/clients?token=${token}`);
  }

  getClientData(id_client: number, token: string): Observable<UsersData.UserData> {
    return this._httpClient.get<UsersData.UserData>(`${this.app_url}/users/client?token=${token}&id_client=${id_client}`);
  }

  saveSerie(serie: number, clientid: number, token: string, trainingstart: Date, serietime: number, dimensions: any): Observable<UsersData.Serie> {
    var body = JSON.stringify({serie: serie, clientid: clientid, token: token, trainingstart: trainingstart, serietime: serietime, dimensions: dimensions});
    return this._httpClient.post<UsersData.Serie>(`${this.app_url}/users/serie/save`, body);
  }

  addPractice(serie: number, clientid: number, token: string, name: string, seriedate: number, position: number): Observable<UsersData.MessageFromAPI> {
    var body = JSON.stringify({serie: serie, clientid: clientid, token: token, name: name, seriedate: seriedate, position: position});
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/users/practice/add`, body);
  }

  addTrainingserie(token: string, practiceid: number, position: number): Observable<UsersData.MessageFromAPI> {
    var body = JSON.stringify({token: token, practiceid: practiceid, position: position});
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/users/trainingserie/add`, body);
  }

  updateTrainingseries(token: string, trainingseries: any[], comment: string, serie_id: number): Observable<UsersData.MessageFromAPI> {
    var body = JSON.stringify({token: token, trainingseries: trainingseries, comment: comment, serie_id: serie_id});
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/users/trainingseries/update`, body);
  }

  addSupplement(token: string, supplement: UsersData.Supplement): Observable<UsersData.MessageFromAPI> {
    var body = JSON.stringify({supplement: supplement, token: token});
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/users/supplement/add`, body);
  }

  addIngredient(token: string, ingredient: UsersData.Ingredient): Observable<UsersData.MessageFromAPI> {
    var body = JSON.stringify({ingredient: ingredient, token: token});
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/users/ingredient/add`, body);
  }

  addAdminIngredient(token: string, ingredient: UsersData.Ingredient): Observable<UsersData.MessageFromAPI> {
    var body = JSON.stringify({ingredient: ingredient, token: token});
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/users/ingredient/admin/add`, body);
  }

  getIngredients(token: string, type: number): Observable<UsersData.Ingredient[]> {
    return this._httpClient.get<UsersData.Ingredient[]>(`${this.app_url}/users/ingredients?token=${token}&type=${type}`);
  }

  getModeratorIngredients(token: string, type: number): Observable<UsersData.Ingredient[]> {
    return this._httpClient.get<UsersData.Ingredient[]>(`${this.app_url}/users/ingredients/admin?token=${token}&type=${type}`);
  }

  addMeal(token: string, meal: UsersData.Meal): Observable<UsersData.MessageFromAPI> {
    var body = JSON.stringify({meal: meal, token: token});
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/users/meal/add`, body);
  }

  getMeals(token: string, serieid: number): Observable<UsersData.Meal[]> {
    return this._httpClient.get<UsersData.Meal[]>(`${this.app_url}/users/meals?token=${token}&serie=${serieid}`);
  }

  updateMeals(token: string, meals: any[], serie_id: number): Observable<UsersData.MessageFromAPI> {
    var body = JSON.stringify({token: token, meals: meals, serie_id: serie_id});
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/users/meals/update`, body);
  }

  deleteUser(token: string, userid: number): Observable<UsersData.MessageFromAPI> {
  	var body = JSON.stringify({
		token: token,
		userid: userid
	});
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/users/delete`, body);
  }

  disableUser(token: string, userid: number): Observable<UsersData.MessageFromAPI> {
  	var body = JSON.stringify({
		token: token,
		userid: userid
	});
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/users/disable`, body);
  }

  enableUser(token: string, userid: number): Observable<UsersData.MessageFromAPI> {
  	var body = JSON.stringify({
		token: token,
		userid: userid
	});
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/users/enable`, body);
  }

  deleteUserFromTrainer(token: string, clientid: number): Observable<UsersData.MessageFromAPI> {
  	var body = JSON.stringify({
		token: token,
		clientid: clientid
	});
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/messages/invite/delete`, body);
  }

  getAllSpecializations(token: string): Observable<UsersData.Specialization[]> {
    return this._httpClient.get<UsersData.Specialization[]>(`${this.app_url}/users/specializations?token=${token}`);
  }

  addSpecialization(specialization: UsersData.Specialization, token: string) {
    const endpoint = `${this.app_url}/users/specialization/add`;
	var body = JSON.stringify({specialization: specialization, token: token});
    return this._httpClient.post(endpoint, body);
  }

  deleteUserSpecialization(id: number, token: string) {
    const endpoint = `${this.app_url}/users/specialization/delete`;
	var body = JSON.stringify({id: id, token: token});
    return this._httpClient.post(endpoint, body);
  }

  getFiltersTrainer(token: string): Observable<UsersData.FiltersObject[]> {
    return this._httpClient.get<UsersData.FiltersObject[]>(`${this.app_url}/search/filters/trainer?token=${token}`);
  }

  getFiltersClient(token: string): Observable<UsersData.FiltersObject[]> {
    return this._httpClient.get<UsersData.FiltersObject[]>(`${this.app_url}/search/filters/client?token=${token}`);
  }

  getUsersList(filters: UsersData.Filters, token: string, currentPage: number, limit: number): Observable<UsersData.Search> {
    const endpoint = `${this.app_url}/search/userslist`;
	var body = JSON.stringify({filters: filters, token: token, currentPage: currentPage, limit: limit});
    return this._httpClient.post<UsersData.Search>(endpoint, body);
  }

  message(token: string, invite: UsersData.Invite): Observable<UsersData.Invite> {
  	invite.token = token;
  	var body = JSON.stringify(invite);
    return this._httpClient.post<UsersData.Invite>(`${this.app_url}/messages/send`, body);
  }

  addReview(token: string, review: UsersData.Review): Observable<UsersData.MessageFromAPI> {
	var body = JSON.stringify({review: review, token: token});
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/messages/review/add`, body);
  }

  getHistory(token: string, serieid?: number): Observable<UsersData.UserHistory[]> {
	if(serieid)
    	return this._httpClient.get<UsersData.UserHistory[]>(`${this.app_url}/users/history?token=${token}&serieid=${serieid}`);
	else
    	return this._httpClient.get<UsersData.UserHistory[]>(`${this.app_url}/users/history?token=${token}`);
  }

  getStatistics(token: string, clientid: number, serieid: number): Observable<UsersData.Statistics> {
	return this._httpClient.get<UsersData.Statistics>(`${this.app_url}/users/statistics?token=${token}&clientid=${clientid}&serieid=${serieid}`);
  }

  clearSerie(token: string, serieid: number): Observable<UsersData.MessageFromAPI> {
    var body = JSON.stringify({token: token, serieid: serieid});
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/users/serie/clear`, body);
  }

  copySerie(token: string, serieid: number, copyTo: number): Observable<UsersData.MessageFromAPI> {
    var body = JSON.stringify({token: token, serieid: serieid, copyTo: copyTo});
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/users/serie/copy`, body);
  }

  clearDiet(token: string, serieid: number): Observable<UsersData.MessageFromAPI> {
    var body = JSON.stringify({token: token, serieid: serieid});
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/users/diet/clear`, body);
  }

  copyDiet(token: string, serieid: number, copyTo: number): Observable<UsersData.MessageFromAPI> {
    var body = JSON.stringify({token: token, serieid: serieid, copyTo: copyTo});
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/users/diet/copy`, body);
  }

  sendReport(token: string, invite: UsersData.Invite): Observable<UsersData.MessageFromAPI> {
  	invite.token = token;
  	var body = JSON.stringify(invite);
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/messages/report/send`, body);
  }

  deleteMessage(id: number, token: string) {
    const endpoint = `${this.app_url}/messages/delete`;
	var body = JSON.stringify({id: id, token: token});
    return this._httpClient.post(endpoint, body);
  }

  deleteAlert(id: number, token: string) {
    const endpoint = `${this.app_url}/messages/alerts/delete`;
	var body = JSON.stringify({id: id, token: token});
    return this._httpClient.post(endpoint, body);
  }

  getAlerts(token: string): Observable<UsersData.AlertMessage[]> {
    return this._httpClient.get<UsersData.AlertMessage[]>(`${this.app_url}/messages/alerts?token=${token}`);
  }

  getSeriesDates(token: string, clientid: number): Observable<UsersData.SeriesDatesItem[]> {
	return this._httpClient.get<UsersData.SeriesDatesItem[]>(`${this.app_url}/users/series/dates?token=${token}&clientid=${clientid}`);
  }
  
  confirmAccount(userlogin: string, confirmToken: string): Observable<UsersData.Login> {
    return this._httpClient.get<UsersData.Login>(`${this.app_url}/users/confirmaccount?login=${userlogin}&confirmToken=${confirmToken}`);
  }

  getAlertsCount(token: string): Observable<UsersData.Count> {
    return this._httpClient.get<UsersData.Count>(`${this.app_url}/users/alerts/count?token=${token}`);
  }

  readAlert(token: string, id: number): Observable<UsersData.MessageFromAPI> {
	var body = JSON.stringify({id: id});
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/users/alert/read?token=${token}`, body);
  }

  readMessage(token: string, id: number): Observable<UsersData.MessageFromAPI> {
	var body = JSON.stringify({id: id});
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/users/message/read?token=${token}`, body);
  }

  getFigures(gender: string): Observable<UsersData.Figure[]> {
    return this._httpClient.get<UsersData.Figure[]>(`${this.app_url}/users/figures?gender=${gender}`);
  }

  changeFigure(token: string, client_id: number, figure_id: number): Observable<UsersData.MessageFromAPI> {
	var body = JSON.stringify({client_id: client_id, figure_id: figure_id});
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/users/figure?token=${token}`, body);
  }

  addSerieImage(file: any, serie_id: number, token: string) {
    const endpoint = `${this.app_url}/users/serie/file`;
	var body = JSON.stringify({file: file, serie_id: serie_id, token: token});
    return this._httpClient.post(endpoint, body);
  }

  deleteImageSerie(imageserie_id: number, token: string) {
    const endpoint = `${this.app_url}/users/serie/file/delete`;
	var body = JSON.stringify({imageserie_id: imageserie_id, token: token});
    return this._httpClient.post(endpoint, body);
  }

  getSimilarTrainers(id_trainer: number): Observable<UsersData.Search> {
    const endpoint = `${this.app_url}/search/similartrainers?id_trainer=${id_trainer}`;
    return this._httpClient.get<UsersData.Search>(endpoint);
  }

  validateCity(query: string): Observable<boolean> {
    const endpoint = `${this.app_url}/search/city?query=${query}`;
    return this._httpClient.get<boolean>(endpoint);
  }

  getStates(): Observable<UsersData.State[]> {
    const endpoint = `${this.app_url}/search/states`;
    return this._httpClient.get<UsersData.State[]>(endpoint);
  }

  getCities(query: string, state: string): Observable<UsersData.City[]> {
    const endpoint = `${this.app_url}/search/cities?query=${query}&state=${state}`;
    return this._httpClient.get<UsersData.City[]>(endpoint);
  }

  addQualification(file: any, name: string, token: string) {
    const endpoint = `${this.app_url}/users/qualification`;
	var body = JSON.stringify({file: file, name: name, token: token});
    return this._httpClient.post(endpoint, body);
  }

  deleteQualification(id: number, token: string) {
    const endpoint = `${this.app_url}/users/qualification/delete`;
	var body = JSON.stringify({id: id, token: token});
    return this._httpClient.post(endpoint, body);
  }

  deletePractice(id: number, token: string) {
    const endpoint = `${this.app_url}/users/practice/delete`;
	var body = JSON.stringify({id: id, token: token});
    return this._httpClient.post(endpoint, body);
  }

  deleteIngredient(id: number, token: string) {
    const endpoint = `${this.app_url}/users/ingredient/delete`;
	var body = JSON.stringify({id: id, token: token});
    return this._httpClient.post(endpoint, body);
  }

  remindpassword(login: string) {
    var body = JSON.stringify({login: login});
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/users/remindpassword`, body);
  }

  sendCode(login: string, code: string) {
    var body = JSON.stringify({code: code, login: login});
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/users/sendcode`, body);
  }

  resetpassword(login: string, code: string, password: string) {
    var body = JSON.stringify({code: code, login: login, password: password});
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/users/resetpassword`, body);
  }

  addSerieDate(token: string, numberOfDay: number, serieid: number): Observable<UsersData.MessageFromAPI> {
    var body = JSON.stringify({numberOfDay: numberOfDay, token: token, serieid: serieid});
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/users/seriedate/add`, body);
  }
  
  setSocials(date: any): Observable<UsersData.MessageFromAPI> {
	return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/users/social/save`, JSON.stringify(date));
  }

  getSocials(): Observable<UsersData.Socials> {
	return this._httpClient.get<UsersData.Socials>(`${this.app_url}/users/socials`);
  }
  
  setContent(date: any): Observable<UsersData.MessageFromAPI> {
	return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/users/content/save`, JSON.stringify(date));
  }

  getContents(): Observable<UsersData.Content[]> {
	return this._httpClient.get<UsersData.Content[]>(`${this.app_url}/users/contents`);
  }

  getContent(id: number): Observable<UsersData.Content> {
	return this._httpClient.get<UsersData.Content>(`${this.app_url}/users/content?id=${id}`);
  }

  saveContentImage(file: any, id: number, token: string) {
    const endpoint = `${this.app_url}/users/content/image`;
	var body = JSON.stringify({file: file, id: id, token: token});
    return this._httpClient.post(endpoint, body);
  }
  
  setCms(date: any): Observable<UsersData.MessageFromAPI> {
	return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/users/cms/save`, JSON.stringify(date));
  }

  getCmsList(): Observable<UsersData.Content[]> {
	return this._httpClient.get<UsersData.Content[]>(`${this.app_url}/users/cms/list`);
  }

  getCms(id: number): Observable<UsersData.Content> {
	return this._httpClient.get<UsersData.Content>(`${this.app_url}/users/cms?id=${id}`);
  }

  deleteCms(id: number, token: string) {
    const endpoint = `${this.app_url}/users/cms/delete`;
	var body = JSON.stringify({id: id, token: token});
    return this._httpClient.post(endpoint, body);
  }
  
  deleteSeriedate(id: number, token: string) {
    const endpoint = `${this.app_url}/users/seriedate/delete`;
	var body = JSON.stringify({id: id, token: token});
    return this._httpClient.post(endpoint, body);
  }

  adminPassword(password: string, token: string) {
    var body = JSON.stringify({password: password, token: token});
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/users/adminpassword`, body);
  }

  adminPayment(balance: number, clientid: number, token: string) {
    var body = JSON.stringify({balance: balance, clientid: clientid, token: token});
    return this._httpClient.post<UsersData.MessageFromAPI>(`${this.app_url}/users/adminpayment`, body);
  }

  getHistoryBalance(clientid: number, token: string) {
    var body = JSON.stringify({clientid: clientid, token: token});
    return this._httpClient.post<UsersData.HistoryBalance[]>(`${this.app_url}/users/historybalance`, body);
  }
}
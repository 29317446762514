import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Alert } from 'src/app/models/alert.model';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';

@Component({
  selector: 'app-copyseriedialog',
  templateUrl: './copyseriedialog.component.html',
  styleUrls: ['./copyseriedialog.component.scss']
})
export class CopyseriedialogComponent implements OnInit {

  serieid: number;
  serieNumber: number;
  tabs: UsersData.SerieTime[] = [];

  constructor(private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService, public dialogRef: MatDialogRef<CopyseriedialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.serieid = data.serieid;
    this.serieNumber = data.serieNumber;
    this.tabs = data.tabs;
  }

  ngOnInit(): void {
  }

  copySerie(serie_number: number) {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.copySerie(token, _self.serieid, serie_number).subscribe(
	  (messageFromAPI: UsersData.MessageFromAPI) => {
		this.dialogRef.close(true);
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  onSubmit(event: any): void {
    var _self = this;
  }
}

<div class="container">
	<div class="row">
		<div class="col-sm-12">
			<app-page-header></app-page-header>
		</div>
		<app-alert [alerts]="alerts" style="width: 100%;"></app-alert>
		<div class="col-sm-12 px-0">
			<div class="paneltrainerlist-left-column">
				<form class="row admin-form" [formGroup]="cmsForm" (ngSubmit)="onSubmit($event)">
					<div class="col-sm-12">
						<label class="admin-form-label">Nazwa</label>
						<input class="admin-form-control" formControlName="name" type="text" name="name" matInput placeholder="Nazwa">
					</div>
					<div class="col-sm-12">
						<label class="admin-form-label">Zawartość</label>
						<angular-editor formControlName="content"></angular-editor>
					</div>
					<div class="col-sm-12">
						<button class="admin-form-button" type="submit">Zapisz</button>
					</div>
				</form>
			</div>
			<div class="paneltrainerlist-right-column">
				<ul class="paneltrainerlist-right-column-menu">
					<li class="paneltrainerlist-right-column-menu-item">
						<a [routerLink]="['/panel/admin']">
							<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
								 width="35.695px" height="35.695px" viewBox="0 0 35.695 35.695" style="enable-background:new 0 0 35.695 35.695;"
								 xml:space="preserve">
								<g>
									<path d="M11.558,10.767c0-3.473,2.815-6.29,6.289-6.29c3.476,0,6.289,2.817,6.289,6.29c0,3.475-2.813,6.29-6.289,6.29
										C14.373,17.057,11.558,14.243,11.558,10.767z M35.667,22.607l-0.879-5.27c-0.158-0.954-0.961-1.754-1.896-1.984
										c-0.836,0.74-1.932,1.191-3.136,1.191c-1.203,0-2.3-0.452-3.135-1.191c-0.938,0.229-1.739,1.03-1.897,1.984l-0.021,0.124
										c-0.522-0.503-1.17-0.881-1.868-1.052c-1.33,1.176-3.072,1.896-4.987,1.896s-3.657-0.72-4.987-1.896
										c-0.698,0.171-1.346,0.549-1.868,1.052l-0.021-0.124c-0.158-0.954-0.962-1.754-1.897-1.984c-0.835,0.74-1.932,1.191-3.135,1.191
										c-1.204,0-2.3-0.452-3.136-1.191c-0.936,0.229-1.738,1.03-1.896,1.984l-0.879,5.27c-0.189,1.131,0.596,2.057,1.741,2.057h7.222
										l-0.548,3.283c-0.3,1.799,0.948,3.271,2.771,3.271H24.48c1.823,0,3.071-1.475,2.771-3.271l-0.548-3.283h7.222
										C35.071,24.662,35.855,23.738,35.667,22.607z M29.755,15.762c2.184,0,3.954-1.77,3.954-3.954c0-2.183-1.771-3.954-3.954-3.954
										s-3.953,1.771-3.953,3.954C25.802,13.992,27.574,15.762,29.755,15.762z M5.938,15.762c2.183,0,3.953-1.77,3.953-3.954
										c0-2.183-1.771-3.954-3.953-3.954c-2.184,0-3.954,1.771-3.954,3.954C1.984,13.992,3.755,15.762,5.938,15.762z"/>
								</g>
							</svg>
							<span>Użytkownicy</span>
						</a>
					</li>
					<li class="paneltrainerlist-right-column-menu-item">
						<a [routerLink]="['/panel/admin/contents']">
							<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
								 viewBox="0 0 330 330" style="enable-background:new 0 0 330 330;" xml:space="preserve">
								<g id="XMLID_23_">
									<path id="XMLID_24_" d="M75,180v60c0,8.284,6.716,15,15,15h60c3.978,0,7.793-1.581,10.606-4.394l164.999-165
										c5.858-5.858,5.858-15.355,0-21.213l-60-60C262.794,1.581,258.978,0,255,0s-7.794,1.581-10.606,4.394l-165,165
										C76.58,172.206,75,176.022,75,180z M105,186.213L255,36.213L293.787,75l-150,150H105V186.213z"/>
									<path id="XMLID_27_" d="M315,150.001c-8.284,0-15,6.716-15,15V300H30V30H165c8.284,0,15-6.716,15-15s-6.716-15-15-15H15
										C6.716,0,0,6.716,0,15v300c0,8.284,6.716,15,15,15h300c8.284,0,15-6.716,15-15V165.001C330,156.716,323.284,150.001,315,150.001z"
										/>
								</g>
							</svg>
							<span>Treści na stronie</span>
						</a>
					</li>
					<li class="paneltrainerlist-right-column-menu-item">
						<a [routerLink]="['/panel/admin/cmslist']">
							<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
								 viewBox="0 0 488.477 488.477" style="enable-background:new 0 0 488.477 488.477;" xml:space="preserve">
							<g>
								<path d="M472.227,35.998H16.25C7.29,35.998,0,43.288,0,52.248v383.98c0,8.961,7.29,16.25,16.25,16.25h455.977
									c8.96,0,16.25-7.289,16.25-16.25V52.248C488.477,43.288,481.187,35.998,472.227,35.998z M8.5,269.795
									c8.632-7.003,18.187-12.819,28.452-17.27c29.968-12.996,63.203-13.544,93.585-1.541c30.38,12.002,54.269,35.117,67.265,65.087
									c13.004,29.988,13.292,64.555,0.883,95.235H8.5V269.795z M205.6,312.689c-13.899-32.053-39.448-56.774-71.94-69.61
									c-32.492-12.837-68.039-12.251-100.09,1.648c-8.89,3.855-17.292,8.657-25.07,14.324v-71.738l37.286-16.169l10.427,24.048
									c0.766,1.766,2.626,2.802,4.527,2.512c14.637-2.186,29.51-2.429,44.209-0.718c1.919,0.219,3.736-0.869,4.444-2.66l9.636-24.388
									l75.301,29.748l-9.635,24.388c-0.708,1.791-0.122,3.834,1.426,4.979c11.915,8.811,22.61,19.158,31.79,30.752
									c1.294,1.636,3.56,2.069,5.35,1.103l23.714-10.283l32.21,74.282l-23.667,10.263c-0.029,0.009-0.058,0.019-0.086,0.029
									c-1.933,0.67-3.113,2.622-2.811,4.645c2.192,14.644,2.435,29.525,0.722,44.23c-0.223,1.913,0.869,3.737,2.661,4.444l24.389,9.632
									l-5.198,13.156h-67.388C219.788,379.331,219.058,343.726,205.6,312.689z M283.73,390.329l-21.597-8.529
									c1.299-13.296,1.081-26.706-0.65-39.954l21.293-9.234c4.183-1.813,6.108-6.698,4.293-10.89l-32.383-74.68
									c-1.818-4.189-6.698-6.122-10.882-4.309l-21.295,9.234c-8.479-10.308-18.118-19.631-28.72-27.779l8.531-21.595
									c1.673-4.237-0.418-9.052-4.667-10.735l-75.706-29.908c-4.247-1.675-9.066,0.41-10.74,4.649l-8.532,21.594
									c-13.296-1.297-26.705-1.08-39.951,0.649l-9.235-21.297c-1.817-4.183-6.703-6.104-10.891-4.286L8.5,178.047v-75.241h471.477v43.833
									h-88.882c-2.347,0-4.25,1.903-4.25,4.25s1.903,4.25,4.25,4.25h88.882v256.167H284.333l4.043-10.233
									C290.052,396.825,287.969,392.006,283.73,390.329z M16.25,44.498h455.977c4.273,0,7.75,3.476,7.75,7.75v15.058H170.095
									c-2.347,0-4.25,1.903-4.25,4.25s1.903,4.25,4.25,4.25h309.882v18.5H8.5V52.248C8.5,47.975,11.977,44.498,16.25,44.498z
									 M472.227,443.978H16.25c-4.273,0-7.75-3.477-7.75-7.75v-16.423h471.477v16.423C479.977,440.502,476.5,443.978,472.227,443.978z"/>
								<path d="M38.095,84.806c8.409,0,15.25-6.841,15.25-15.25s-6.841-15.25-15.25-15.25c-8.409,0-15.25,6.841-15.25,15.25
									S29.686,84.806,38.095,84.806z M38.095,62.806c3.722,0,6.75,3.028,6.75,6.75s-3.028,6.75-6.75,6.75c-3.722,0-6.75-3.028-6.75-6.75
									S34.373,62.806,38.095,62.806z"/>
								<path d="M80.595,84.806c8.409,0,15.25-6.841,15.25-15.25s-6.841-15.25-15.25-15.25c-8.409,0-15.25,6.841-15.25,15.25
									S72.186,84.806,80.595,84.806z M80.595,62.806c3.722,0,6.75,3.028,6.75,6.75s-3.028,6.75-6.75,6.75c-3.722,0-6.75-3.028-6.75-6.75
									S76.873,62.806,80.595,62.806z"/>
								<path d="M123.095,84.806c8.409,0,15.25-6.841,15.25-15.25s-6.841-15.25-15.25-15.25c-8.409,0-15.25,6.841-15.25,15.25
									S114.686,84.806,123.095,84.806z M123.095,62.806c3.722,0,6.75,3.028,6.75,6.75s-3.028,6.75-6.75,6.75
									c-3.722,0-6.75-3.028-6.75-6.75S119.373,62.806,123.095,62.806z"/>
								<path d="M52.633,288.691c-2.153,0.934-3.142,3.437-2.208,5.59c0.935,2.154,3.438,3.141,5.59,2.208
									c37.625-16.315,81.507,1.022,97.823,38.646c0.695,1.603,2.259,2.56,3.901,2.56c0.564,0,1.138-0.113,1.689-0.352
									c2.153-0.934,3.142-3.437,2.208-5.59C143.456,289.83,94.557,270.512,52.633,288.691z"/>
							</g>
							</svg>
							<span>Strony CMS</span>
						</a>
					</li>
					<li class="paneltrainerlist-right-column-menu-item">
						<a [routerLink]="['/panel/admin/socials']">
							<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
									 width="76.609px" height="76.609px" viewBox="0 0 76.609 76.609" style="enable-background:new 0 0 76.609 76.609;"
									 xml:space="preserve">
								<g>
									<g>
										<path d="M57.455,16.575c0,1.12,1.121,2.24,2.688,2.24c2.239,0,2.911-0.896,2.911-2.24c0-0.224,0-0.224,0-0.447
											c-0.223-0.672-0.896-1.12-1.566-1.568c-0.225,0-0.672-0.224-1.119-0.224C58.799,14.337,57.455,15.232,57.455,16.575z"/>
										<path d="M62.16,9.183c-0.226-1.344-1.121-2.464-2.24-2.464c-1.121,0-1.793,1.12-1.567,2.464c0.226,1.344,1.119,2.464,2.239,2.464
											C61.488,11.647,62.383,10.527,62.16,9.183z"/>
										<path d="M52.08,25.983l3.808-3.808c2.239,1.792,4.93,2.912,7.841,2.912c6.943,0,12.544-5.601,12.544-12.544
											C76.271,5.599,70.672,0,63.728,0c-6.942,0-12.544,5.6-12.544,12.544c0,3.136,1.119,5.823,2.912,7.84l-3.809,3.809
											C50.959,24.864,51.631,25.537,52.08,25.983z M65.744,8.511h2.463V6.048h0.896v2.464h2.465v0.896h-2.465v2.465h-0.896V9.407h-2.463
											V8.511L65.744,8.511z M60.144,12.767c0-0.224,0-0.448,0.226-0.672c-0.226,0-0.226,0-0.447,0c-1.793,0-3.137-1.344-3.137-2.912
											s1.791-2.912,3.584-2.912H64.4l-0.896,0.672H62.16c0.896,0.225,1.344,1.345,1.344,2.464c0,0.896-0.448,1.568-1.121,2.24
											c-0.672,0.448-0.896,0.672-0.896,1.12c0,0.448,0.673,1.12,1.12,1.344c1.119,0.672,1.344,1.568,1.344,2.688
											c0,1.567-1.344,2.912-4.031,2.912c-2.239,0-4.256-0.896-4.256-2.464s1.791-2.912,4.031-2.912c0.225,0,0.447,0,0.672,0
											C60.367,13.664,60.144,13.216,60.144,12.767z"/>
										<path d="M63.728,51.52c-2.911,0-5.823,1.119-7.841,2.912l-3.807-3.809c-0.449,0.672-1.121,1.118-1.568,1.566l3.809,3.809
											c-1.792,2.238-2.912,4.929-2.912,7.841c0,6.943,5.602,12.544,12.545,12.544c6.942,0,12.545-5.601,12.545-12.544
											C76.271,56.895,70.672,51.52,63.728,51.52z M60.144,69.664H57.68v-7.841h2.464V69.664z M59.024,60.705L59.024,60.705
											c-0.896,0-1.567-0.673-1.567-1.346c0-0.672,0.672-1.344,1.567-1.344s1.567,0.672,1.567,1.344
											C60.367,60.256,59.92,60.705,59.024,60.705z M70.224,69.664h-2.912v-4.031c0-1.119-0.447-1.792-1.345-1.792
											c-0.672,0-1.119,0.448-1.344,0.896c0,0.227,0,0.449,0,0.673v4.257h-2.688c0,0,0-7.168,0-7.841h2.688v1.12
											c0.225-0.447,1.121-1.344,2.465-1.344c1.791,0,3.136,1.119,3.136,3.584V69.664L70.224,69.664z"/>
										<path d="M24.304,50.625l-3.808,3.809c-2.24-1.793-4.928-2.912-7.84-2.912c-6.944,0-12.544,5.602-12.544,12.543
											c0,6.944,5.6,12.545,12.544,12.545c6.943,0,12.544-5.601,12.544-12.545c0-2.912-1.12-5.823-2.912-7.84l3.808-3.809
											C25.424,51.743,24.752,51.07,24.304,50.625z M18.033,62.048c0,3.808-2.912,8.063-8.064,8.063c-1.567,0-3.136-0.448-4.256-1.346
											c0.224,0,0.448,0,0.672,0c1.344,0,2.464-0.447,3.584-1.119c-1.12,0-2.24-0.896-2.688-2.017c0.224,0,0.447,0,0.447,0
											c0.225,0,0.448,0,0.673,0c-1.345-0.226-2.24-1.344-2.24-2.688l0,0c0.448,0.225,0.896,0.447,1.344,0.447
											c-0.672-0.447-1.344-1.344-1.344-2.24c0-0.446,0.224-0.896,0.448-1.344c1.344,1.793,3.359,2.912,5.823,2.912
											c0-0.226,0-0.447,0-0.672c0-1.568,1.345-2.912,2.912-2.912c0.896,0,1.568,0.447,2.017,0.896c0.672-0.225,1.344-0.448,1.792-0.672
											c-0.225,0.672-0.673,1.119-1.345,1.566c0.672,0,1.12-0.225,1.568-0.447c-0.448,0.672-0.896,1.119-1.344,1.344
											C18.033,61.823,18.033,61.823,18.033,62.048z"/>
										<path d="M12.656,25.088c2.912,0,5.823-1.12,7.84-2.912l3.808,3.808c0.448-0.672,1.12-1.119,1.568-1.567l-3.809-3.808
											c1.792-2.24,2.912-4.928,2.912-7.84c0-6.944-5.6-12.544-12.544-12.544c-6.943,0-12.319,5.6-12.319,12.544
											C0.112,19.487,5.712,25.088,12.656,25.088z M9.744,10.527h1.344V9.183c0-1.792,0.672-2.912,2.912-2.912h1.792v2.24h-1.12
											c-0.896,0-0.896,0.224-0.896,0.896v1.12h2.016l-0.224,2.24H13.55v6.272h-2.688v-6.272H9.519v-2.24H9.744z"/>
										<g>
											<path d="M49.617,44.575c-0.226-0.446-0.673-0.673-0.896-0.673c-2.016-0.672-3.807-1.118-5.823-1.791
												c-0.224,0-0.672-0.447-0.672-1.344c0-0.449,0-0.673-0.447-0.673c-0.226,0,0,0-0.226-0.224c-0.223-0.896-0.223-1.345-0.223-1.568
												c0-0.223,0.223-0.225,0.223-0.448c0.673-0.896,0.896-2.239,0.896-2.688c0,0,0.225,0,0.225-0.225
												c0.224-0.448,0.224-0.448,0.224-1.121c0.226-0.446,0.226-1.118-0.224-1.118c-0.225,0.224-0.225,0-0.225-0.448v-2.688
												c0-0.896-0.673-1.567-1.119-1.791c-0.674-0.449-0.896-0.673-1.121-0.673c-0.225-0.224-0.225-0.448,0-0.672
												c0.224-0.223,0.447-0.223,0.447-0.448c0,0,0,0-0.225,0s-1.567,0.226-2.239,0.448c-1.12,0.224-2.24,0.672-3.137,1.345
												c-0.672,0.447-1.12,1.119-1.12,2.017c0,0.446,0,1.791,0,2.688c0,0.226,0,0.448-0.224,0.226c-0.672,0-0.224,0.896-0.224,1.118
												c0,0.448,0.224,0.673,0.447,1.121c0,0.225,0.225,0.225,0.225,0.225c0.224,0.672,0.448,2.016,0.896,2.688
												c0,0,0.225,0.225,0.225,0.448c0,0.448,0,1.12-0.225,1.568c0,0,0,0.225-0.224,0.225c-0.448,0-0.448,0.224-0.448,0.672
												c0,0.672-0.448,1.345-0.672,1.345c-1.12,0.446-4.479,1.567-5.6,1.791c-0.672,0.226-0.896,0.448-1.12,0.672l-1.12,2.688
												c4.479,0,6.72,2.688,11.424,3.584h2.24c4.704-0.672,8.063-3.584,11.2-3.584L49.617,44.575z"/>
										</g>
									</g>
								</g>
							</svg>
							<span>Media społecznościowe</span>
						</a>
					</li>
					<li class="paneltrainerlist-right-column-menu-item">
						<a [routerLink]="['/panel/admin/ingredients']">
							<svg fill="#000000" height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
								 viewBox="0 0 512 512" xml:space="preserve">
								<g transform="translate(1 1)">
									<g>
										<g>
											<path d="M229.48,263.533c-5.12,0-8.533,3.413-8.533,8.533c0,5.12,3.413,8.533,8.533,8.533c5.12,0,8.533-3.413,8.533-8.533
												C238.013,266.947,234.6,263.533,229.48,263.533z"/>
											<path d="M246.547,237.933c0,5.12,3.413,8.533,8.533,8.533c5.12,0,8.533-3.413,8.533-8.533s-3.413-8.533-8.533-8.533
												C249.96,229.4,246.547,232.813,246.547,237.933z"/>
											<path d="M229.48,212.333c-5.12,0-8.533,3.413-8.533,8.533s3.413,8.533,8.533,8.533c5.12,0,8.533-3.413,8.533-8.533
												S234.6,212.333,229.48,212.333z"/>
											<path d="M255.08,289.133c-5.12,0-8.533,3.413-8.533,8.533s3.413,8.533,8.533,8.533c5.12,0,8.533-3.413,8.533-8.533
												S260.2,289.133,255.08,289.133z"/>
											<path d="M491.453,223.427c-3.413-3.413-8.533-3.413-11.947,0L362.6,340.333H246.547c0-5.12-3.413-8.533-8.533-8.533
												s-8.533,3.413-8.533,8.533h-128c-5.12,0-8.533,3.413-8.533,8.533v51.2c0,39.609,20.574,74.248,51.654,93.867h-33.734
												L69.053,485.4c-5.12-0.853-9.387,2.56-10.24,6.827c-0.853,5.12,2.56,9.387,6.827,10.24L108.307,511c0.853,0,0.853,0,1.707,0
												h93.867h136.533h93.867c0.853,0,0.853,0,1.707,0l42.667-8.533c5.12-0.853,7.68-5.973,6.827-10.24
												c-0.853-5.12-5.973-7.68-10.24-6.827l-41.813,8.533h-33.734c31.08-19.619,51.654-54.257,51.654-93.867v-51.2
												c0-5.12-3.413-8.533-8.533-8.533h-56.32l104.96-104.96C494.867,231.96,494.867,226.84,491.453,223.427z M434.28,400.067
												c0,52.053-41.813,93.867-93.867,93.867H203.88c-52.053,0-93.867-41.813-93.867-93.867V357.4H434.28V400.067z"/>
											<path d="M169.747,243.053c6.827,0,13.653-2.56,18.773-8.533l64-64c8.533-8.533,9.387-22.187,2.56-32.427l-88.747-128
												C162.067,3.267,155.24-0.147,147.56-1c-7.68,0-15.36,2.56-20.48,7.68L23.827,110.787c-5.973,5.12-8.533,12.8-7.68,20.48
												c0.853,7.68,5.12,14.507,11.093,18.773l128,88.747C159.507,241.347,164.627,243.053,169.747,243.053z M33.213,129.56
												c0-1.707,0-5.12,2.56-6.827L139.88,18.627c2.56-2.56,5.12-2.56,6.827-2.56c0.853,0,4.267,0.853,5.973,3.413l87.893,128.853
												c2.56,3.413,1.707,8.533-0.853,11.093l-64,64c-3.413,2.56-7.68,3.413-11.093,0.853l-128-88.747
												C34.067,133.827,33.213,131.267,33.213,129.56z"/>
											<path d="M184.253,131.267c6.827-5.973,10.24-14.507,10.24-23.893c0-8.533-3.413-17.067-10.24-23.893
												c-11.093-11.947-29.013-13.653-41.813-5.12c-13.653-7.68-31.573-5.973-42.667,5.12c-11.947,11.093-13.653,29.013-5.12,41.813
												c-7.68,12.8-5.973,30.72,5.12,41.813c6.827,6.827,15.36,10.24,23.893,10.24s17.92-3.413,23.893-9.387
												c7.68-7.68,11.093-17.067,10.24-26.453C167.187,141.507,177.427,138.093,184.253,131.267z M135.613,118.467
												c-1.707,1.707-2.56,4.267-2.56,5.973s0.853,4.267,2.56,5.973c6.827,6.827,6.827,17.067,0,23.893s-17.067,6.827-23.893,0
												s-6.827-17.067,0-23.893c3.413-3.413,3.413-8.533,0-11.947c-6.827-6.827-6.827-17.067,0-23.893
												c3.413-3.413,7.68-5.12,11.947-5.12s8.533,1.707,11.947,5.12s8.533,3.413,11.947,0c6.827-6.827,17.067-6.827,23.893,0
												c3.413,3.413,5.12,7.68,5.12,11.947s-1.707,8.533-5.12,11.947c-6.827,6.827-17.067,6.827-23.893,0
												C144.147,115.053,139.027,115.053,135.613,118.467z"/>
										</g>
									</g>
								</g>
							</svg>
							<span>Składniki</span>
						</a>
					</li>
				</ul>
			</div>
		</div>
		<div class="col-sm-12 px-0">
			<app-page-footer></app-page-footer>
		</div>
	</div>
</div>
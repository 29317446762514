<div class="container">
	<div class="row">
		<div class="col-sm-12">
			<app-page-header></app-page-header>
		</div>
		<div class="col-sm-12">
			<app-panelheader [id_tab]="5"></app-panelheader>
			<div class="panelprofile">
				<app-alert [alerts]="alerts" style="width: 100%;"></app-alert>
				<form class="panelprofile-form" [formGroup]="profileForm" (ngSubmit)="onSubmit($event)">
					<div class="panelprofile-form-header">
						<div class="panelprofile-form-header-content">
							<div class="row">
								<div class="panelprofile-form-header-content-info-box col-sm-6">
									<div class="panelprofile-form-header-content-info row">
										<div class="panelprofile-form-header-content-info-field col-sm-12 description">
											<label class="panelprofile-form-header-content-info-field-label">Preferencje smakowe</label>
											<textarea [value]="client.user?.tastepreferences || ''" [readonly]="userData.user?.isTrainer" formControlName="tastepreferences" class="panelprofile-form-header-content-info-field-textarea"></textarea>
										</div>
									</div>
								</div>
								<div class="panelprofile-form-header-content-info-box col-sm-6">
									<div class="panelprofile-form-header-content-info row">
										<div class="panelprofile-form-header-content-info-field col-sm-12 description">
											<label class="panelprofile-form-header-content-info-field-label">Przebyte choroby</label>
											<textarea [value]="client.user?.pastdiseases || ''" [readonly]="userData.user?.isTrainer" formControlName="pastdiseases" class="panelprofile-form-header-content-info-field-textarea"></textarea>
										</div>
									</div>
								</div>
								<div class="panelprofile-form-header-content-info-box col-sm-6">
									<div class="panelprofile-form-header-content-info row">
										<div class="panelprofile-form-header-content-info-field col-sm-12 description">
											<label class="panelprofile-form-header-content-info-field-label">Przebyte kontuzje</label>
											<textarea [value]="client.user?.pastinjuries || ''" [readonly]="userData.user?.isTrainer" formControlName="pastinjuries" class="panelprofile-form-header-content-info-field-textarea"></textarea>
										</div>
									</div>
								</div>
								<div class="panelprofile-form-header-content-info-box col-sm-6">
									<div class="panelprofile-form-header-content-info row">
										<div class="panelprofile-form-header-content-info-field col-sm-12 description">
											<label class="panelprofile-form-header-content-info-field-label">Uwagi do treningu</label>
											<textarea [value]="client.user?.trainingnotes || ''" [readonly]="userData.user?.isTrainer" formControlName="trainingnotes" class="panelprofile-form-header-content-info-field-textarea"></textarea>
										</div>
									</div>
								</div>
								<div class="panelprofile-form-header-content-info-box col-sm-6">
									<div class="panelprofile-form-header-content-info row">
										<div class="panelprofile-form-header-content-info-field col-sm-12 description">
											<label class="panelprofile-form-header-content-info-field-label">Uwagi do diety</label>
											<textarea [value]="client.user?.dietnotes || ''" [readonly]="userData.user?.isTrainer" formControlName="dietnotes" class="panelprofile-form-header-content-info-field-textarea"></textarea>
										</div>
									</div>
								</div>
								<div class="panelprofile-form-header-content-info-box col-sm-6">
									<div class="panelprofile-form-header-content-info row">
										<div class="panelprofile-form-header-content-info-field col-sm-12 description">
											<label class="panelprofile-form-header-content-info-field-label">Przeciwskazania, oraz niedogodności zdrowotne: te, które mogłyby wpływać na rodzaj treningu i wybranych ćwiczeń</label>
											<textarea [value]="client.user?.contraindications || ''" [readonly]="userData.user?.isTrainer" formControlName="contraindications" class="panelprofile-form-header-content-info-field-textarea"></textarea>
										</div>
									</div>
								</div>
							</div>
							<button *ngIf="!userData.user?.isTrainer" class="panelprofile-form-header-content-info-submit" type="submit">
								<span>Zapisz zmiany</span>
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
		<div class="col-sm-12 px-0">
			<app-page-footer></app-page-footer>
		</div>
	</div>
</div>
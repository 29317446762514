<div class="header__menu">
    <ul class="menu">
      <li class="menu__element" *ngFor="let element of menuElements">
        <a
          [routerLink]="element.url"
          class="menu__element-anchor"
          >{{ element.name }}</a
        >
      </li>
	</ul>
</div>
<app-submenu class="menu-submenu"></app-submenu>

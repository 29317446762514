<div class="ingredientdialog">
	<div class="row">
		<div class="col-sm-12">
			<div class="ingredientdialog-content">
				<form class="ingredientdialog-form" [formGroup]="ingredientForm" (ngSubmit)="onSubmit($event)">
					<h1 class="ingredientdialog-form-title">
						Dodaj składnik
					</h1>
					<div class="col-sm-12 ingredientdialog-form-field-textarea">
						<label class="ingredientdialog-form-input-label">Nazwa</label>
						<input formControlName="name" name="name" class="ingredientdialog-form-input">
					</div>
					<div class="col-sm-12 ingredientdialog-form-field-textarea">
						<label class="ingredientdialog-form-input-label">Kcal</label>
						<input formControlName="kcal" name="kcal" class="ingredientdialog-form-input">
					</div>
					<div class="col-sm-12 ingredientdialog-form-field-textarea">
						<label class="ingredientdialog-form-input-label">Białko</label>
						<input formControlName="protein" name="protein" class="ingredientdialog-form-input">
					</div>
					<div class="col-sm-12 ingredientdialog-form-field-textarea">
						<label class="ingredientdialog-form-input-label">Węglowodany</label>
						<input formControlName="carbohydrates" name="carbohydrates" class="ingredientdialog-form-input">
					</div>
					<div class="col-sm-12 ingredientdialog-form-field-textarea">
						<label class="ingredientdialog-form-input-label">Tłuszcz</label>
						<input formControlName="fat" name="fat" class="ingredientdialog-form-input">
					</div>
					<div class="col-sm-12 ingredientdialog-form-field-textarea">
						<label class="ingredientdialog-form-input-label">Ilość</label>
						<input formControlName="amount" name="amount" class="ingredientdialog-form-input">
					</div>
					<div class="col-sm-12 ingredientdialog-form-field-textarea">
						<label class="ingredientdialog-form-input-label">Jednostka</label>
						<input formControlName="unit" name="unit" maxlength="3" class="ingredientdialog-form-input">
					</div>
					<div class="col-sm-12">
						<button class="ingredientdialog-form-button" type="submit">dodaj</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
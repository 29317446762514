import { Component, OnInit } from '@angular/core';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';
import {MatDialog} from '@angular/material/dialog';
import { IngredientadmindialogComponent } from '../components/ingredientadmindialog/ingredientadmindialog.component';
import { Alert } from 'src/app/models/alert.model';

@Component({
  selector: 'app-paneladminingredients',
  templateUrl: './paneladminingredients.component.html',
  styleUrls: ['./paneladminingredients.component.scss']
})
export class PaneladminingredientsComponent implements OnInit {

  adminIngredients: UsersData.Ingredient[] = [];
  typeOfMeal: number = 1;
  alerts: Alert[] = [];
  displayedColumns: string[] = ['id', 'name', 'actions'];

  constructor(public dialog: MatDialog, private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) {
    if(!this.cookieService.get('sessionToken') || (!this.cookieService.get('sessionFA') || this.cookieService.get('sessionFA') !== '1'))
		this.router.navigate(['/logowanie']);
  }

  convertRemToPixels(rem: number): number {    
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }

  ngOnInit(): void {
	this.getModeratorIngredients();
  }

  getModeratorIngredients() {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getModeratorIngredients(token, this.typeOfMeal).subscribe(
	  (ingredients: UsersData.Ingredient[]) => {
		_self.adminIngredients = ingredients;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  deleteAdminIngredient(id: number) {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.deleteIngredient(id, token).subscribe(
	  (messageFromAPI: UsersData.MessageFromAPI) => {
		if(messageFromAPI.result === 'success') {
			_self.alerts = [];
		  	_self.alerts.push({message: messageFromAPI.message, type: 'success'});
	  		window.scroll(0,0);
			_self.getModeratorIngredients();
		}
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  editIngredient(ingredient: UsersData.Ingredient) {
	var _self = this;
  	const dialogRef = this.dialog.open(IngredientadmindialogComponent, {
      data: ingredient
    });
  	dialogRef.afterClosed().subscribe(result => {
		_self.getModeratorIngredients();
    });
  }

  addIngredient() {
	var _self = this;
    var ingredient = {
		type: this.typeOfMeal
	};
  	const dialogRef = this.dialog.open(IngredientadmindialogComponent, {
      data: ingredient
    });
  	dialogRef.afterClosed().subscribe(result => {
		_self.getModeratorIngredients();
    });
  }
}

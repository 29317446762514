import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';
import {MatDialog} from '@angular/material/dialog';
import { ConfirmdialogComponent } from 'src/app/client/page/components/confirmdialog/confirmdialog.component';
import { MessagedialogComponent } from '../components/messagedialog/messagedialog.component';
import { Alert } from 'src/app/models/alert.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-paneladmincms',
  templateUrl: './paneladmincms.component.html',
  styleUrls: ['./paneladmincms.component.scss']
})
export class PaneladmincmsComponent implements OnInit {

  cms: UsersData.Cms = {};
  alerts: Alert[] = [];
  public cmsForm: FormGroup;
  cmsid: number = 0;

  constructor(private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) {
	if(!this.cookieService.get('sessionToken') || (!this.cookieService.get('sessionFA') || this.cookieService.get('sessionFA') !== '1'))
		this.router.navigate(['/logowanie']);
	this.cmsForm = new FormGroup({
      'name': new FormControl('', []),
      'content': new FormControl('', []),
    });
    var _self = this;
    this.activatedRoute.params.subscribe(params => {
    	if(typeof params['cmsid'] !== 'undefined' && params['cmsid']) {
      		_self.cmsid = params['cmsid'];
      		
    	}
    });
  }

  ngOnInit(): void {
	this.getCms();
  }
  
  onSubmit(event: any): void {
  	this.alerts = [];
	if (this.cmsForm.invalid) {
		this.alerts.push({message: 'Prosimy poprawnie wypełnić wszystkie wymagane pola', type: 'danger'});
		for (var key in this.cmsForm.errors) {
			this.alerts.push({message: key, type: 'danger'});
		}
	  return;
	}
    var _self = this;
	var token = this.cookieService.get('sessionToken');
    var date = {
		token: token,
		id: this.cmsid,
		name: this.cmsForm.value.name,
		content: this.cmsForm.value.content
    }
    this._loginService.setCms(date).subscribe(
		(messageFromAPI: UsersData.MessageFromAPI) => {
			if(messageFromAPI?.result === 'success') {
				this.alerts.push({message: (messageFromAPI?.message ? messageFromAPI?.message : ''), type: 'success'});
				this.router.navigate(['/panel/admin/cmslist']);
			}
			else
				this.alerts.push({message: (messageFromAPI?.message ? messageFromAPI?.message : ''), type: 'danger'});
	    },
	    (error: HttpErrorResponse) => {
			this.alerts.push({message: 'Wystąpił błąd podczas zapisu danych', type: 'danger'});
		},
    );
  }
  
  getCms() {
    var _self = this;
	var token = this.cookieService.get('sessionToken');
    this._loginService.getCms(this.cmsid).subscribe(
		(cms: UsersData.Cms) => {
		    _self.cmsForm.controls['name'].setValue(cms.name);
		    _self.cmsForm.controls['content'].setValue(cms.content);
		},
	    (error: HttpErrorResponse) => {
		},
    );
  }
}

<div class="container">
	<div class="row">
		<div class="col-sm-12">
			<app-page-header></app-page-header>
		</div>
		<div class="col-sm-12">
			<app-panelheader *ngIf="!userData.user?.isTrainer" [id_tab]="4"></app-panelheader>
			<div class="panelprofile">
				<app-alert [alerts]="alerts" style="width: 100%;"></app-alert>
				<form class="panelprofile-form" [formGroup]="profileForm" (ngSubmit)="onSubmit($event)">
					<div class="panelprofile-form-header">
						<div class="panelprofile-form-header-title">
							Dane podstawowe
						</div>
						<div class="panelprofile-form-header-content">
							<div class="row">
								<div class="panelprofile-form-header-content-photo col-sm-12 col-xl-4 photo">
									<img class="panelprofile-form-header-content-photo-img" *ngIf="userData.user?.photo" src="{{userData.user?.photo}}"/>
									<img class="panelprofile-form-header-content-photo-img" *ngIf="!userData.user?.photo" src="/assets/img/default_img.svg"/>
									<input type="file" (change)="onFileChanged($event)" style="display: none;" #fileInput>
									<div class="panelprofile-form-header-content-photo-button">
										<button class="panelprofile-form-header-content-photo-button-edit" type="button" (click)="fileInput.click()">zmień zdjęcie</button>
										<button class="panelprofile-form-header-content-photo-button-delete" type="button" (click)="deleteImage()">usuń zdjęcie</button>
									</div>
								</div>
								<div class="panelprofile-form-header-content-info-box col-sm-12 col-xl-8">
									<div class="panelprofile-form-header-content-info row">
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-3">
											<label class="panelprofile-form-header-content-info-field-label">Imię <sup style="color: red;">*wymagane</sup></label>
											<input formControlName="firstname" class="panelprofile-form-header-content-info-field-input" type="text" value="{{userData.user?.firstname}}">
										</div>
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-3">
											<label class="panelprofile-form-header-content-info-field-label">Nazwisko <sup style="color: red;">*wymagane</sup></label>
											<input formControlName="lastname" class="panelprofile-form-header-content-info-field-input" type="text" value="{{userData.user?.lastname}}">
										</div>
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-3">
											<label class="panelprofile-form-header-content-info-field-label">Płeć</label>
											<select formControlName="gender" class="panelprofile-form-header-content-info-field-input">
												<option [ngValue]="'male'" [selected]="userData.user?.gender === 'male'">Mężczyzna</option>
												<option [ngValue]="'female'" [selected]="userData.user?.gender === 'female'">Kobieta</option>
											</select>
										</div>
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-3">
											<label class="panelprofile-form-header-content-info-field-label">Data urodzin</label>
											<input formControlName="birthday" class="panelprofile-form-header-content-info-field-input" type="date" value="{{userData.user?.birthday}}">
										</div>
									</div>
									<div class="panelprofile-form-header-content-info row">
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-3">
											<label class="panelprofile-form-header-content-info-field-label">Województwo</label>
											<select class="panelprofile-form-header-content-info-field-input" [value]="this.userData.user?.address?.state" formControlName="state" type="text" name="state">
												<option *ngFor="let state of states" [value]="state.name" [selected]="state.name === this.userData.user?.address?.state">{{state.name}}</option>
											</select>
										</div>
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-3">
											<label class="panelprofile-form-header-content-info-field-label">Miasto</label>
											<mat-form-field class="login-form-input" floatLabel="never">
												<input formControlName="city" type="text" value="{{userData.user?.address?.city}}" name="city" matInput placeholder="miasto" [matAutocomplete]="auto" (input)="onSearchChange($event.target.value)">
												<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
											      <mat-option *ngFor="let city of cities" [value]="city.name">
												    {{city.name}}
												  </mat-option>
											    </mat-autocomplete>
											</mat-form-field>
										</div>
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-3">
											<label *ngIf="!userData?.user?.isTrainer" class="panelprofile-form-header-content-info-field-label">Preferencje płci trenera</label>
											<label *ngIf="userData?.user?.isTrainer" class="panelprofile-form-header-content-info-field-label">Preferencje płci podopiecznego</label>
											<select formControlName="gender_client" class="panelprofile-form-header-content-info-field-input">
												<option [ngValue]="''" [selected]="!userData.user?.gender_client"></option>
												<option [ngValue]="'male'" [selected]="userData.user?.gender_client === 'male'">Mężczyzna</option>
												<option [ngValue]="'female'" [selected]="userData.user?.gender_client === 'female'">Kobieta</option>
											</select>
										</div>
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-3">
											<label class="panelprofile-form-header-content-info-field-label">Trening online</label>
											<select formControlName="isOnline" class="panelprofile-form-header-content-info-field-input">
												<option [ngValue]="true" [selected]="userData.user?.isOnline">Tak</option>
												<option [ngValue]="false" [selected]="!userData.user?.isOnline">Nie</option>
											</select>
										</div>
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-3" *ngIf="userData?.user?.isTrainer">
											<label class="panelprofile-form-header-content-info-field-label">Układanie diety</label>
											<select formControlName="isDietician" class="panelprofile-form-header-content-info-field-input">
												<option [ngValue]="true" [selected]="userData.user?.isDietician">Tak</option>
												<option [ngValue]="false" [selected]="!userData.user?.isDietician">Nie</option>
											</select>
										</div>
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-3">
											<label class="panelprofile-form-header-content-info-field-label">Wyświetl profil w serwisie</label>
											<select formControlName="displayAsClient" class="panelprofile-form-header-content-info-field-input">
												<option [ngValue]="true" [selected]="userData.user?.displayAsClient">Tak</option>
												<option [ngValue]="false" [selected]="!userData.user?.displayAsClient">Nie</option>
											</select>
										</div>
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-3">
											<label *ngIf="!userData?.user?.isTrainer" class="panelprofile-form-header-content-info-field-label">Preferowany wiek trenera:</label>
											<label *ngIf="userData?.user?.isTrainer" class="panelprofile-form-header-content-info-field-label">Preferowany wiek podopiecznego:</label>
											<ngx-slider [(value)]="preferredAgeMin" [(highValue)]="preferredAgeMax" [options]="options"></ngx-slider>
											<!-- <input formControlName="preferredAge" class="panelprofile-form-header-content-info-field-input" type="text" value="{{userData.user?.preferredAge}}"> -->
										</div>
									</div>
									<div class="panelprofile-form-header-content-info row">
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-3">
											<label class="panelprofile-form-header-content-info-field-label">Telefon</label>
											<input formControlName="phone" class="panelprofile-form-header-content-info-field-input" type="text" value="{{userData.user?.phone}}">
										</div>
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-3">
											<label class="panelprofile-form-header-content-info-field-label">E-mail</label>
											<input formControlName="email" class="panelprofile-form-header-content-info-field-input" type="text" value="{{(userData.user?.email ? userData.user?.email : userData.user?.login)}}">
										</div>
									</div>
								</div>
							</div>
						</div>
					</div><div class="panelprofile-form-header">
						<div class="panelprofile-form-header-title">
							Dane dodatkowe
						</div>
						<div class="panelprofile-form-header-content">
							<div class="row">
								<div class="panelprofile-form-header-content-info-box col-sm-12 col-xl-6">
									<div class="panelprofile-form-header-content-info row">
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-4">
											<label *ngIf="!userData?.user?.isTrainer" class="panelprofile-form-header-content-info-field-label">Obszar, który chcę usprawnić:</label>
											<label *ngIf="userData?.user?.isTrainer" class="panelprofile-form-header-content-info-field-label">Specjalizacja:</label>
											<select formControlName="specialization" class="panelprofile-form-header-content-info-field-input">
												<option *ngFor="let specialization of specializations" [ngValue]="specialization.id">{{specialization.name}}</option>
											</select>
											<ul class="panelprofile-form-header-content-info-field-list" *ngIf="userData?.user?.specializations">
												<li class="panelprofile-form-header-content-info-field-list-item" *ngFor="let object of userData?.user?.specializations">
													<span>{{object?.specialization?.name}}</span><button class="panelprofile-form-header-content-info-field-list-item-button" type="button" (click)="deleteSpecialization(object?.id || 0)"><svg height="365.696pt" viewBox="0 0 365.696 365.696" width="365.696pt" xmlns="http://www.w3.org/2000/svg"><path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0"/></svg></button>
												</li>
											</ul>
											<button class="panelprofile-form-header-content-info-field-button" type="button" (click)="addSpecialization()">
												<svg id="Capa_1" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><g id="_x33_"><path d="m18 2c2.206 0 4 1.794 4 4v12c0 2.206-1.794 4-4 4h-12c-2.206 0-4-1.794-4-4v-12c0-2.206 1.794-4 4-4zm0-2h-12c-3.314 0-6 2.686-6 6v12c0 3.314 2.686 6 6 6h12c3.314 0 6-2.686 6-6v-12c0-3.314-2.686-6-6-6z"/></g><g id="_x32_"><path d="m12 18c-.552 0-1-.447-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10c0 .553-.448 1-1 1z"/></g><g id="_x31_"><path d="m6 12c0-.552.447-1 1-1h10c.552 0 1 .448 1 1s-.448 1-1 1h-10c-.553 0-1-.448-1-1z"/></g></svg>
												<span>dodaj kolejne</span>
											</button>
										</div>
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-4">
											<label class="panelprofile-form-header-content-info-field-label">Miejsca, w których trenuję</label>
											<input formControlName="place" class="panelprofile-form-header-content-info-field-input" type="text">
											<ul class="panelprofile-form-header-content-info-field-list">
												<li class="panelprofile-form-header-content-info-field-list-item" *ngFor="let place of getPlaces()">
													<span>{{place.value}}</span><button class="panelprofile-form-header-content-info-field-list-item-button" type="button" (click)="deleteAttr(place.id)"><svg height="365.696pt" viewBox="0 0 365.696 365.696" width="365.696pt" xmlns="http://www.w3.org/2000/svg"><path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0"/></svg></button>
												</li>
											</ul>
											<button class="panelprofile-form-header-content-info-field-button" type="button" (click)="addPlace()">
												<svg id="Capa_1" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><g id="_x33_"><path d="m18 2c2.206 0 4 1.794 4 4v12c0 2.206-1.794 4-4 4h-12c-2.206 0-4-1.794-4-4v-12c0-2.206 1.794-4 4-4zm0-2h-12c-3.314 0-6 2.686-6 6v12c0 3.314 2.686 6 6 6h12c3.314 0 6-2.686 6-6v-12c0-3.314-2.686-6-6-6z"/></g><g id="_x32_"><path d="m12 18c-.552 0-1-.447-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10c0 .553-.448 1-1 1z"/></g><g id="_x31_"><path d="m6 12c0-.552.447-1 1-1h10c.552 0 1 .448 1 1s-.448 1-1 1h-10c-.553 0-1-.448-1-1z"/></g></svg>
												<span>dodaj kolejne</span>
											</button>
										</div>
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-4">
											<label class="panelprofile-form-header-content-info-field-label">Preferowane siłownie</label>
											<ul class="panelprofile-form-header-content-info-field-list">
												<li class="panelprofile-form-header-content-info-field-list-item" *ngFor="let gym of getGyms()">
													<span>{{gym.value}}</span><button class="panelprofile-form-header-content-info-field-list-item-button" type="button" (click)="deleteAttr(gym.id)"><svg height="365.696pt" viewBox="0 0 365.696 365.696" width="365.696pt" xmlns="http://www.w3.org/2000/svg"><path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0"/></svg></button>
												</li>
											</ul>
											<button class="panelprofile-form-header-content-info-field-button" type="button" (click)="addGym()">
												<svg id="Capa_1" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><g id="_x33_"><path d="m18 2c2.206 0 4 1.794 4 4v12c0 2.206-1.794 4-4 4h-12c-2.206 0-4-1.794-4-4v-12c0-2.206 1.794-4 4-4zm0-2h-12c-3.314 0-6 2.686-6 6v12c0 3.314 2.686 6 6 6h12c3.314 0 6-2.686 6-6v-12c0-3.314-2.686-6-6-6z"/></g><g id="_x32_"><path d="m12 18c-.552 0-1-.447-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10c0 .553-.448 1-1 1z"/></g><g id="_x31_"><path d="m6 12c0-.552.447-1 1-1h10c.552 0 1 .448 1 1s-.448 1-1 1h-10c-.553 0-1-.448-1-1z"/></g></svg>
												<span>dodaj kolejne</span>
											</button>
										</div>
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-4" *ngIf="userData?.user?.isTrainer">
											<label class="panelprofile-form-header-content-info-field-label">Kwalifikacje</label>
											<ul class="panelprofile-form-header-content-info-field-list">
												<li class="panelprofile-form-header-content-info-field-list-item" *ngFor="let qualification of userData.user?.qualifications">
													<span>{{qualification.name}}</span><button class="panelprofile-form-header-content-info-field-list-item-button" type="button" (click)="deleteQualification(qualification.id)"><svg height="365.696pt" viewBox="0 0 365.696 365.696" width="365.696pt" xmlns="http://www.w3.org/2000/svg"><path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0"/></svg></button>
												</li>
											</ul>
											<input type="file" (change)="onDocumentChanged($event)" style="display: none;" #documentInput>
											<button class="panelprofile-form-header-content-info-field-button" type="button" (click)="documentInput.click()">
												<svg id="Capa_1" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><g id="_x33_"><path d="m18 2c2.206 0 4 1.794 4 4v12c0 2.206-1.794 4-4 4h-12c-2.206 0-4-1.794-4-4v-12c0-2.206 1.794-4 4-4zm0-2h-12c-3.314 0-6 2.686-6 6v12c0 3.314 2.686 6 6 6h12c3.314 0 6-2.686 6-6v-12c0-3.314-2.686-6-6-6z"/></g><g id="_x32_"><path d="m12 18c-.552 0-1-.447-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10c0 .553-.448 1-1 1z"/></g><g id="_x31_"><path d="m6 12c0-.552.447-1 1-1h10c.552 0 1 .448 1 1s-.448 1-1 1h-10c-.553 0-1-.448-1-1z"/></g></svg>
												<span>dodaj kolejne</span>
											</button>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="panelprofile-form-header-content-info-box col-sm-12">
									<div class="panelprofile-form-header-content-info row">
										<div class="panelprofile-form-header-content-info-field col-sm-12 description">
											<label class="panelprofile-form-header-content-info-field-label">Dodaj swój opis <sup style="color: red;">*wymagane</sup></label>
											<textarea [value]="userData.user?.short_description" formControlName="short_description" class="panelprofile-form-header-content-info-field-textarea"></textarea>
										</div>
									</div>
								</div>
							</div>
							<button class="panelprofile-form-header-content-info-submit" type="submit">
								<span>Zapisz zmiany</span>
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
		<div class="col-sm-12 px-0">
			<app-page-footer></app-page-footer>
		</div>
	</div>
</div>
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Alert } from 'src/app/models/alert.model';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  public registerForm: FormGroup;
  public registerUser: UsersData.RegisterUser = {};
  alerts: Alert[] = [];
  states: UsersData.State[] = [];
  cities: UsersData.City[] = [];
  isSearching: boolean = false;

  constructor(private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) {
	this.getStates();
    this.registerForm = new FormGroup({
      'login': new FormControl('', [
        Validators.required,
		Validators.email
      ]),
      'password': new FormControl('', [
        Validators.required
      ]),
      'repeat_password': new FormControl('', [
        Validators.required
      ]),
      'isTrainer': new FormControl('', [

      ]),
      'name': new FormControl('', [
        Validators.required
      ]),
      'nip': new FormControl('', [
      ]),
      'state': new FormControl('', [
        Validators.required
      ]),
      'city': new FormControl('', [
        Validators.required
      ]),
      'isOnline': new FormControl('', [
      ]),
      'phone': new FormControl('', [
        Validators.required
      ])
    });
  }

  ngOnInit(): void {
	window.scroll(0,0);
  }

  onSubmit(event: any): void {
  	this.alerts = [];
	if (this.registerForm.invalid) {
		this.alerts.push({message: 'Prosimy wypełnić wszystkie wymagane pola', type: 'danger'});
		for (var key in this.registerForm.errors) {
			this.alerts.push({message: key, type: 'danger'});
		}
	  return;
	}
	
    var _self = this;
    this._loginService.validateCity(this.registerForm.value.city).subscribe(
      (isValid: boolean) => {
		if(isValid) {
			_self.onRegister();
		}
		else {
			this.alerts.push({message: 'Nazwa miasta jest nieprawidłowa', type: 'danger'});
		}
      },
      (error: HttpErrorResponse) => {
		this.alerts.push({message: 'Wystąpił błąd podczas tworzenia konta', type: 'danger'});
      },
    );
  }

  getErrorList(errorObject: any) {
	return Object.keys(errorObject);
  }

  getStates(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken')
	this._loginService.getStates().subscribe(
	  (states: UsersData.State[]) => {
		_self.states = states;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  getCities(searchValue: string): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken')
	this._loginService.getCities(searchValue, this.registerForm.value.state).subscribe(
	  (cities: UsersData.City[]) => {
		_self.cities = cities;
		_self.isSearching = false;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }
  
  onRegister() {
    var _self = this;
	this.registerUser = Object.assign(this.registerUser, this.registerForm.value);
    this._loginService.register(this.registerUser).subscribe(
      (auth: UsersData.MessageFromAPI) => {
		if(auth?.result === 'success') {
			this.router.navigate(['/logowanie'], { queryParams: { alert_type: 'success', alert: 'Konto zostało utworzone. Prosimy potwierdzić rejestrację poprzez kliknięcie w link otrzymany na podany przy rejestracji adres e-mail.'}});
		}
		else if(auth?.message)
			this.alerts.push({message: auth?.message, type: 'danger'});
      },
      (error: HttpErrorResponse) => {
		this.alerts.push({message: 'Wystąpił błąd podczas tworzenia konta', type: 'danger'});
      },
    );
  }
  
  onSearchChange(searchValue: string): void {
	  if(!this.isSearching) {
		this.isSearching = true;
	  	this.getCities(searchValue);
	  }
  }
}

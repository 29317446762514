<div class="container">
	<div class="row">
		<div class="col-sm-12">
			<app-page-header></app-page-header>
		</div>
		<div class="col-sm-12">
			<div class="row content">
				<div class="col-sm-12 col-xl-5 not-found-column-left">
					<img class="not-found-column-left-img-responsive" src="/assets/img/not_found.png" alt="image">
				</div>
				<div class="col-sm-12 col-xl-7 not-found-column-right">
					<img class="not-found-column-right-img-responsive" src="/assets/img/404.png" alt="image">
					<div class="not-found-column-right-content">
						<p class="not-found-column-right-content-text">Ups! Niestety, ale coś poszło nie tak!<br/>Nie odnaleziono strony, błąd 404</p>
						<a [routerLink]="['/']" class="not-found-column-right-content-button">
							Wróć do strony głównej
							<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
								 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
								<g>
									<g>
										<path d="M361.5,140.148H50.689l43.529-43.71c5.846-5.87,5.826-15.368-0.044-21.214c-5.871-5.847-15.368-5.826-21.213,0.044
											L4.372,144.143c-2.969,2.982-4.417,6.899-4.361,10.8C0.009,155.012,0,155.079,0,155.148c0,5.201,2.649,9.781,6.669,12.472
											l66.292,66.568c5.847,5.87,15.343,5.889,21.213,0.044c5.87-5.846,5.89-15.344,0.044-21.214l-42.692-42.87H361.5
											c66.444,0,120.5,54.056,120.5,120.5s-54.056,120.5-120.5,120.5H15c-8.284,0-15,6.716-15,15s6.716,15,15,15h346.5
											c82.986,0,150.5-67.514,150.5-150.5S444.486,140.148,361.5,140.148z"/>
									</g>
								</g>
							</svg>
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="col-sm-12">
			<app-page-footer></app-page-footer>
		</div>
	</div>
</div>

import { Component, OnInit } from '@angular/core';
import { Options } from "@angular-slider/ngx-slider";
import {MatDialog} from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';
import { Alert } from 'src/app/models/alert.model';
import { GymdialogComponent } from '../components/gymdialog/gymdialog.component';
import { QualificationdialogComponent } from '../components/qualificationdialog/qualificationdialog.component';

@Component({
  selector: 'app-paneladminprofile',
  templateUrl: './paneladminprofile.component.html',
  styleUrls: ['./paneladminprofile.component.scss']
})
export class PaneladminprofileComponent implements OnInit {

  userData: UsersData.User = {};
  clientid: number = 0;
  specializations: UsersData.Specialization[] = [];
  public profileForm: FormGroup;
  alerts: Alert[] = [];
  preferredAgeMin: number = 20;
  preferredAgeMax: number = 40;
  states: UsersData.State[] = [];
  cities: UsersData.City[] = [];
  isSearching: boolean = false;
  options: Options = {
	disabled: true,
    floor: 0,
    ceil: 100,
    showTicks: true
  };

  constructor(public dialog: MatDialog, private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) {
	if(!this.cookieService.get('sessionToken') || (!this.cookieService.get('sessionFA') || this.cookieService.get('sessionFA') !== '1'))
		this.router.navigate(['/logowanie']);
	var _self = this;
    this.activatedRoute.params.subscribe(params => {
      _self.clientid = params['clientid'];
    });
	this.getStates();
	this.profileForm = new FormGroup({
      'firstname': new FormControl(this.userData.user?.firstname, [
        Validators.required
      ]),
      'lastname': new FormControl(this.userData.user?.lastname, [
        Validators.required
      ]),
      'city': new FormControl(this.userData.user?.address?.city, [
      ]),
      'state': new FormControl(this.userData.user?.address?.state, [
      ]),
      'birthday': new FormControl('', [
      ]),
      'gender': new FormControl(this.userData.user?.gender, [
      ]),
      'gender_client': new FormControl(this.userData.user?.gender_client, [
      ]),
      'short_description': new FormControl(this.userData.user?.short_description, [
        Validators.required
      ]),
      'isOnline': new FormControl(this.userData.user?.isOnline, [
      ]),
      'isDietician': new FormControl(this.userData.user?.isDietician, [
      ]),
      'displayAsClient': new FormControl(this.userData.user?.displayAsClient, [
      ]),
      'gym': new FormControl('', [
      ]),
      'place': new FormControl('', [
      ]),
      'specialization': new FormControl('', [
      ]),
      'phone': new FormControl('', [
      ]),
      'email': new FormControl('', [
      ])
    });
	if(!this.cookieService.get('sessionToken'))
        this.router.navigate(['/logowanie']);
  }

  ngOnInit(): void {
  }

  onSubmit(event: any): void {
  	
  }

  onSaveProfile(): void {
    
  }
  
  onSearchChange(searchValue: string): void {
	  if(!this.isSearching) {
		this.isSearching = true;
	  	this.getCities(searchValue);
	  }
  }

  onFileChanged(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
		var _self = this;
		var token = this.cookieService.get('sessionToken');
	    this._loginService.saveImages(reader.result+'', token).subscribe(
	      (message: any) => {
	    	  window.location.reload();
	      },
	      (error: HttpErrorResponse) => {
			
	      },
	    );
    };
  }

  deleteImage(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.deleteImage(token).subscribe(
	  (message: any) => {
		this.getUserData();
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  getAllSpecializations(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getAllSpecializations(token).subscribe(
	  (specializations: UsersData.Specialization[]) => {
		_self.specializations = specializations;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  getUserData(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getClientData(this.clientid, token).subscribe(
	  (userData: UsersData.UserData) => {
		_self.userData.user = userData;
		_self.preferredAgeMin = userData?.preferredAgeMin || 20;
		_self.preferredAgeMax = userData?.preferredAgeMax || 40;
		_self.getAllSpecializations();
	    _self.profileForm.controls['city'].setValue(_self.userData?.user?.address?.city);
	    _self.profileForm.controls['state'].setValue(_self.userData?.user?.address?.state);
	    _self.profileForm.controls['firstname'].setValue(_self.userData?.user?.firstname);
	    _self.profileForm.controls['lastname'].setValue(_self.userData?.user?.lastname);
	    _self.profileForm.controls['short_description'].setValue(_self.userData?.user?.short_description);
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  addGym(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	var attr = {
		attribute: {
			id: 1
		}
	};
  	const dialogRef = this.dialog.open(GymdialogComponent, {
      data: attr
    });
  	dialogRef.afterClosed().subscribe(result => {
		this.getUserData();
    });
  }

  addPlace(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	if(this.profileForm.value.place.length >= 4) {
		var attr = {
			attribute: {
				id: 2
			},
			value: this.profileForm.value.place
		};
	    this._loginService.addAttributeValue(attr, this.cookieService.get( 'sessionToken')).subscribe(
	      (messageFromAPI: UsersData.MessageFromAPI) => {
	    	  this.getUserData();
	      },
	      (error: HttpErrorResponse) => {
	
	      },
	    );
	}
	else {
		this.alerts = [];
		this.alerts.push({message: 'Nazwa musi zawierać minimum 4 znaki', type: 'danger'});
		window.scroll(0,0);
	}
  }

  addSpecialization() {
	if(this.profileForm.value.specialization)
	{
		var _self = this;
		var token = this.cookieService.get('sessionToken');
		var specialization = {
			id: this.profileForm.value.specialization
		};
		this._loginService.addSpecialization(specialization, token).subscribe(
		  (message: any) => {
			if(message?.result === 'success') {
				this.getUserData();
			}
			else {
				this.alerts = [];
				this.alerts.push({message: (message?.message ? message?.message : ''), type: 'danger'});
				window.scroll(0,0);
			}
		  },
		  (error: HttpErrorResponse) => {
			
		  },
		);
	}
  }

  getGyms(): any[] {
	var gyms = [];
	if(this.userData.user?.attributes) {
		for(var i = 0; i < this.userData.user?.attributes.length; i++) {
			if(this.userData.user?.attributes[i].attribute?.id == 1) {
				gyms.push({id: this.userData.user?.attributes[i].id, value: this.userData.user?.attributes[i].value})
			}
		}
	}
	return gyms;
  }

  getPlaces(): any[] {
	var places = [];
	if(this.userData.user?.attributes) {
		for(var i = 0; i < this.userData.user?.attributes.length; i++) {
			if(this.userData.user?.attributes[i].attribute?.id == 2) {
				places.push({id: this.userData.user?.attributes[i].id, value: this.userData.user?.attributes[i].value})
			}
		}
	}
	return places;
  }

  addAttr(attr_id: number, attr_value: string): void {
	if(attr_value)
	{
		var _self = this;
		var token = this.cookieService.get('sessionToken');
		var attr = {
			value: attr_value,
			attribute: {
				id: attr_id
			}
		};
		this._loginService.addAttributeValue(attr, token).subscribe(
		  (message: any) => {
			this.getUserData();
		  },
		  (error: HttpErrorResponse) => {
			
		  },
		);
	}
  }

  deleteAttr(attr_id: number): void {
	if(attr_id)
	{
		var _self = this;
		var token = this.cookieService.get('sessionToken');
		this._loginService.deleteAttributeValue(attr_id, token).subscribe(
		  (message: any) => {
			this.getUserData();
		  },
		  (error: HttpErrorResponse) => {
			
		  },
		);
	}
  }

  deleteSpecialization(specialization_id: number) {
	if(specialization_id)
	{
		var _self = this;
		var token = this.cookieService.get('sessionToken');
		this._loginService.deleteUserSpecialization(specialization_id, token).subscribe(
		  (message: any) => {
			this.getUserData();
		  },
		  (error: HttpErrorResponse) => {
			
		  },
		);
	}
  }

  deleteQualification(qualification_id: number): void {
	if(qualification_id)
	{
		var _self = this;
		var token = this.cookieService.get('sessionToken');
		this._loginService.deleteQualification(qualification_id, token).subscribe(
		  (message: any) => {
			this.getUserData();
		  },
		  (error: HttpErrorResponse) => {
			
		  },
		);
	}
  }

  onDocumentChanged(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
    	var _self = this;
    	var token = this.cookieService.get('sessionToken');
      	const dialogRef = this.dialog.open(QualificationdialogComponent, {
          data: {}
        });
      	dialogRef.afterClosed().subscribe(result => {
      		if(result) {
	      		_self._loginService.addQualification(reader.result+'', result.name, token).subscribe(
		  	      (message: any) => {
		  	    	_self.getUserData();
		  	      },
		  	      (error: HttpErrorResponse) => {
		  			
		  	      },
		  	    );
      		}
        });
    };
  }

  getStates(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken')
	this._loginService.getStates().subscribe(
	  (states: UsersData.State[]) => {
		_self.states = states;
		_self.getUserData();
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  getCities(searchValue: string): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken')
	this._loginService.getCities(searchValue, this.profileForm.value.state).subscribe(
	  (cities: UsersData.City[]) => {
		_self.cities = cities;
		_self.isSearching = false;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }
}

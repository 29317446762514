<div class="copyseriedialog">
	<div class="row">
		<div class="col-sm-12">
			<div class="copyseriedialog-content">
				<div class="copyseriedialog-form">
					<h1 class="copyseriedialog-form-title">
						Skopiuj cykl do cyklu:
					</h1>
					<div class="copyseriedialog-menu col-sm-12">
						<ul class="copyseriedialog-menu-list">
							<li *ngFor="let item of tabs">
								<div class="copyseriedialog-menu-list-item" *ngIf="serieNumber != item.number">
									<button class="copyseriedialog-menu-list-item-button" (click)="copySerie(item.number)">Cykl {{item.number}}</button>
									<div class="copyseriedialog-menu-list-item-background"></div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
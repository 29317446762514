<div class="container">
	<div class="row">
		<div class="col-sm-12">
			<app-page-header></app-page-header>
		</div>
		<div class="col-sm-12 px-0">
			<app-panelheader [id_tab]="1" [id_client]="clientid" [isSerieTime]="isSerieTime"></app-panelheader>
			<form class="paneltrainercard-column row" [formGroup]="sizesForm" (ngSubmit)="onSubmit($event)">
				<app-alert class="paneltrainercard-alerts" [alerts]="alerts"></app-alert>
				<div class="paneltrainercard-column-header col-sm-12">
					<div class="paneltrainercard-column-header-list-item">
						<div class="paneltrainercard-column-header-list-item-button">Cykl {{serie?.number}} ({{serieStart}} -  {{serieEnd}})</div>
						<div class="paneltrainercard-column-header-list-item-background"></div>
					</div>
				</div>
				<div class="paneltrainercard-column-left col-sm-12 col-xl-3">
					<label class="paneltrainercard-column-center-label">Początkowa data treningu</label>
					<div class="paneltrainercard-column-center-input-box">
						<input formControlName="trainingstart" type="date" value="{{client?.trainingstart}}">
					</div>
					<label class="paneltrainercard-column-center-label">Długość cyklu</label>
					<select formControlName="serietime" class="paneltrainercard-column-center-select-box">
						<option [value]="7">tydzień</option>
						<option [value]="14">2 tygodnie</option>
						<option [value]="28">miesiąc</option>
					</select>
					<ul class="paneltrainercard-column-left-list">
						<li class="paneltrainercard-column-left-list-item" *ngFor="let item of this.serie?.serietime_list | slice:0:5" [ngClass]="{'active': serie?.number == item?.number}">
							<button type="button" class="paneltrainercard-column-left-list-item-button" (click)="changeSerie(item?.number)">Cykl {{item?.number}}</button>
							<div class="paneltrainercard-column-left-list-item-background"></div>
						</li>
					</ul>
					<ul class="paneltrainercard-column-left-tabs row">
						<li class="paneltrainercard-column-left-tabs-tab col-sm-4" *ngFor="let item of this.serie?.serietime_list | slice:0:5" [ngClass]="{'active': serie?.number === item?.number}"><a (click)="changeSerie(item?.number)">Cykl {{item?.number}}</a></li>
					</ul>
					<div class="row paneltrainercard-column-left-tabs-content-serie-images py-0 px-2">
						<div *ngFor="let image of serie?.images;" class="col-sm-6 px-0" [ngClass]="{'d-none': image.isDeleted}">
							<div *ngIf="!image.isDeleted" class="paneltrainercard-column-left-tabs-content-serie-images-image px-2">
								<a href="https://api.fitbook.pro/upload/{{image?.img}}" target="_blank"><img class="paneltrainercard-column-left-tabs-content-serie-images-image-img" src="https://api.fitbook.pro/upload/{{image?.img}}" /></a>
							</div>
						</div>
					</div>
					<div class="row paneltrainercard-column-left-tabs-content-serie-images pt-0 px-2">
						<div *ngFor="let image of serie?.images;" class="col-sm-3 px-0" [ngClass]="{'d-none': !image.isDeleted}">
							<div *ngIf="image.isDeleted" class="paneltrainercard-column-left-tabs-content-serie-images-image px-2">
								<a href="https://api.fitbook.pro/upload/{{image?.img}}" target="_blank"><img class="paneltrainercard-column-left-tabs-content-serie-images-image-img" src="https://api.fitbook.pro/upload/{{image?.img}}" /></a>
							</div>
						</div>
					</div>
				</div>
				<div class="paneltrainercard-column-center col-sm-12 col-xl-6">
					<div class="row">
						<div class="paneltrainercard-column-center-first col-sm-12 col-xl-3">
							<h3>Wymiary</h3>
							<label class="paneltrainercard-column-center-label">Obwód karku</label>
							<div class="paneltrainercard-column-center-input-box">
								<input formControlName="neck" type="text" [value]="serie?.dimensions?.neck" [readonly]="disabled">
							</div>
							<label class="paneltrainercard-column-center-label">Obwód klatki piersiowej</label>
							<div class="paneltrainercard-column-center-input-box">
								<input formControlName="thorax" type="text" [value]="serie?.dimensions?.thorax" [readonly]="disabled">
							</div>
							<label class="paneltrainercard-column-center-label">Obwód talii</label>
							<div class="paneltrainercard-column-center-input-box">
								<input formControlName="waist" type="text" [value]="serie?.dimensions?.waist" [readonly]="disabled">
							</div>
							<label class="paneltrainercard-column-center-label">Obwód brzucha</label>
							<div class="paneltrainercard-column-center-input-box">
								<input formControlName="stomach" type="text" [value]="serie?.dimensions?.stomach" [readonly]="disabled">
							</div>
							<label class="paneltrainercard-column-center-label">Obwód bioder</label>
							<div class="paneltrainercard-column-center-input-box">
								<input formControlName="hips" type="text" [value]="serie?.dimensions?.hips" [readonly]="disabled">
							</div>
							<label class="paneltrainercard-column-center-label">Obwód uda</label>
							<div class="paneltrainercard-column-center-input-box">
								<input formControlName="thighs" type="text" [value]="serie?.dimensions?.thighs" [readonly]="disabled">
							</div>
							<label class="paneltrainercard-column-center-label">Obwód łydki</label>
							<div class="paneltrainercard-column-center-input-box">
								<input formControlName="calves" type="text" [value]="serie?.dimensions?.calves" [readonly]="disabled">
							</div>
							<label class="paneltrainercard-column-center-label">Obwód ramienia</label>
							<div class="paneltrainercard-column-center-input-box">
								<input formControlName="arm" type="text" [value]="serie?.dimensions?.arm" [readonly]="disabled">
							</div>
							<label class="paneltrainercard-column-center-label">Obwód nadgarstka</label>
							<div class="paneltrainercard-column-center-input-box">
								<input formControlName="wrist" type="text" [value]="serie?.dimensions?.wrist" [readonly]="disabled">
							</div>
						</div>
						<div class="paneltrainercard-column-center-second col-sm-12 col-xl-6">
							<img *ngIf="!client?.figure?.img" src="/assets/img/figure_male_1.svg" />
							<img *ngIf="client?.figure?.img" src="{{client?.figure?.img}}"/>
							<span class="paneltrainercard-column-center-second-edit" (click)="changeFigure()">
								<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
									 viewBox="0 0 330 330" style="enable-background:new 0 0 330 330;" xml:space="preserve">
									<g id="XMLID_23_">
										<path id="XMLID_24_" d="M75,180v60c0,8.284,6.716,15,15,15h60c3.978,0,7.793-1.581,10.606-4.394l164.999-165
											c5.858-5.858,5.858-15.355,0-21.213l-60-60C262.794,1.581,258.978,0,255,0s-7.794,1.581-10.606,4.394l-165,165
											C76.58,172.206,75,176.022,75,180z M105,186.213L255,36.213L293.787,75l-150,150H105V186.213z"/>
										<path id="XMLID_27_" d="M315,150.001c-8.284,0-15,6.716-15,15V300H30V30H165c8.284,0,15-6.716,15-15s-6.716-15-15-15H15
											C6.716,0,0,6.716,0,15v300c0,8.284,6.716,15,15,15h300c8.284,0,15-6.716,15-15V165.001C330,156.716,323.284,150.001,315,150.001z"
											/>
									</g>
								</svg>
							</span>
						</div>
						<div class="paneltrainercard-column-center-third col-sm-12 col-xl-3">
							<label class="paneltrainercard-column-center-label">Wzrost</label>
							<div class="paneltrainercard-column-center-input-box">
								<input formControlName="height" type="text" [value]="serie?.dimensions?.height" [readonly]="disabled">
							</div>
							<label class="paneltrainercard-column-center-label">Waga</label>
							<div class="paneltrainercard-column-center-input-box">
								<input formControlName="weight" type="text" [value]="serie?.dimensions?.weight" [readonly]="disabled">
							</div>
							<label class="paneltrainercard-column-center-label">Wiek</label>
							<div class="paneltrainercard-column-center-input-box">
								<input formControlName="age" type="text" [value]="serie?.dimensions?.age" [readonly]="disabled">
							</div>
							<label class="paneltrainercard-column-center-label">Płeć</label>
							<select formControlName="gender" class="paneltrainercard-column-center-select-box" [attr.disabled]="disabled">
								<option [value]="'male'" [selected]="serie?.dimensions?.gender === 'male'">Mężczyzna</option>
								<option [value]="'female'" [selected]="serie?.dimensions?.gender === 'female'">Kobieta</option>
							</select>
							<div class="paneltrainercard-column-center-attrs">
								<div class="paneltrainercard-column-center-attrs-icon" matTooltip="k - kcal&#13;b - białko&#13;w - węglowodany&#13;t - tłuszcze" [matTooltipClass]="'my-tooltip'">
									<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
									 	viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001 512.001;" xml:space="preserve">
										<g>
											<g>
												<g>
													<path d="M252.692,139.985c-47.057,0-68.668,27.89-68.668,46.715c0,13.597,11.502,19.872,20.914,19.872
														c18.822,0,11.154-26.844,46.708-26.844c17.429,0,31.372,7.671,31.372,23.707c0,18.826-19.52,29.633-31.023,39.394
														c-10.108,8.716-23.354,23.01-23.354,52.991c0,18.128,4.879,23.357,19.171,23.357c17.079,0,20.565-7.669,20.565-14.293
														c0-18.129,0.349-28.588,19.52-43.578c9.412-7.321,39.04-31.028,39.04-63.798S297.308,139.986,252.692,139.985z"/>
													<path d="M248.16,343.232c-14.639,0-26.491,12.202-26.491,26.844c0,14.293,11.503,26.844,26.491,26.844
														c14.988,0,26.84-12.55,26.84-26.844C275,355.434,262.799,343.232,248.16,343.232z"/>
													<path d="M447.717,97.795L297.208,10.994c-25.417-14.659-56.997-14.659-82.414,0L64.285,97.795
														c-25.465,14.686-41.284,42.072-41.284,71.472v173.467c0,29.399,15.819,56.786,41.284,71.472l150.509,86.801
														c25.412,14.657,56.993,14.661,82.414,0l150.509-86.801c25.465-14.687,41.284-42.073,41.284-71.473V169.267
														C489.001,139.867,473.182,112.481,447.717,97.795z M449.001,342.733c0,15.144-8.148,29.25-21.266,36.815l-150.509,86.801
														c-13.093,7.55-29.358,7.55-42.451,0l-150.508-86.8c-13.118-7.565-21.266-21.672-21.266-36.816V169.267
														c0-15.144,8.148-29.25,21.266-36.815l150.509-86.801c13.095-7.551,29.358-7.55,42.451,0l150.509,86.801
														c13.117,7.564,21.265,21.671,21.265,36.815V342.733z"/>
												</g>
											</g>
										</g>
									</svg>
								</div>
								<div class="paneltrainercard-column-center-attrs-box">
									<input formControlName="kcal" type="text" [value]="serie?.dimensions?.kcal">
									<input formControlName="protein" type="text" [value]="serie?.dimensions?.protein">
									<input formControlName="carbohydrates" type="text" [value]="serie?.dimensions?.carbohydrates">
									<input formControlName="fat" type="text" [value]="serie?.dimensions?.fat">
								</div>
								<div class="paneltrainercard-column-center-attrs-units">
									<span class="paneltrainercard-column-center-attrs-units-item">k</span>
									<span class="paneltrainercard-column-center-attrs-units-item">b</span>
									<span class="paneltrainercard-column-center-attrs-units-item">w</span>
									<span class="paneltrainercard-column-center-attrs-units-item">t</span>
								</div>
							</div>
							<label class="paneltrainercard-column-center-label">Komentarz dla trenera</label>
							<div class="paneltrainercard-column-center-textarea-box">
								<textarea formControlName="comment" [value]="serie?.dimensions?.comment" [readonly]="disabled"></textarea>
							</div>
						</div>
						<button class="paneltrainercard-column-center-button">Zapisz zmiany</button>
					</div>
				</div>
				<div class="paneltrainercard-column-right col-sm-12 col-xl-3">
					<a [routerLink]="['/panel/preferencje/'+clientid]" class="paneltrainercard-column-right-button">Preferencje podopiecznego</a>
					<div class="paneltrainercard-column-right-history">
						<div class="paneltrainercard-column-right-history-title"><b>Cykl {{serie?.number}}</b> - Historia zmian statusu</div>
						<ul class="paneltrainercard-column-right-history-list">
							<li class="paneltrainercard-column-right-history-list-item row" *ngFor="let item of history">
								<div class="paneltrainercard-column-right-history-list-item-text col-sm-8 col-xl-10"><span><b *ngIf="item.history?.isFromTrainer">Trener</b><b *ngIf="!item.history?.isFromTrainer">Podopieczny</b> {{item.history?.name}}</span></div>
								<div class="paneltrainercard-column-right-history-list-item-svg col-sm-4 col-xl-2"><svg id="Layer_1" enable-background="new 0 0 128 128" height="512" viewBox="0 0 128 128" width="512" xmlns="http://www.w3.org/2000/svg"><path id="External_Link" d="m124 8v36c0 2.211-1.789 4-4 4s-4-1.789-4-4v-26.344l-57.172 57.172c-.781.781-1.805 1.172-2.828 1.172s-2.047-.391-2.828-1.172c-1.563-1.563-1.563-4.094 0-5.656l57.172-57.172h-26.344c-2.211 0-4-1.789-4-4s1.789-4 4-4h36c2.211 0 4 1.789 4 4zm-16 104v-48c0-2.211-1.789-4-4-4s-4 1.789-4 4v48c0 2.207-1.793 4-4 4h-80c-2.207 0-4-1.793-4-4v-80c0-2.207 1.793-4 4-4h48c2.211 0 4-1.789 4-4s-1.789-4-4-4h-48c-6.617 0-12 5.383-12 12v80c0 6.617 5.383 12 12 12h80c6.617 0 12-5.383 12-12z"/></svg></div>
							</li>
						</ul>
					</div>
				</div>
			</form>
		</div>
		<div class="col-sm-12 px-0">
			<app-page-footer></app-page-footer>
		</div>
	</div>
</div>
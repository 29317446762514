import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';
import {MatDialog} from '@angular/material/dialog';
import { ConfirmdialogComponent } from 'src/app/client/page/components/confirmdialog/confirmdialog.component';
import { MessagedialogComponent } from '../components/messagedialog/messagedialog.component';
import { Alert } from 'src/app/models/alert.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-paneladmincontent',
  templateUrl: './paneladmincontent.component.html',
  styleUrls: ['./paneladmincontent.component.scss']
})
export class PaneladmincontentComponent implements OnInit {

  content: UsersData.Content = {};
  alerts: Alert[] = [];
  public contentForm: FormGroup;
  contentid: number = 0;

  constructor(private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) {
	if(!this.cookieService.get('sessionToken') || (!this.cookieService.get('sessionFA') || this.cookieService.get('sessionFA') !== '1'))
		this.router.navigate(['/logowanie']);
    this.contentForm = new FormGroup({
      'name': new FormControl('', []),
      'content': new FormControl('', []),
    });
    var _self = this;
    this.activatedRoute.params.subscribe(params => {
      _self.contentid = params['contentid'];
    });
  }

  ngOnInit(): void {
	this.getContent();
  }
  
  onSubmit(event: any): void {
  	this.alerts = [];
	if (this.contentForm.invalid) {
		this.alerts.push({message: 'Prosimy poprawnie wypełnić wszystkie wymagane pola', type: 'danger'});
		for (var key in this.contentForm.errors) {
			this.alerts.push({message: key, type: 'danger'});
		}
	  return;
	}
    var _self = this;
	var token = this.cookieService.get('sessionToken');
    var date = {
		token: token,
		id: this.content.id,
		name: this.contentForm.value.name,
		content: this.contentForm.value.content
    }
    this._loginService.setContent(date).subscribe(
		(messageFromAPI: UsersData.MessageFromAPI) => {
			if(messageFromAPI?.result === 'success') {
				this.alerts.push({message: (messageFromAPI?.message ? messageFromAPI?.message : ''), type: 'success'});
			}
			else
				this.alerts.push({message: (messageFromAPI?.message ? messageFromAPI?.message : ''), type: 'danger'});
	    },
	    (error: HttpErrorResponse) => {
			this.alerts.push({message: 'Wystąpił błąd podczas zapisu danych', type: 'danger'});
		},
    );
  }

  onFileChanged(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
		var _self = this;
		var token = this.cookieService.get('sessionToken');
	    this._loginService.saveContentImage(reader.result+'', this.contentid, token).subscribe(
	      (message: any) => {
	    	  window.location.reload();
	      },
	      (error: HttpErrorResponse) => {
			
	      },
	    );
    };
  }
  
  getContent() {
    var _self = this;
	var token = this.cookieService.get('sessionToken');
    this._loginService.getContent(this.contentid).subscribe(
		(content: UsersData.Content) => {
			_self.content = content;
		    _self.contentForm.controls['name'].setValue(content.name);
		    _self.contentForm.controls['content'].setValue(content.content);
		},
	    (error: HttpErrorResponse) => {
		},
    );
  }

}

import { Component, OnInit } from '@angular/core';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';
import { PracticedialogComponent } from '../components/practicedialog/practicedialog.component';
import { SupplementdialogComponent } from '../components/supplementdialog/supplementdialog.component';
import {MatDialog} from '@angular/material/dialog';
import { ConfirmdialogComponent } from 'src/app/client/page/components/confirmdialog/confirmdialog.component';
import { CopyseriedialogComponent } from 'src/app/client/page/components/copyseriedialog/copyseriedialog.component';
import { SeriedatedialogComponent } from 'src/app/client/page/components/seriedatedialog/seriedatedialog.component';
import { Alert } from 'src/app/models/alert.model';

@Component({
  selector: 'app-paneltrainertraining',
  templateUrl: './paneltrainertraining.component.html',
  styleUrls: ['./paneltrainertraining.component.scss']
})
export class PaneltrainertrainingComponent implements OnInit {

  clientid: number = 0;
  client: UsersData.UserData = {};
  serie: UsersData.Serie = {};
  serieStart: string = '';
  serieEnd: string = '';
  listOfDate: string[] = [];
  numbers: number[] = [];
  days: string[] = ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'];
  serienumbers: string[] = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X'];
  trainingseries: any[] = [];
  comment: string = '';
  statistics: UsersData.Statistics = {};
  cellWidth: number = 320;
  cellHeight: number = 750;
  alerts: Alert[] = [];
  seriesDates: UsersData.SeriesDatesItem[] = [];
  seriedateItems: UsersData.SeriedateItem[] = [];
  tabs: UsersData.SerieTime[] = [];

  constructor(public dialog: MatDialog, private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) {
	var _self = this;
    this.activatedRoute.params.subscribe(params => {
      _self.clientid = params['clientid'];
    });
    if(window.innerWidth < 768) {
    	this.cellWidth = 320;
    	this.cellHeight = 450;
    }
    if(!this.cookieService.get('sessionToken'))
		this.router.navigate(['/logowanie']);
    this.cellWidth = this.convertRemToPixels(27);
  }

  getTabs(): void {
	this.tabs = [];
	for(var i = 0; i < this.serie?.serietime_list.length; i++) {
		if(this.serie?.serietime_list[i].number !== this.serie?.number && this.tabs.length < 4)
			this.tabs.push(this.serie?.serietime_list[i]);
	}
  }

  getSeriesDatesItem(number: number): string {
	for(var i = 0; i < this.seriesDates.length; i++) {
		if(this.seriesDates[i].number == number)
			return this.seriesDates[i].date;
	}
	return '';
  }

  convertRemToPixels(rem: number): number {    
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }

  addSerieDate(): void {
	var _self = this;
    var seriedate = {
		serie: this.serie,
		seriedateitems: this.seriedateItems
	};
  	const dialogRef = this.dialog.open(SeriedatedialogComponent, {
      data: seriedate
    });
  	dialogRef.afterClosed().subscribe(result => {
		_self.getDimensions(_self.serie.number);
    });
  }

  ngOnInit(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getClientData(this.clientid, token).subscribe(
	  (client: UsersData.UserData) => {
		_self.client = client;
		_self.getSeriesDates();
		_self.getDimensions(_self.client?.defaultSerie ? _self.client?.defaultSerie : 1);
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  getSeriesDates(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken')
	this._loginService.getSeriesDates(token, this.clientid).subscribe(
	  (seriesDates: UsersData.SeriesDatesItem[]) => {
		_self.seriesDates = seriesDates;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  changeSerie(serie: number): void {
	this.getDimensions(serie);
  }

  getRepeats(seriedate: number): number {
	var practices = this.getPracticesForSerieDate(seriedate);
	var repeats = 0;
	for(var i = 0; i < practices.length; i++) {
		if(practices[i] && practices[i]?.trainingseries) {
			var trainingseries = practices[i].trainingseries || [];
			for(var j = 0; j < trainingseries.length; j++) {
				repeats += trainingseries[j].repeats || 0;
			}
		}
	}
	return repeats;
  }

  getWeight(seriedate: number): number {
	var practices = this.getPracticesForSerieDate(seriedate);
	var weight = 0;
	for(var i = 0; i < practices.length; i++) {
		if(practices[i] && practices[i]?.trainingseries) {
			var trainingseries = practices[i].trainingseries || [];
			for(var j = 0; j < trainingseries.length; j++) {
				weight += trainingseries[j].weight || 0;
			}
		}
	}
	return weight;
  }

  getStatistics(serie: number): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken')
	this._loginService.getStatistics(token, this.clientid, serie).subscribe(
	  (statistics: UsersData.Statistics) => {
		_self.statistics = statistics;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  deleteSeriedate(seriedateid: number) {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
    var confirm_item = {
		title: "Czy na pewno chcesz usunąć tą datę?"
	};
  	const dialogRef = this.dialog.open(ConfirmdialogComponent, {
      data: confirm_item
    });
  	dialogRef.afterClosed().subscribe(result => {
		if(result) {
			this._loginService.deleteSeriedate(seriedateid, this.cookieService.get( 'sessionToken')).subscribe(
		      (messageFromAPI: UsersData.MessageFromAPI) => {
				_self.getDimensions(_self.serie.number);
		      },
		      (error: HttpErrorResponse) => {
		
		      },
		    );
		}
    });
  }

  getDimensions(serie_id: number): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this.alerts = [];
	this._loginService.getDimensions(serie_id, this.clientid, token).subscribe(
	  (serie: UsersData.Serie) => {
		_self.serie = serie;
		_self.numbers = Array(_self.serie.serietime).fill(_self.serie.serietime).map((x,i)=>i);
		_self.listOfDate = [];
		_self.seriedateItems = [];
		if(_self.client?.trainingstart && serie?.number && _self.serie?.serietime)
			for(var i = 0; i < _self.numbers.length; i++) {
				var date = new Date(_self.client?.trainingstart);
				date.setDate(date.getDate() + (i+((serie.number-1)*_self.serie.serietime)));
				var dayName = _self.days[date.getDay()];
				_self.listOfDate.push(date.getDate()+'.'+(date.getMonth()+1)+' ('+dayName+')');
				if(i == 0)
					_self.serieStart = date.getDate()+'.'+(date.getMonth()+1);
				if(i == _self.numbers.length-1)
					_self.serieEnd = date.getDate()+'.'+(date.getMonth()+1);
				_self.comment = serie.comment || '';
				var serieDate = _self.getSerieDate(i);
				if(serieDate !== null) {
					serieDate.date = date.getDate()+'.'+(date.getMonth()+1)+' ('+dayName+')';
				}
				if(!_self.issetSerieDate(i)) {
					_self.seriedateItems.push({
						numberOfDay: i,
						date: date.getDate()+'.'+(date.getMonth()+1)+' ('+dayName+')'
					});
				}
			}
		this.getStatistics(serie.id);
		_self.getTabs();
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  issetSerieDate(numberOfDay: number): boolean {
	for(var j = 0; j < this.serie?.seriedates?.length; j++) {
		if(this.serie?.seriedates[j].numberOfDay == numberOfDay)
			return true;
	}
	return false;
  }

  getSerieDate(seriedate: number): UsersData.Seriedate {
	if(this.serie?.seriedates)
		for(var i = 0; i < this.serie.seriedates.length; i++) {
			if(this.serie.seriedates[i].numberOfDay == seriedate) {
				return this.serie.seriedates[i];
			}
		}
	return null;
  }

  getPracticesForSerieDate(seriedate: number): UsersData.Practice[] {
	var practices: UsersData.Practice[] = [];
	if(this.serie?.seriedates)
		for(var i = 0; i < this.serie.seriedates.length; i++) {
			if(this.serie.seriedates[i].numberOfDay == seriedate) {
				return this.serie.seriedates[i].practices;
			}
		}
	return practices;
  }

  addSupplement() {
	var _self = this;
    var supplement = {
		serieid: this.serie.id
	};
  	const dialogRef = this.dialog.open(SupplementdialogComponent, {
      data: supplement
    });
  	dialogRef.afterClosed().subscribe(result => {
		if(this.serie?.number) 
			_self.saveChanges()
			//_self.getDimensions(this.serie.number);
    });
  }

  addPractice(date: number, index: number) {
	var _self = this;
    var practice = {
	  	seriedate: date,
	  	position: this.serie.seriedates[index].practices ? this.serie.seriedates[index].practices.length : 0,
		serienumber: this.serie.number,
		clientid: this.clientid,
	};
  	const dialogRef = this.dialog.open(PracticedialogComponent, {
      data: practice
    });
  	dialogRef.afterClosed().subscribe(result => {
		if(this.serie?.number)
			_self.saveChanges()
			//_self.getDimensions(this.serie.number);
    });
  }

  addTrainingserie(practice: UsersData.Practice) {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	if(practice?.id)
		this._loginService.addTrainingserie(token, practice.id, (practice.trainingseries ? practice.trainingseries.length : 0)).subscribe(
	      (messageFromAPI: UsersData.MessageFromAPI) => {
			if(this.serie?.number)
				_self.saveChanges()
				//_self.getDimensions(this.serie.number);
	      },
	      (error: HttpErrorResponse) => {
	
	      },
	    );
  }

  itemChange(id: number, value: string, type: number) {
	var isSave = false;
	for(var i = 0; i < this.trainingseries.length; i++) {
		if(this.trainingseries[i].id == id) {
			if(type == 1)
				this.trainingseries[i].repeats = value;
			else if(type == 2)
				this.trainingseries[i].weight = value;
			isSave = true;
			break;
		}
	}
	if(!isSave) {
		if(type == 1)
			this.trainingseries.push({id: id, repeats: parseInt(value), weight: 0})
		else if(type == 2)
			this.trainingseries.push({id: id, repeats: 0, weight: parseInt(value)})
	}
  }

  copySerie() {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
  	const dialogRef = this.dialog.open(CopyseriedialogComponent, {
      data: {serieid: _self.serie?.id, serieNumber: _self.serie?.number, tabs: _self.tabs}
    });
  	dialogRef.afterClosed().subscribe(result => {
		if(result) {
		  	this.alerts = [];
			this.alerts.push({message: 'Cykl skopiowany poprawnie', type: 'success'});
		}
    });
  }

  clearSerie() {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
    var confirm_item = {
		title: "Czy na pewno chcesz wyczyścić cykl ?"
	};
  	const dialogRef = this.dialog.open(ConfirmdialogComponent, {
      data: confirm_item
    });
  	dialogRef.afterClosed().subscribe(result => {
		if(result) {
			this._loginService.clearSerie(token, _self.serie?.id || 0).subscribe(
			  (messageFromAPI: UsersData.MessageFromAPI) => {
				if(this.serie?.number)
					_self.getDimensions(this.serie.number);
			  },
			  (error: HttpErrorResponse) => {
				
			  },
			);
		}
    });
  }
  
  deletePractice(practice_index: number, practice_id: number, seriedate: number) {
    var _self = this;
	this._loginService.deletePractice(practice_id, this.cookieService.get( 'sessionToken')).subscribe(
      (messageFromAPI: UsersData.MessageFromAPI) => {
	    var index = 0;
	    var practices: UsersData.Practice[] = [];
		if(_self.serie?.seriedates[seriedate].practices) {
			for(var j = 0; j < _self.serie?.seriedates[seriedate].practices.length; j++) {
			  if((_self.serie?.seriedates[seriedate].practices[j]?.id || 0) !== practice_id)
				  practices.push(_self.serie?.seriedates[seriedate].practices[j]);
			}
			if(typeof _self.serie !== 'undefined')
				_self.serie!.seriedates[seriedate].practices = practices;
		}
      },
      (error: HttpErrorResponse) => {

      },
    );
  }
  
  /*deleteSerie(practice_index: number, practice_id: number, seriedate: number) {
    var index = 0;
	if(this.serie?.practices)
		for(var j = 0; j < this.serie?.practices.length; j++) {
			if(this.serie?.practices[j].seriedate == seriedate) {
				if(index == meal_index) {
				  var mealingredients: UsersData.MealIngredient[] = [];
				  if(typeof this.serie?.practices[j].ingredients !== 'undefined')
					  for(var i = 0; i < (this.serie?.practices[j]?.ingredients?.length || 0); i++) {
						  if((this.serie?.practices[j]?.ingredients[i]?.id || 0) !== ingredient_id)
							  mealingredients.push(this.serie?.practices[j]?.ingredients[i]);
					  }
				  this.serie?.practices[j].ingredients = mealingredients;
				}
				else 
					index++;
			}
		}
  }*/

  saveChanges() {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.updateTrainingseries(token, this.trainingseries, this.comment, (this.serie.id ? this.serie.id : 0)).subscribe(
      (messageFromAPI: UsersData.MessageFromAPI) => {
		if(this.serie?.number)
			_self.getDimensions(this.serie.number);
		_self.alerts = [];
		_self.alerts.push({message: 'Zmiany zostały zapisane', type: 'success'});
		window.scroll(0,0);
      },
      (error: HttpErrorResponse) => {

      },
    );
  }
}

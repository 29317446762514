import { Component, OnInit } from '@angular/core';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';
import {MatDialog} from '@angular/material/dialog';
import { ConfirmdialogComponent } from 'src/app/client/page/components/confirmdialog/confirmdialog.component';
import { MessagedialogComponent } from '../components/messagedialog/messagedialog.component';
import { Alert } from 'src/app/models/alert.model';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-paneladmin',
  templateUrl: './paneladmin.component.html',
  styleUrls: ['./paneladmin.component.scss']
})
export class PaneladminComponent implements OnInit {
	
	  alerts: Alert[] = [];
	  userData: UsersData.User = {};
	  clients: UsersData.UserData[] = [];
	  countAlerts: number = 0;
	  countMessages: number = 0;
	  page: number = 1;
	  pageIndex: number = 0;
	  count: number = 0;
	  limit: number = 20;
	  maxpages: number = 1;
	  displayedColumns: string[] = ['id', 'email', 'firstname', 'lastname', 'isTrainer', 'actions'];

	  constructor(public dialog: MatDialog, private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) {
	  	if(!this.cookieService.get('sessionToken') || (!this.cookieService.get('sessionFA') || this.cookieService.get('sessionFA') !== '1'))
			this.router.navigate(['/logowanie']);
	  }

	  ngOnInit(): void {
		this.getUserData();
		this.getAlertsCount();
	  }

	  getUserData(): void {
		var _self = this;
		var token = this.cookieService.get('sessionToken');
		this._loginService.getUserData(token).subscribe(
		  (userData: UsersData.UserData) => {
			_self.userData.user = userData;
		  },
		  (error: HttpErrorResponse) => {
			
		  },
		);
		this.getAllUsers();
	  }
	  
	  getAllUsers() {
		var _self = this;
		var token = this.cookieService.get('sessionToken');
		this._loginService.getAllUsers(token, this.page, this.limit).subscribe(
		  (data: any) => {
			_self.clients = data.users;
			_self.count = data.count;
			_self.maxpages = data.maxpages;
		  },
		  (error: HttpErrorResponse) => {
			
		  },
		);
	  }

	  goToClientSettings(client_id: number|undefined) {
		this.router.navigate(['panel/trener/karta/'+client_id]);
	  }

	  messageDialogOpen(clientid: number) {
	    var message = {
			from: this.userData?.user?.id || 0,
			email: this.userData?.user?.email,
			name: this.userData?.user?.firstname+' '+this.userData?.user?.lastname,
			to: clientid
		};
	  	const dialogRef = this.dialog.open(MessagedialogComponent, {
	      data: message
	    });
		var _self = this;
	  	dialogRef.afterClosed().subscribe(result => {
	  		if(result) {
				_self.alerts = [];
				_self.alerts.push({message: 'Wiadomość została wysłana', type: 'success'});
				window.scroll(0,0);
	  		}
	    });
	  }

	  deleteUser(id_user: number) {
		var _self = this;
		var token = this.cookieService.get('sessionToken');
	    var confirm_item = {
			title: "Czy na pewno chcesz usunąć użytkownika ?"
		};
	  	const dialogRef = this.dialog.open(ConfirmdialogComponent, {
	      data: confirm_item
	    });
	  	dialogRef.afterClosed().subscribe(result => {
			if(result) {
				this._loginService.deleteUser(token, id_user).subscribe(
				  (messageFromAPI: UsersData.MessageFromAPI) => {
	    			window.location.reload();
				  },
				  (error: HttpErrorResponse) => {
					
				  },
				);
			}
	    });
	  }

	  disableUser(id_user: number) {
		var _self = this;
		var token = this.cookieService.get('sessionToken');
	    var confirm_item = {
			title: "Czy na pewno chcesz zablokować użytkownika ?"
		};
	  	const dialogRef = this.dialog.open(ConfirmdialogComponent, {
	      data: confirm_item
	    });
	  	dialogRef.afterClosed().subscribe(result => {
			if(result) {
				this._loginService.disableUser(token, id_user).subscribe(
				  (messageFromAPI: UsersData.MessageFromAPI) => {
	    			window.location.reload();
				  },
				  (error: HttpErrorResponse) => {
					
				  },
				);
			}
	    });
	  }

	  enableUser(id_user: number) {
		var _self = this;
		var token = this.cookieService.get('sessionToken');
	    var confirm_item = {
			title: "Czy na pewno chcesz odblokować użytkownika ?"
		};
	  	const dialogRef = this.dialog.open(ConfirmdialogComponent, {
	      data: confirm_item
	    });
	  	dialogRef.afterClosed().subscribe(result => {
			if(result) {
				this._loginService.enableUser(token, id_user).subscribe(
				  (messageFromAPI: UsersData.MessageFromAPI) => {
	    			window.location.reload();
				  },
				  (error: HttpErrorResponse) => {
					
				  },
				);
			}
	    });
	  }
	  
	  getAlertsCount(): void {
		var _self = this;
		var token = this.cookieService.get('sessionToken');
		this._loginService.getAlertsCount(token).subscribe(
		  (count: UsersData.Count) => {
			_self.countAlerts = count.countAlerts || 0;
			_self.countMessages = count.countMessages || 0;
		  },
		  (error: HttpErrorResponse) => {
			
		  },
		);
	  }

	  confirmPageChange(pageEvent: PageEvent) {
		  this.page = pageEvent.pageIndex+1;
		  this.getAllUsers();
	  }
}

import { Component, OnInit } from '@angular/core';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';
import { Alert } from 'src/app/models/alert.model';
import {MatDialog} from '@angular/material/dialog';
import { MessagedialogComponent } from '../components/messagedialog/messagedialog.component';

@Component({
  selector: 'app-trainerslist',
  templateUrl: './trainerslist.component.html',
  styleUrls: ['./trainerslist.component.scss']
})
export class TrainerslistComponent implements OnInit {

  trainers: UsersData.UserData[] = [];
  filters: UsersData.Filters = {
	query: "",
	isTrainer: true,
  	ageMin: 0,
  	ageMax: 100,
	preferredAgeMin: 0,
	preferredAgeMax: 100
  };
  userData: UsersData.User = {};
  pages: number = 1;
  currentPage: number = 1;
  limit: number = 6;
  alerts: Alert[] = [];

  constructor(public dialog: MatDialog, private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
	var _self = this;
    this.activatedRoute.queryParams.subscribe(params => {
		if(typeof params.query !== 'undefined')
	      _self.filters.query = params.query;
    });
	this.getUserData();
  }

  getUserData(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	if(token) {
		this._loginService.getUserData(token).subscribe(
		  (userData: UsersData.UserData) => {
			_self.userData.user = userData;
		  },
		  (error: HttpErrorResponse) => {
			
		  },
		);
	}
  }

  ngOnInit(): void {
	this.getUsersList();
  }
  
  goToTrainerPage(trainerid: number) {
	this.router.navigate(['/trener/'+trainerid]);
  }

  getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  getUsersList(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getUsersList(_self.filters, token, _self.currentPage, _self.limit).subscribe(
	  (search: UsersData.Search) => {
		_self.alerts = [];
		_self.trainers = search?.items || [];
		_self.pages = search?.pages || 0;
		if(_self.trainers.length == 0) {
			_self.alerts.push({message: 'Nie znaleziono trenerów.', type: 'info'});
		}
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  getGyms(userData: UsersData.UserData): any[] {
	var gyms = [];
	if(userData?.attributes) {
		for(var i = 0; i < userData?.attributes.length; i++) {
			if(userData?.attributes[i].attribute?.id == 1) {
				gyms.push({id: userData?.attributes[i].id, value: userData?.attributes[i].value})
			}
		}
	}
	return gyms;
  }

  messageDialogOpen(trainerid: number) {
	if(this.userData?.user?.id) {
	    var message = {
			to: trainerid,
			email: this.userData?.user?.email,
			name: this.userData?.user?.firstname+' '+this.userData?.user?.lastname,
			from: this.userData?.user?.id
		};
	  	const dialogRef = this.dialog.open(MessagedialogComponent, {
	      data: message
	    });
	}
	else {
        this.router.navigate(['/logowanie']);
	}
  }

  onChangeOrder(value: any) {
	this.currentPage = 1;
	this.filters.sortBy = value;
	this.changeFilters(this.filters);
  }

  changeFilters(filters: UsersData.Filters) {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	filters.isTrainer = true;
	filters.query = this.filters.query;
	this.filters = filters;
	this.getUsersList();
  }
  
  changePage(page: number) {
	  this.currentPage = page;
	  this.getUsersList();
  }
}

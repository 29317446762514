<div class="container">
	<div class="row">
		<div class="col-sm-12">
			<app-page-header></app-page-header>
		</div>
		<div class="col-sm-12">
			<div class="panelprofile">
				<app-alert [alerts]="alerts" style="width: 100%;"></app-alert>
				<form class="panelprofile-form" [formGroup]="paymentForm" (ngSubmit)="onSubmit($event)">
					<div class="panelprofile-form-header">
						<div class="panelprofile-form-header-title">
							Edytuj saldo konta użytkownika
						</div>
						<div class="panelprofile-form-header-content">
							<div class="row">
								<div class="panelprofile-form-header-content-info-box col-sm-12 col-xl-12">
									<div class="panelprofile-form-header-content-info row">
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-3">
											<label class="panelprofile-form-header-content-info-field-label">Saldo <sup style="color: red;">*wymagane</sup></label>
											<input formControlName="balance" class="panelprofile-form-header-content-info-field-input" type="number">
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<button class="panelprofile-form-header-content-info-submit" type="submit">
						<span>Zapisz zmiany</span>
					</button>
				</form>
				<div class="panelpayment-history-title">
					Historia salda:
				</div>
				<ul class="panelpayment-history">
					<li class="panelpayment-history-element" *ngFor="let history of histories">Data: {{history.date}} - Typ modyfikacji: {{history.name}} - Kwota: <span *ngIf="history.price >= 0" style="color: green;">+{{history.price}}zł</span><span *ngIf="history.price < 0" style="color: red;">{{history.price}}zł</span></li>
				</ul>
			</div>
		</div>
		<div class="col-sm-12 px-0">
			<app-page-footer></app-page-footer>
		</div>
	</div>
</div>
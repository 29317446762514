import { Component, OnInit } from '@angular/core';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-searcher',
  templateUrl: './searcher.component.html',
  styleUrls: ['./searcher.component.scss']
})
export class SearcherComponent implements OnInit {
  myControl = new FormControl();
  options: string[] = [];
  searchTimeout: any;
  trainers: number = 0;
  clients: number = 0;
  searchText: string = '';
  searchType: boolean = true;
  isLogged: boolean = false;

  constructor(private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) {
    if(this.cookieService.get( 'sessionToken')) {
		this.isLogged = true;
	}
  }

  ngOnInit(): void {
	
  }

  onSubmit(event: any): void {
	var params = {query: this.searchText, isTrainer: this.searchType};
	if(this.isLogged) {
		if(this.searchType)
			this.router.navigate(['/bazatrenerow'], {queryParams: params});
		else
			this.router.navigate(['/ofertadlatrenerow'], {queryParams: params});
	}
	else {
		this.router.navigate(['/logowanie']);
	}
  }

  /*getUsersList(value: string, isTrainer: boolean): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken')
	var filters = {query: value, isTrainer: isTrainer};
	this._loginService.getUsersList(filters, token).subscribe(
	  (search: UsersData.Search) => {
		_self.trainers = (search?.trainers || []).length;
		_self.clients = (search?.clients || []).length;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }*/

}

import { Component, OnInit } from '@angular/core';
import { Alert } from 'src/app/models/alert.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsersData } from 'src/app/models/users.namespace';
import { LoginService } from 'src/app/client/services/login/login.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
	
  contactForm: FormGroup;
  alerts: Alert[] = [];
  socials: UsersData.Socials = {
	'facebook': '',
	'twitter': '',
	'instagram': '',
	'youtube': ''
  };

  constructor(private _loginService: LoginService) {
	this.contactForm = new FormGroup({
     	'firstname': new FormControl('', [
		  Validators.required
		]),
		'lastname': new FormControl('', [
		  Validators.required
		]),
		'email': new FormControl('', [
		  Validators.required,
			Validators.email
		]),
		'phone': new FormControl('', [
		  Validators.required
		]),
		'message': new FormControl('', [
		  Validators.required
		])
    });
  }
  
  getSocials() {
    var _self = this;
    this._loginService.getSocials().subscribe(
		(socials: UsersData.Socials) => {
			_self.socials = socials;
		},
	    (error: HttpErrorResponse) => {
		},
    );
  }

  resolved(captchaResponse: string) {
	
  }

  ngOnInit(): void {
	this.getSocials();
  }

  onSubmit(event: any): void {
  	this.alerts = [];
	if (this.contactForm.invalid) {
		this.alerts.push({message: 'Prosimy poprawnie wypełnić wszystkie wymagane pola', type: 'danger'});
		for (var key in this.contactForm.errors) {
			this.alerts.push({message: key, type: 'danger'});
		}
	  return;
	}
	this.alerts.push({message: 'Wiadomość została wysłana.', type: 'success'});
	this.contactForm.reset();
  }

}

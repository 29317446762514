<div class="gymdialog">
	<div class="row">
		<div class="col-sm-12">
			<div class="gymdialog-content">
				<app-alert [alerts]="alerts" style="width: 100%;"></app-alert>
				<form class="gymdialog-form" [formGroup]="gymForm" (ngSubmit)="onSubmit($event)">
					<h1 class="gymdialog-form-title">
						Dodaj siłownię
					</h1>
					<div class="col-sm-12 gymdialog-form-field-textarea">
						<label class="gymdialog-form-input-label">Nazwa</label>
						<input formControlName="value" name="value" class="gymdialog-form-input"/>
					</div>
					<div class="col-sm-12 gymdialog-form-field-textarea">
						<label class="gymdialog-form-input-label">Adres</label>
						<input formControlName="secondValue" name="secondValue" class="gymdialog-form-input"/>
					</div>
					<div class="col-sm-12">
						<button class="gymdialog-form-button" type="submit">wyślij</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
import { Component, OnInit } from '@angular/core';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';
import { PracticedialogComponent } from '../components/practicedialog/practicedialog.component';
import { SupplementdialogComponent } from '../components/supplementdialog/supplementdialog.component';
import {MatDialog} from '@angular/material/dialog';
import { Alert } from 'src/app/models/alert.model';

@Component({
  selector: 'app-panelclienttraining',
  templateUrl: './panelclienttraining.component.html',
  styleUrls: ['./panelclienttraining.component.scss']
})
export class PanelclienttrainingComponent implements OnInit {
	
  clientid: number = 0;
  client: UsersData.UserData = {};
  serie: UsersData.Serie = {};
  serieStart: string = '';
  serieEnd: string = '';
  listOfDate: string[] = [];
  numbers: number[] = [];
  days: string[] = ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'];
  serienumbers: string[] = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X'];
  trainingseries: any[] = [];
  comment: string = '';
  disabled: boolean = true;
  statistics: UsersData.Statistics = {};
  seriesDates: UsersData.SeriesDatesItem[] = [];
  alerts: Alert[] = [];
  cellWidth: number = 320;
  cellHeight: number = 750;
  tabs: UsersData.SerieTime[] = [];

  constructor(public dialog: MatDialog, private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) {
	if(window.innerWidth < 768) {
    	this.cellWidth = 320;
    	this.cellHeight = 450;
    }
	if(!this.cookieService.get('sessionToken'))
		this.router.navigate(['/logowanie']);
    this.cellWidth = this.convertRemToPixels(27);
  }

  convertRemToPixels(rem: number): number {    
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }

  getTabs(): void {
	this.tabs = [];
	for(var i = 0; i < this.serie?.serietime_list.length; i++) {
		if(this.serie?.serietime_list[i].number !== this.serie?.number && this.tabs.length < 4)
			this.tabs.push(this.serie?.serietime_list[i]);
	}
  }

  getUserData(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken')
	this._loginService.getUserData(token).subscribe(
	  (userData: UsersData.UserData) => {
		if(!userData.idTrainer) {
	        this.router.navigate(['/panel/profil']);
		}
		_self.clientid = userData?.id || 0;
		_self.getSeriesDates();
		_self.getClientData();
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  getSeriesDatesItem(number: number): string {
	for(var i = 0; i < this.seriesDates.length; i++) {
		if(this.seriesDates[i].number == number)
			return this.seriesDates[i].date;
	}
	return '';
  }

  getSeriesDates(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken')
	this._loginService.getSeriesDates(token, this.clientid).subscribe(
	  (seriesDates: UsersData.SeriesDatesItem[]) => {
		_self.seriesDates = seriesDates;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  getClientData(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getClientData(this.clientid, token).subscribe(
	  (client: UsersData.UserData) => {
		_self.client = client;
		_self.getDimensions(_self.client?.defaultSerie ? _self.client?.defaultSerie : 1);
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  ngOnInit(): void {
	this.getUserData();
  }

  changeSerie(serie: number): void {
	this.getDimensions(serie);
  }

  getRepeats(seriedate: number): number {
	var practices = this.getPracticesForSerieDate(seriedate);
	var repeats = 0;
	for(var i = 0; i < practices.length; i++) {
		if(practices[i] && practices[i]?.trainingseries) {
			var trainingseries = practices[i].trainingseries || [];
			for(var j = 0; j < trainingseries.length; j++) {
				repeats += trainingseries[j].repeats || 0;
			}
		}
	}
	return repeats;
  }

  getWeight(seriedate: number): number {
	var practices = this.getPracticesForSerieDate(seriedate);
	var weight = 0;
	for(var i = 0; i < practices.length; i++) {
		if(practices[i] && practices[i]?.trainingseries) {
			var trainingseries = practices[i].trainingseries || [];
			for(var j = 0; j < trainingseries.length; j++) {
				weight += trainingseries[j].weight || 0;
			}
		}
	}
	return weight;
  }

  getStatistics(serie: number): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken')
	this._loginService.getStatistics(token, this.clientid, serie).subscribe(
	  (statistics: UsersData.Statistics) => {
		_self.statistics = statistics;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  getDimensions(serie: number): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken')
	this._loginService.getDimensions(serie, this.clientid, token).subscribe(
	  (serie: UsersData.Serie) => {
		_self.serie = serie;
		_self.numbers = Array(_self.serie.serietime).fill(_self.serie.serietime).map((x,i)=>i);
		_self.listOfDate = [];
		if(_self.client?.trainingstart && serie?.number && _self.serie?.serietime)
			for(var i = 0; i < _self.numbers.length; i++) {
				var date = new Date(_self.client?.trainingstart);
				date.setDate(date.getDate() + (i+((serie.number-1)*_self.serie.serietime)));
				var dayName = _self.days[date.getDay()];
				_self.listOfDate.push(date.getDate()+'.'+(date.getMonth()+1)+' ('+dayName+')');
				if(i == 0)
					_self.serieStart = date.getDate()+'.'+(date.getMonth()+1);
				if(i == _self.numbers.length-1)
					_self.serieEnd = date.getDate()+'.'+(date.getMonth()+1);
				_self.comment = serie.comment || '';
				var serieDate = _self.getSerieDate(i);
				if(serieDate !== null) {
					serieDate.date = date.getDate()+'.'+(date.getMonth()+1)+' ('+dayName+')';
				}
			}
		this.getStatistics(serie?.id || 0);
		_self.getTabs();
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  getSerieDate(seriedate: number): UsersData.Seriedate {
	if(this.serie?.seriedates)
		for(var i = 0; i < this.serie.seriedates.length; i++) {
			if(this.serie.seriedates[i].numberOfDay == seriedate) {
				return this.serie.seriedates[i];
			}
		}
	return null;
  }

  getPracticesForSerieDate(seriedate: number): UsersData.Practice[] {
	var practices: UsersData.Practice[] = [];
	if(this.serie?.seriedates)
		for(var i = 0; i < this.serie.seriedates.length; i++) {
			if(this.serie.seriedates[i].numberOfDay == seriedate) {
				return this.serie.seriedates[i].practices;
			}
		}
	return practices;
  }

  addSupplement() {
	var _self = this;
    var supplement = {
		serieid: this.serie.id
	};
  	const dialogRef = this.dialog.open(SupplementdialogComponent, {
      data: supplement
    });
  	dialogRef.afterClosed().subscribe(result => {
		if(this.serie?.number)
			_self.getDimensions(this.serie.number);
    });
  }

  addPractice(date: number) {
	var _self = this;
    var practice = {
	  	seriedate: date,
	  	position: this.serie.seriedates[date].practices ? this.serie.seriedates[date].practices.length : 0,
		serienumber: this.serie.number,
		clientid: this.clientid,
	};
  	const dialogRef = this.dialog.open(PracticedialogComponent, {
      data: practice
    });
  	dialogRef.afterClosed().subscribe(result => {
		if(this.serie?.number)
			_self.getDimensions(this.serie.number);
    });
  }

  addTrainingserie(practice: UsersData.Practice) {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	if(practice?.id)
		this._loginService.addTrainingserie(token, practice.id, (practice.trainingseries ? practice.trainingseries.length : 0)).subscribe(
	      (messageFromAPI: UsersData.MessageFromAPI) => {
			if(this.serie?.number)
				_self.getDimensions(this.serie.number);
	      },
	      (error: HttpErrorResponse) => {
	
	      },
	    );
  }

  itemChange(id: number, value: string, type: number) {
	var isSave = false;
	for(var i = 0; i < this.trainingseries.length; i++) {
		if(this.trainingseries[i].id == id) {
			this.trainingseries[i].complete = value;
			isSave = true;
			break;
		}
	}
	if(!isSave) {
		this.trainingseries.push({id: id, complete: value})
	}
  }

  saveChanges() {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.updateTrainingseries(token, this.trainingseries, this.comment, (this.serie.id ? this.serie.id : 0)).subscribe(
      (messageFromAPI: UsersData.MessageFromAPI) => {
		if(this.serie?.number)
			_self.getDimensions(this.serie.number);
		_self.alerts = [];
		_self.alerts.push({message: 'Zmiany zostały zapisane', type: 'success'});
		window.scroll(0,0);
      },
      (error: HttpErrorResponse) => {

      },
    );
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Alert } from 'src/app/models/alert.model';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';

@Component({
  selector: 'app-figuredialog',
  templateUrl: './figuredialog.component.html',
  styleUrls: ['./figuredialog.component.scss']
})
export class FiguredialogComponent implements OnInit {

	client: UsersData.UserData;
	figures: UsersData.Figure[] = [];
	
	constructor(private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService, public dialogRef: MatDialogRef<FiguredialogComponent>,
	  @Inject(MAT_DIALOG_DATA) public data: UsersData.UserData) {
	  this.client = data;
	}
	
	ngOnInit(): void {
		this.getFigures();
	}
	
	closeDialog() {
	  this.dialogRef.close(false);
	}
	
	getFigures(): void {
	  var _self = this;
		this._loginService.getFigures(this.client.gender || 'male').subscribe(
	    (figures: UsersData.Figure[]) => {
			_self.figures = figures;
	    },
	    (error: HttpErrorResponse) => {
	
	    },
	  );
	}
	
	selectFigure(figure_id: number): void {
	  var _self = this;
		this._loginService.changeFigure(this.cookieService.get( 'sessionToken'), this.client.id || 0, figure_id).subscribe(
	    (messageFromAPI: UsersData.MessageFromAPI) => {
			this.dialogRef.close(true);
	    },
	    (error: HttpErrorResponse) => {
	
	    },
	  );
	}
}

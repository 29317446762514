import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/client/services/login/login.service';

@Component({
  selector: 'app-cms',
  templateUrl: './cms.component.html',
  styleUrls: ['./cms.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CmsComponent implements OnInit {

  cms: UsersData.Cms = {};
  cmsid: number = 0;

  constructor(private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
	var _self = this;
    this.activatedRoute.params.subscribe(params => {
    	if(typeof params['cmsid'] !== 'undefined' && params['cmsid']) {
      		_self.cmsid = params['cmsid'];
      		_self.getCms();
    	}
    });
  }

  ngOnInit(): void {
  }
  
  getCms() {
    var _self = this;
    this._loginService.getCms(this.cmsid).subscribe(
		(cms: UsersData.Cms) => {
			_self.cms = cms;
		},
	    (error: HttpErrorResponse) => {
		},
    );
  }

}

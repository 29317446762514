<div class="supplementdialog">
	<div class="row">
		<div class="col-sm-12">
			<div class="supplementdialog-content">
				<app-alert [alerts]="alerts" style="width: 100%;"></app-alert>
				<form class="supplementdialog-form" [formGroup]="supplementForm" (ngSubmit)="onSubmit($event)">
					<h1 class="supplementdialog-form-title">
						Dodaj suplement
					</h1>
					<div class="col-sm-12 supplementdialog-form-field-textarea">
						<label class="supplementdialog-form-input-label">Nazwa suplementu (tekst) <span style="color: red; float: right;">*wymagane</span></label>
						<input formControlName="name" name="name" placeholder="białko" class="supplementdialog-form-input">
					</div>
					<div class="col-sm-12 supplementdialog-form-field-textarea">
						<label class="supplementdialog-form-input-label">Ilość (tekst) <span style="color: red; float: right;">*wymagane</span></label>
						<input formControlName="grams" name="grams" placeholder="100g" class="supplementdialog-form-input">
					</div>
					<div class="col-sm-12 supplementdialog-form-field-textarea">
						<label class="supplementdialog-form-input-label">Ile razy dziennie (tylko liczby) <span style="color: red; float: right;">*wymagane</span></label>
						<input formControlName="times" name="times" placeholder="2" class="supplementdialog-form-input">
					</div>
					<div class="col-sm-12 supplementdialog-form-field-textarea">
						<label class="supplementdialog-form-input-label">Komentarz (tekst)</label>
						<textarea formControlName="comment" name="comment" placeholder="przed treningiem" class="supplementdialog-form-textarea"></textarea>
					</div>
					<div class="col-sm-12">
						<button class="supplementdialog-form-button" type="submit">dodaj</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
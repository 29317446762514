import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Alert } from 'src/app/models/alert.model';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
	
  public loginForm: FormGroup;
  login: string = '';
  code: string = '';
  remindPasswordForm: FormGroup;
  codeForm: FormGroup;
  passwordForm: FormGroup;
  alerts: Alert[] = [];
  remind_password: boolean = false;
  change_password: boolean = false;
  change_password_code: boolean = false;
  userLogin: string = '';
  confirmToken: string = '';
  redirectURL: string = '';

  constructor(private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) {
    this.loginForm = new FormGroup({
      'login': new FormControl('', [
        Validators.required,
		Validators.email
      ]),
      'password': new FormControl('', [
        Validators.required
      ])
    });
    this.remindPasswordForm = new FormGroup({
      'login': new FormControl('', [
        Validators.required,
		Validators.email
      ])
    });
    this.codeForm = new FormGroup({
        'code': new FormControl('', [
          Validators.required
        ])
    });
    this.passwordForm = new FormGroup({
        'password': new FormControl('', [
	       Validators.required
	     ]),
        'repeatpassword': new FormControl('', [
          Validators.required
        ])
    });
	this.cookieService.set( 'sessionToken', '');
    var _self = this;
    this.activatedRoute.queryParams.subscribe(params => {
		if(typeof params['login'] !== 'undefined')
	      _self.userLogin = params['login'];
		if(typeof params['confirmToken'] !== 'undefined')
	      _self.confirmToken = params['confirmToken'];
		if(typeof params['redirectURL'] !== 'undefined')
	      _self.redirectURL = params['redirectURL'];
		if(_self.userLogin && _self.confirmToken)
			_self.confirmAccount();
		if(typeof params['alert_type'] !== 'undefined' && typeof params['alert'] !== 'undefined') {
			this.alerts.push({message: params['alert'], type: params['alert_type']});
		}
    });
  }

  ngOnInit(): void {
  }
  
  remindPassword(): void {
  	this.remind_password = true;
  }

  onSubmit(event: any): void {
    this.onLogin();
  }

  onSubmitRemindPassword(event: any): void {
  	this.alerts = [];
	if (this.remindPasswordForm.invalid) {
		this.alerts.push({message: 'Prosimy poprawnie wypełnić wszystkie wymagane pola', type: 'danger'});
		for (var key in this.remindPasswordForm.errors) {
			this.alerts.push({message: key, type: 'danger'});
		}
	  return;
	}

    var _self = this;
    this._loginService.remindpassword(this.remindPasswordForm.value.login).subscribe(
		(messageFromAPI: UsersData.MessageFromAPI) => {
			if(messageFromAPI?.result === 'success') {
				this.login = this.remindPasswordForm.value.login;
				this.alerts.push({message: (messageFromAPI?.message ? messageFromAPI?.message : ''), type: 'success'});
			  	this.remind_password = false;
			  	this.change_password_code = true;
			}
			else
				this.alerts.push({message: (messageFromAPI?.message ? messageFromAPI?.message : ''), type: 'danger'});
	    },
	    (error: HttpErrorResponse) => {
			this.alerts.push({message: 'Wystąpił błąd podczas wysyłania kodu', type: 'danger'});
		},
    );
  }
  
  onCheckCode(event: any): void {
    this.alerts = [];
	if (this.codeForm.invalid) {
		this.alerts.push({message: 'Prosimy poprawnie wypełnić wszystkie wymagane pola', type: 'danger'});
		for (var key in this.codeForm.errors) {
			this.alerts.push({message: key, type: 'danger'});
		}
	  return;
	}

    var _self = this;
    this._loginService.sendCode(this.login, this.codeForm.value.code).subscribe(
		(messageFromAPI: UsersData.MessageFromAPI) => {
			if(messageFromAPI?.result === 'success') {
				this.code = this.codeForm.value.code;
				this.alerts.push({message: (messageFromAPI?.message ? messageFromAPI?.message : ''), type: 'success'});
			  	this.remind_password = false;
			  	this.change_password_code = false;
			  	this.change_password = true;
			}
			else
				this.alerts.push({message: (messageFromAPI?.message ? messageFromAPI?.message : ''), type: 'danger'});
	    },
	    (error: HttpErrorResponse) => {
			this.alerts.push({message: 'Nieprawidłowy kod', type: 'danger'});
		},
    );
  }
  
  onChangePassword(event: any): void {
    this.alerts = [];
	if (this.passwordForm.invalid) {
		this.alerts.push({message: 'Prosimy poprawnie wypełnić wszystkie wymagane pola', type: 'danger'});
		for (var key in this.passwordForm.errors) {
			this.alerts.push({message: key, type: 'danger'});
		}
	  return;
	}
	if(this.passwordForm.value.password !== this.passwordForm.value.repeatpassword) {
	  this.alerts.push({message: 'Hasła nie są identyczne', type: 'danger'});
	  return;
	}

    var _self = this;
    this._loginService.resetpassword(this.login, this.code, this.passwordForm.value.password).subscribe(
		(messageFromAPI: UsersData.MessageFromAPI) => {
			if(messageFromAPI?.result === 'success') {
				this.router.navigate(['/logowanie']);
				this.remind_password = false;
			  	this.change_password_code = false;
			  	this.change_password = false;
			}
			else
				this.alerts.push({message: (messageFromAPI?.message ? messageFromAPI?.message : ''), type: 'danger'});
	    },
	    (error: HttpErrorResponse) => {
			this.alerts.push({message: 'Błąd podczas zmiany hasła', type: 'danger'});
		},
    );
  }

	getErrorList(errorObject: any) {
	  return Object.keys(errorObject);
	}

  confirmAccount(): void {
    var _self = this;
    this._loginService.confirmAccount(this.userLogin, this.confirmToken).subscribe(
      (auth: UsersData.Login) => {
		if(auth?.result === 'success') {
			this.cookieService.set( 'sessionNumber', (auth?.sessionNumber+"" || "0"));
			this.cookieService.set( 'sessionToken', (auth?.token || ''));
			if(auth?.isTrainer) {
				this.router.navigate(['/panel/trener/lista']);
			}
			else {
				this.router.navigate(['/panel/profil']);
			}
		}
		else if(auth?.message)
			this.alerts.push({message: auth?.message, type: 'danger'});
      },
      (error: HttpErrorResponse) => {
		this.alerts.push({message: 'Wystąpił błąd podczas logowania', type: 'danger'});
      },
    );
  }

  onLogin(): void {
  	this.alerts = [];
	if (this.loginForm.invalid) {
		this.alerts.push({message: 'Prosimy poprawnie wypełnić wszystkie wymagane pola', type: 'danger'});
		for (var key in this.loginForm.errors) {
			this.alerts.push({message: key, type: 'danger'});
		}
	  return;
	}

    var _self = this;
    this._loginService.login(this.loginForm.value.login, this.loginForm.value.password).subscribe(
      (auth: UsersData.Login) => {
		if(auth?.result === 'success') {
			this.cookieService.set( 'sessionNumber', (typeof auth.sessionNumber !== 'undefined' ? auth.sessionNumber+"" : "0"));
			this.cookieService.set( 'sessionToken', (typeof auth.token !== 'undefined' ? auth.token : ''));
			this.cookieService.set( 'sessionFA', (typeof auth.isAdministrator !== 'undefined' && auth.isAdministrator ? '1' : '0'));
			if(auth.isAdministrator) {
				if(!_self.redirectURL)
					this.router.navigate(['/panel/admin']);
				else
					this.router.navigate([_self.redirectURL]);
			}
			else if(auth.isTrainer) {
				if(!_self.redirectURL)
					this.router.navigate(['/panel/trener/lista']);
				else
					this.router.navigate([_self.redirectURL]);
			}
			else {
				if(!_self.redirectURL)
					this.router.navigate(['/panel/profil']);
				else
					this.router.navigate([_self.redirectURL]);
			}
		}
		else if(auth?.message)
			this.alerts.push({message: auth?.message, type: 'danger'});
      },
      (error: HttpErrorResponse) => {
		this.alerts.push({message: 'Wystąpił błąd podczas tworzenia konta', type: 'danger'});
      },
    );
  }

}

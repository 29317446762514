import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainPageModule } from './client/main-page/main-page.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {TranslationService} from './shared/services/translation.service';
import { PageModule } from './client/page/page.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export function translationInitializer(translationService: TranslationService) {
  return function () {
    return translationService.init('pl');  
  };
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    MainPageModule,
    PageModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'pl',
      loader: { 
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
        }    
    }),
    BrowserAnimationsModule
  ],
  providers: [
    {
      provide: NgModule, 
      useFactory: translationInitializer, 
      deps: [TranslationService], 
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';
import { MessagedialogComponent } from '../components/messagedialog/messagedialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-panelalerts',
  templateUrl: './panelalerts.component.html',
  styleUrls: ['./panelalerts.component.scss']
})
export class PanelalertsComponent implements OnInit {

  userData: UsersData.User = {};
  messages: UsersData.AlertMessage[] = [];
  activeMessage: UsersData.AlertMessage = {};

  constructor(public dialog: MatDialog, private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) {
    if(!this.cookieService.get('sessionToken'))
        this.router.navigate(['/logowanie']);
  }

  ngOnInit(): void {
	this.getUserData();
  }

  getUserData(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getUserData(token).subscribe(
	  (userData: UsersData.UserData) => {
		_self.userData.user = userData;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
	this.getMessages();
  }
  
  getMessages() {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getAlerts(token).subscribe(
	  (messages: UsersData.AlertMessage[]) => {
		_self.messages = messages;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }
  
  deleteMessage(activeMessage: UsersData.AlertMessage) {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.deleteAlert(activeMessage?.id || 0, token).subscribe(
	  (messageFromAPI: UsersData.MessageFromAPI) => {
		  this.activeMessage = {};
		  this.getMessages();
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  readMessage(message: UsersData.Invite) {
	this.activeMessage = message;
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.readAlert(token, this.activeMessage?.id || 0).subscribe(
	  (messageFromAPI: UsersData.MessageFromAPI) => {
		this.getMessages();
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

}

import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss']
})
export class PageFooterComponent implements OnInit {

  isLogged: boolean = false;

  constructor(private cookieService: CookieService) {
	if(this.cookieService.get( 'sessionToken')) {
		this.isLogged = true;
	}
  }

  ngOnInit(): void {
  }

}

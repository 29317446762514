import { Component, OnInit, Input } from '@angular/core';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginService } from 'src/app/client/services/login/login.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import {MatDialog} from '@angular/material/dialog';
import { ConfirmdialogComponent } from 'src/app/client/page/components/confirmdialog/confirmdialog.component';

@Component({
  selector: 'app-panelheader',
  templateUrl: './panelheader.component.html',
  styleUrls: ['./panelheader.component.scss']
})
export class PanelheaderComponent implements OnInit {
	
  @Input() id_tab: number = 1;
  @Input() id_client: number = 0;
  @Input() isSerieTime: boolean = false;
  userData: UsersData.User = {};
  client: UsersData.UserData = {};
  countAlerts: number = 0;
  countMessages: number = 0;

  constructor(public dialog: MatDialog, private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) { }

  ngOnInit(): void {
	this.getUserData();
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getClientData(this.id_client, token).subscribe(
	  (client: UsersData.UserData) => {
		_self.client = client;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
	this.getAlertsCount();
  }

  getUserData(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getUserData(token).subscribe(
	  (userData: UsersData.UserData) => {
		_self.userData.user = userData;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  deleteUserFromTrainer(isTrainer: boolean) {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	if(isTrainer) {
	    var confirm_item = {
			title: "Czy na pewno chcesz usunąć podopiecznego?"
		};
	  	const dialogRef = this.dialog.open(ConfirmdialogComponent, {
	      data: confirm_item
	    });
	  	dialogRef.afterClosed().subscribe(result => {
			if(result) {
				this._loginService.deleteUserFromTrainer(token, this.id_client).subscribe(
				  (messageFromAPI: UsersData.MessageFromAPI) => {
					this.router.navigate(['/panel/trener/lista']);
				  },
				  (error: HttpErrorResponse) => {
					
				  },
				);
			}
	    });
	}
	else {
	    var confirm_item = {
			title: "Czy na pewno chcesz zrezygnować z trenera?"
		};
	  	const dialogRef = this.dialog.open(ConfirmdialogComponent, {
	      data: confirm_item
	    });
	  	dialogRef.afterClosed().subscribe(result => {
			if(result) {
				this._loginService.deleteUserFromTrainer(token, this.userData?.user?.id || 0).subscribe(
				  (messageFromAPI: UsersData.MessageFromAPI) => {
					this.router.navigate(['/panel/profil']);
				  },
				  (error: HttpErrorResponse) => {
					
				  },
				);
			}
	    });
	}
  }

  getAlertsCount(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getAlertsCount(token).subscribe(
	  (count: UsersData.Count) => {
		_self.countAlerts = count.countAlerts || 0;
		_self.countMessages = count.countMessages || 0;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }
}

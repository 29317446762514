<div class="reviewdialog">
	<div class="row">
		<div class="col-sm-12">
			<div class="reviewdialog-content">
				<app-alert [alerts]="alerts" style="width: 100%;"></app-alert>
				<form class="reviewdialog-form" [formGroup]="reviewForm" (ngSubmit)="onSubmit($event)">
					<h1 class="reviewdialog-form-title">
						Wystaw ocenę
					</h1>
					<div class="col-sm-12 reviewdialog-form-field-textarea">
						<label class="reviewdialog-form-input-label">Ocena</label>
						<ngb-rating [(rate)]="rating">
							<ng-template let-fill="fill" let-index="index">
							    <span class="star" [class.filled]="fill === 100">&#9733;</span>
						    </ng-template>
						</ngb-rating>
					</div>
					<div class="col-sm-12 reviewdialog-form-field-textarea">
						<label class="reviewdialog-form-input-label">Treść wiadomości</label>
						<textarea formControlName="message" name="message" class="reviewdialog-form-input" rows="5"></textarea>
					</div>
					<div class="col-sm-12">
						<button class="reviewdialog-form-button" type="submit">wyślij</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Alert } from 'src/app/models/alert.model';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';

@Component({
  selector: 'app-ingredientdialog',
  templateUrl: './ingredientdialog.component.html',
  styleUrls: ['./ingredientdialog.component.scss']
})
export class IngredientdialogComponent implements OnInit {

  public ingredientForm: FormGroup;
  ingredient: UsersData.Ingredient;

  constructor(private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService, public dialogRef: MatDialogRef<IngredientdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UsersData.Ingredient) {
    this.ingredient = data;
    this.ingredientForm = new FormGroup({
      'name': new FormControl('', [
        
      ]),
      'kcal': new FormControl('', [
        
      ]),
      'protein': new FormControl('', [
        
      ]),
      'carbohydrates': new FormControl('', [
        
      ]),
      'fat': new FormControl('', [
        
      ]),
      'amount': new FormControl('', [
        
      ]),
      'unit': new FormControl('', [
        
      ])
    });
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit(event: any): void {
    var _self = this;
    this.ingredient.name = this.ingredientForm.value.name;
    this.ingredient.kcal = this.ingredientForm.value.kcal;
    this.ingredient.protein = this.ingredientForm.value.protein;
    this.ingredient.carbohydrates = this.ingredientForm.value.carbohydrates;
    this.ingredient.fat = this.ingredientForm.value.fat;
    this.ingredient.amount = this.ingredientForm.value.amount;
    this.ingredient.unit = this.ingredientForm.value.unit;
	this._loginService.addIngredient(this.cookieService.get( 'sessionToken'), this.ingredient).subscribe(
      (messageFromAPI: UsersData.MessageFromAPI) => {
		this.dialogRef.close();
      },
      (error: HttpErrorResponse) => {

      },
    );
  }
}

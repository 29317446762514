import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';
import { InvitedialogComponent } from '../components/invitedialog/invitedialog.component';
import { MessagedialogComponent } from '../components/messagedialog/messagedialog.component';
import { ReviewdialogComponent } from '../components/reviewdialog/reviewdialog.component';
import { Alert } from 'src/app/models/alert.model';

@Component({
  selector: 'app-trainer',
  templateUrl: './trainer.component.html',
  styleUrls: ['./trainer.component.scss']
})
export class TrainerComponent implements OnInit {

  alerts: Alert[] = [];
  trainer: UsersData.UserData = {};
  similartrainers: UsersData.UserData[] = [];
  userData: UsersData.User = {};
  trainerid: number = 0;

  constructor(public dialog: MatDialog, private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) {
	this.router.routeReuseStrategy.shouldReuseRoute = () => false;
	var _self = this;
    this.activatedRoute.params.subscribe(params => {
      _self.trainerid = params['trainerid'];
    });
  }

  ngOnInit(): void {
	this.getUserData();
	this.getTrainerData();
  }

  getTrainerData() {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getClientData(this.trainerid, token).subscribe(
	  (trainer: UsersData.UserData) => {
		_self.trainer = trainer;
		if(_self.trainer.deleted || !_self.trainer.active) {
			this.router.navigate(['/']);
		}
		_self.getSimilarTrainers();
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  getUserData(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	if(token) {
		this._loginService.getUserData(token).subscribe(
		  (userData: UsersData.UserData) => {
			_self.userData.user = userData;
		  },
		  (error: HttpErrorResponse) => {
			
		  },
		);
	}
  }

  getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  inviteDialogOpen() {
    var invite = {
	  	title: 'Nowy podopieczny wysłał Ci zaproszenie.',
		from: this.userData.user?.id,
		to: this.trainer.id,
		isInviteForTrainer: true
	};
  	const dialogRef = this.dialog.open(InvitedialogComponent, {
      data: invite
    });
	var _self = this;
  	dialogRef.afterClosed().subscribe(result => {
  		if(result) {
			_self.alerts = [];
			_self.alerts.push({message: 'Zaproszenie zostało wysłane', type: 'success'});
			window.scroll(0,0);
		}
    });
  }

  messageDialogOpen() {
    var message = {
		to: this.trainerid,
		email: this.userData?.user?.email,
		name: this.userData?.user?.firstname+' '+this.userData?.user?.lastname,
		from: this.userData?.user?.id || 0
	};
  	const dialogRef = this.dialog.open(MessagedialogComponent, {
      data: message
    });
	var _self = this;
  	dialogRef.afterClosed().subscribe(result => {
  		if(result) {
			_self.alerts = [];
			_self.alerts.push({message: 'Wiadomość została wysłana', type: 'success'});
			window.scroll(0,0);
  		}
    });
  }

  getGyms(userData: UsersData.UserData): any[] {
	var gyms = [];
	if(userData?.attributes) {
		for(var i = 0; i < userData?.attributes.length; i++) {
			if(userData?.attributes[i].attribute?.id == 1) {
				gyms.push({id: userData?.attributes[i].id, value: userData?.attributes[i].value, secondValue: userData?.attributes[i]?.secondValue})
			}
		}
	}
	return gyms;
  }

  getSimilarTrainers() {
    this._loginService.getSimilarTrainers(this.trainerid).subscribe(
      (search: UsersData.Search) => {
		this.similartrainers = search.items;
      },
      (error: HttpErrorResponse) => {
		
      },
    );
  }

  addReview() {
	if(this.userData?.user?.id) {
		var _self = this;
	    var review = {
		  	fromId: this.userData?.user?.id,
		  	fromName: this.userData?.user?.firstname+' '+this.userData?.user?.lastname,
		  	trainerid: this.trainer?.id
		};
	  	const dialogRef = this.dialog.open(ReviewdialogComponent, {
	      data: review
	    });
	  	dialogRef.afterClosed().subscribe(data => {
			_self.getTrainerData();
			_self.alerts = [];
	  		if(data?.result === 'success') {
				_self.alerts.push({message: data?.message, type: 'success'});
	  		}
	  		else if(data?.result === 'failed') {
				_self.alerts.push({message: data?.message, type: 'danger'});
	  		}
			window.scroll(0,0);
	    });
	}
  }

  open(file: string) {
	window.open(file, '_blank');
  }
  
  goToTrainerPage(trainerid: number) {
	this.router.navigate(['/trener/'+trainerid]);
  }
}

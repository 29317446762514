<div class="content-element-full-width-footer-top col-sm-12 px-0"></div>
<div class="content-element-full-width-footer col-sm-12 px-0">
	<img class="content-element-full-width-footer-background" src="/assets/img/stopka_tlo.jpg" alt="image" />
	<a [routerLink]="['/']"><img class="content-element-full-width-footer-logo" src="/assets/img/stopka_logo.png" alt="image" /></a>
	<div class="content-element-full-width-footer-content">
		<div class="content-element-full-width-footer-content-buttons">
			<ul class="content-element-full-width-footer-content-buttons-menu">
		      <li class="content-element-full-width-footer-content-buttons-menu__element-social">
		        <a
		          *ngIf="isLogged"
		          [routerLink]="['/bazatrenerow']"
		          class="content-element-full-width-footer-content-buttons-menu__element-anchor-blue"
		          >Baza trenerów</a
		        >
		        <a
		          *ngIf="!isLogged"
		          [routerLink]="['/logowanie']"
		          class="content-element-full-width-footer-content-buttons-menu__element-anchor-blue"
		          >Baza trenerów</a
		        >
		      </li>
		      <li class="content-element-full-width-footer-content-buttons-menu__element-social">
		        <a
		          *ngIf="isLogged"
		          [routerLink]="['/ofertadlatrenerow']"
		          class="content-element-full-width-footer-content-buttons-menu__element-anchor-pink"
		          >Oferta dla trenerów</a
		        >
		        <a
		          *ngIf="!isLogged"
		          [routerLink]="['/logowanie']"
		          class="content-element-full-width-footer-content-buttons-menu__element-anchor-pink"
		          >Oferta dla trenerów</a
		        >
		      </li>
			</ul>
		</div>
		<ul class="content-element-full-width-footer-content-links">
			<li class="content-element-full-width-footer-content-links-item">
				<a
		          [routerLink]="['/cms/1']"
		          class="content-element-full-width-footer-content-links-item-url"
		          >O nas</a>
			</li>
			<li class="content-element-full-width-footer-content-links-item">
				<a
		          [routerLink]="['/cms/2']"
		          class="content-element-full-width-footer-content-links-item-url"
		          >Cennik</a>
			</li>
			<li class="content-element-full-width-footer-content-links-item">
				<a
		          [routerLink]="['/kontakt']"
		          class="content-element-full-width-footer-content-links-item-url"
		          >Kontakt</a>
			</li>
			<li class="content-element-full-width-footer-content-links-item">
				<a
		          [routerLink]="['/cms/3']"
		          class="content-element-full-width-footer-content-links-item-url"
		          >Polityka prywatności</a>
			</li>
			<li class="content-element-full-width-footer-content-links-item">
				<a
		          [routerLink]="['/bazatrenerow']"
		          class="content-element-full-width-footer-content-links-item-url"
		          >Baza trenerów</a>
			</li>
			<li class="content-element-full-width-footer-content-links-item">
				<a
		          [routerLink]="['/ofertadlatrenerow']"
		          class="content-element-full-width-footer-content-links-item-url"
		          >Oferty dla trenerów</a>
			</li>
		</ul>
		<div class="content-element-full-width-footer-content-icons">
			<app-social-icons></app-social-icons>
		</div>
	</div>
</div>

<div class="container">
	<div class="row">
		<div class="col-sm-12">
			<app-page-header></app-page-header>
		</div>
		<div class="col-sm-12">
			<div class="panelmessages">
				<div class="row">
					<div class="col-sm-12 col-xl-7">
						<div class="panelmessages-content-info">
							<span class="panelmessages-content-info-title">{{activeMessage?.name}}</span>
						</div>
						<div class="panelmessages-content-description" [innerHTML]="activeMessage?.content">
						</div>
						<div class="panelmessages-content-action" *ngIf="activeMessage?.id">
							<button class="panelmessages-content-action-delete" (click)="deleteMessage(activeMessage)">usuń wiadomość</button>
						</div>
					</div>
					<div class="col-sm-12 col-xl-5">
						<div class="panelmessages-box">
							<div class="panelmessages-box-title">
								Powiadomienia
							</div>
							<ul class="panelmessages-box-list">
								<li class="panelmessages-box-list-item" *ngFor="let message of messages" (click)="readMessage(message)">
									<svg height="560pt" viewBox="-17 -101 560 560" width="560pt" xmlns="http://www.w3.org/2000/svg"><path d="m462.5-5.582031h-400c-34.511719.011719-62.484375 27.988281-62.5 62.5v233.371093c.015625 34.511719 27.988281 62.492188 62.5 62.5h400c34.511719-.007812 62.484375-27.988281 62.5-62.5v-233.371093c-.015625-34.511719-27.988281-62.488281-62.5-62.5zm-400 25h400c18.003906.046875 33.453125 12.824219 36.875 30.496093l-236.875 132.003907-236.875-132.003907c3.421875-17.671874 18.871094-30.449218 36.875-30.496093zm400 308.25h-400c-20.683594-.0625-37.441406-16.816407-37.5-37.5v-212l231.375 128.996093c1.875 1.03125 3.980469 1.59375 6.125 1.628907 2.152344.023437 4.265625-.539063 6.125-1.628907l231.375-128.996093v212c-.015625 20.703125-16.796875 37.480469-37.5 37.5zm0 0"/></svg>
									<div class="panelmessages-box-list-item-info" [ngClass]="{'active': message?.id == activeMessage?.id, 'unread': !message?.isReaded}">
										<span class="panelmessages-box-list-item-info-name">{{message.fromName}}:</span>
										<span class="panelmessages-box-list-item-info-title">{{message.name}}</span>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
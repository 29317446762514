import { Component, OnInit } from '@angular/core';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';
import {MatDialog} from '@angular/material/dialog';
import { ConfirmdialogComponent } from 'src/app/client/page/components/confirmdialog/confirmdialog.component';
import { MessagedialogComponent } from '../components/messagedialog/messagedialog.component';
import { Alert } from 'src/app/models/alert.model';

@Component({
  selector: 'app-paneladmincmslist',
  templateUrl: './paneladmincmslist.component.html',
  styleUrls: ['./paneladmincmslist.component.scss']
})
export class PaneladmincmslistComponent implements OnInit {

  displayedColumns: string[] = ['id', 'name', 'actions'];
  cmsList: UsersData.Cms[] = [];
  alerts: Alert[] = [];

  constructor(public dialog: MatDialog, private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) {
	if(!this.cookieService.get('sessionToken') || (!this.cookieService.get('sessionFA') || this.cookieService.get('sessionFA') !== '1'))
		this.router.navigate(['/logowanie']);
  }

  ngOnInit(): void {
	this.getCmsList();
  }
  
  getCmsList() {
    var _self = this;
    this._loginService.getCmsList().subscribe(
		(cmsList: UsersData.Cms[]) => {
			_self.cmsList = cmsList;
		},
	    (error: HttpErrorResponse) => {
		},
    );
  }
  
  deleteCms(id: number) {
    var _self = this;
	var token = this.cookieService.get('sessionToken');
    var confirm_item = {
		title: "Czy na pewno chcesz usunąć stronę CMS ?"
	};
  	const dialogRef = this.dialog.open(ConfirmdialogComponent, {
      data: confirm_item
    });
  	dialogRef.afterClosed().subscribe(result => {
		if(result) {
			this._loginService.deleteCms(id, token).subscribe(
				(messageFromAPI: UsersData.MessageFromAPI) => {
					if(messageFromAPI?.result === 'success') {
						this.alerts.push({message: (messageFromAPI?.message ? messageFromAPI?.message : ''), type: 'success'});
						this.getCmsList();
					}
					else
						this.alerts.push({message: (messageFromAPI?.message ? messageFromAPI?.message : ''), type: 'danger'});
			    },
			    (error: HttpErrorResponse) => {
					this.alerts.push({message: 'Wystąpił błąd podczas zapisu danych', type: 'danger'});
				},
		    );
		}
    });
  }
}

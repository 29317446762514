import { Component, OnInit, Input } from '@angular/core';
import { Alert } from 'src/app/models/alert.model';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  @Input() alerts: Alert[] = [];
  
  constructor() { }

  ngOnInit(): void {
  }

  closeAlert(event: any): void {
    var target = event.target.parentNode.parentNode;
    target.style.display = 'none';
  }

}

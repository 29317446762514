import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { TrainersData } from 'src/app/models/trainers.namespace';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';
import { InvitedialogComponent } from '../components/invitedialog/invitedialog.component';
import { MessagedialogComponent } from '../components/messagedialog/messagedialog.component';
import { Alert } from 'src/app/models/alert.model';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {

  alerts: Alert[] = [];
  client: UsersData.UserData = {};
  userData: UsersData.User = {};
  clientid: number = 0;

  constructor(public dialog: MatDialog, private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) {
  	/*this.client = {
        firstname: 'Lorem ipsum',
        lastname: 'Lorem ipsum',
        id: this.getRandomInt(0, 1000),
        address: 'Warszawa, Mazowieckie',
        info: 'lorem ipsum, 20 lat',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        photo: '/assets/img/trenerka.jpg',
        attrs: [
          {
            name: 'Trenuję',
            type: TrainersData.Type.SINGLE_VALUE,
            values: ['Kobiety Mężczyźni']
          },
          {
            name: 'Układanie diety',
            type: TrainersData.Type.SINGLE_VALUE,
            values: ['Tak']
          },
          {
            name: 'Trening online',
            type: TrainersData.Type.SINGLE_VALUE,
            values: ['Tak']
          },
          {
            name: 'Siłownie',
            type: TrainersData.Type.LIST,
            values: ['Cityfit, Twarda 18, Warszawa', 'Dowolna', 'Lorem', 'Ipsum']
          }
        ],
      };*/
	var _self = this;
    this.activatedRoute.params.subscribe(params => {
      _self.clientid = params['clientid'];
    });
  }

  
  ngOnInit(): void {
	this.getUserData();
	this.getClientData();
  }

  getClientData() {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getClientData(this.clientid, token).subscribe(
	  (client: UsersData.UserData) => {
		_self.client = client;
		if(_self.client.deleted || !_self.client.active) {
			this.router.navigate(['/']);
		}
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  getUserData(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	if(token) {
		this._loginService.getUserData(token).subscribe(
		  (userData: UsersData.UserData) => {
			_self.userData.user = userData;
		  },
		  (error: HttpErrorResponse) => {
			
		  },
		);
	}
  }

  getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  messageDialogOpen() {
    var message = {
		from: this.userData.user?.id,
		name: this.userData.user?.firstname+' '+this.userData.user?.lastname,
		email: this.userData.user?.email,
		to: this.clientid
	};
  	const dialogRef = this.dialog.open(MessagedialogComponent, {
      data: message
    });
	var _self = this;
  	dialogRef.afterClosed().subscribe(result => {
  		if(result) {
			_self.alerts = [];
			_self.alerts.push({message: 'Wiadomość została wysłana', type: 'success'});
			window.scroll(0,0);
  		}
    });
  }

  inviteDialogOpen() {
    var invite = {
	  	title: 'Zaproszenie od trenera '+this.userData.user?.firstname+' '+this.userData.user?.lastname,
		from: this.userData.user?.id,
		to: this.client.id,
		isInviteForTrainer: false
	};
  	const dialogRef = this.dialog.open(InvitedialogComponent, {
      data: invite
    });
	var _self = this;
  	dialogRef.afterClosed().subscribe(result => {
  		if(result) {
			_self.alerts = [];
			_self.alerts.push({message: 'Zaproszenie zostało wysłane', type: 'success'});
			window.scroll(0,0);
  		}
    });
  }

  getCities(userData: UsersData.UserData): any[] {
	var cities = [];
	if(userData?.attributes) {
		for(var i = 0; i < userData?.attributes.length; i++) {
			if(userData?.attributes[i].attribute?.id == 2) {
				cities.push({id: userData?.attributes[i].id, value: userData?.attributes[i].value})
			}
		}
	}
	return cities;
  }

  getGyms(userData: UsersData.UserData): any[] {
	var gyms = [];
	if(userData?.attributes) {
		for(var i = 0; i < userData?.attributes.length; i++) {
			if(userData?.attributes[i].attribute?.id == 1) {
				gyms.push({id: userData?.attributes[i].id, value: userData?.attributes[i].value, secondValue: userData?.attributes[i]?.secondValue})
			}
		}
	}
	return gyms;
  }

}

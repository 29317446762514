import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageComponent } from './page/page.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: PageComponent,
    data: {
      breadcrumbs: [
        {
          name: 'BREADCRUMBS_HOME',
          routerLinkPath: '/',
        },
      ],
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainPageRouting {}

<div class="panelheader">
	<ul class="panelheader-tabs">
		<!-- <li class="panelheader-tabs-tab" [ngClass]="{'active': id_tab === 1}"><a [routerLink]="['/']">Karta podpopiecznego</a></li> -->
		<li class="panelheader-tabs-tab client" *ngIf="!userData.user?.isTrainer" [ngClass]="{'active': id_tab === 4}"><a [routerLink]="['/panel/profil']">Profil</a></li>
		<li class="panelheader-tabs-tab client" *ngIf="!userData.user?.isTrainer" [ngClass]="{'active': id_tab === 1, 'disabled': !userData.user?.idTrainer || (!userData.user?.trainingstart && !isSerieTime)}"><a *ngIf="userData.user?.idTrainer && (userData.user?.trainingstart || isSerieTime)" [routerLink]="['/panel/podopieczny/mojakarta']">Moja karta</a><a *ngIf="!userData.user?.idTrainer" matTooltip="Obecnie nie posiadasz trenera.">Moja karta</a><a *ngIf="userData.user?.idTrainer && (!userData.user?.trainingstart && !isSerieTime)" matTooltip="Prosimy poczekać aż trener uzupełni wymagane dane.">Moja karta</a></li>
		<li class="panelheader-tabs-tab client" *ngIf="!userData.user?.isTrainer" [ngClass]="{'active': id_tab === 2, 'disabled': !userData.user?.idTrainer || (!userData.user?.trainingstart && !isSerieTime)}"><a *ngIf="userData.user?.idTrainer && (userData.user?.trainingstart || isSerieTime)" [routerLink]="['/panel/podopieczny/dieta']">Dieta</a><a *ngIf="!userData.user?.idTrainer" matTooltip="Obecnie nie posiadasz trenera.">Dieta</a><a *ngIf="userData.user?.idTrainer && (!userData.user?.trainingstart && !isSerieTime)" matTooltip="Prosimy poczekać aż trener uzupełni wymagane dane.">Dieta</a></li>
		<li class="panelheader-tabs-tab client" *ngIf="!userData.user?.isTrainer" [ngClass]="{'active': id_tab === 3, 'disabled': !userData.user?.idTrainer || (!userData.user?.trainingstart && !isSerieTime)}"><a *ngIf="userData.user?.idTrainer && (userData.user?.trainingstart || isSerieTime)" [routerLink]="['/panel/podopieczny/trening']">Trening</a><a *ngIf="!userData.user?.idTrainer" matTooltip="Obecnie nie posiadasz trenera.">Trening</a><a *ngIf="userData.user?.idTrainer && (!userData.user?.trainingstart && !isSerieTime)" matTooltip="Prosimy poczekać aż trener uzupełni wymagane dane.">Trening</a></li>
		<li class="panelheader-tabs-tab" *ngIf="userData.user?.isTrainer" [ngClass]="{'active': id_tab === 1}"><a [routerLink]="['/panel/trener/karta/'+id_client]">Karta podopiecznego</a></li>
		<li class="panelheader-tabs-tab" *ngIf="userData.user?.isTrainer" [ngClass]="{'active': id_tab === 2, 'disabled': !client?.trainingstart && !isSerieTime}"><a *ngIf="client?.trainingstart || isSerieTime" [routerLink]="['/panel/trener/dieta/'+id_client]">Dieta</a><a *ngIf="!client?.trainingstart && !isSerieTime" matTooltip="Prosimy uzupełnić datę rozpoczęcia treningu.">Dieta</a></li>
		<li class="panelheader-tabs-tab" *ngIf="userData.user?.isTrainer" [ngClass]="{'active': id_tab === 3, 'disabled': !client?.trainingstart && !isSerieTime}"><a *ngIf="client?.trainingstart || isSerieTime" [routerLink]="['/panel/trener/trening/'+id_client]">Trening</a><a *ngIf="!client?.trainingstart && !isSerieTime" matTooltip="Prosimy uzupełnić datę rozpoczęcia treningu.">Trening</a></li>
	</ul>
</div>
<div class="panelheader-header">
	<div class="panelheader-header-box">
		<div class="panelheader-header-box-photo">
			<img class="panelheader-header-box-photo-img" *ngIf="client?.photo" src="{{client?.photo}}"/>
			<img class="panelheader-header-box-photo-img" *ngIf="!client?.photo" src="/assets/img/default_img.svg"/>
		</div>
		<div class="panelheader-header-box-info" *ngIf="!userData.user?.isTrainer">
			<div class="panelheader-header-box-info-name" *ngIf="userData.user?.firstname || userData.user?.lastname"><span class="panelheader-header-box-info-name-span">{{userData.user?.firstname}} {{userData.user?.lastname}}</span></div>
			<div class="panelheader-header-box-info-name" *ngIf="!userData.user?.firstname && !userData.user?.lastname"><span class="panelheader-header-box-info-name-span">Brak imienia i nazwiska</span></div>
			<div class="panelheader-header-box-info-id" *ngIf="userData.user?.trainerName"><span class="panelheader-header-box-info-id-span">Trener: {{userData.user?.trainerName}}</span></div>
			<div class="panelheader-header-box-info-id" *ngIf="!userData.user?.trainerName"><span class="panelheader-header-box-info-id-span">Trener: brak trenera</span></div>
		</div>
		<div class="panelheader-header-box-info" *ngIf="userData.user?.isTrainer">
			<div class="panelheader-header-box-info-name" *ngIf="client?.firstname || client?.lastname"><span class="panelheader-header-box-info-name-span">{{client?.firstname}} {{client?.lastname}}</span></div>
			<div class="panelheader-header-box-info-name" *ngIf="!client?.firstname && !client?.lastname"><span class="panelheader-header-box-info-name-span">Brak imienia i nazwiska</span></div>
			<div class="panelheader-header-box-info-id"><span class="panelheader-header-box-info-id-span">podopieczny od: {{client?.traineracceptdate}}</span></div>
		</div>
		<div class="panelheader-header-box-action">
			<a class="panelheader-header-box-action-messages" [routerLink]="['/panel/poczta']" matTooltip="wiadomości">
				<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 477.867 477.867" style="enable-background:new 0 0 477.867 477.867;" xml:space="preserve">
				<g>
					<g>
						<path d="M426.667,0.002H51.2C22.923,0.002,0,22.925,0,51.202v273.067c0,28.277,22.923,51.2,51.2,51.2h60.587l-9.284,83.456
							c-1.035,9.369,5.721,17.802,15.09,18.837c4.838,0.534,9.674-1.023,13.292-4.279l108.919-98.014h186.863
							c28.277,0,51.2-22.923,51.2-51.2V51.202C477.867,22.925,454.944,0.002,426.667,0.002z M443.733,324.269
							c0,9.426-7.641,17.067-17.067,17.067H233.25c-4.217,0.001-8.284,1.564-11.418,4.386l-80.452,72.414l6.434-57.839
							c1.046-9.367-5.699-17.809-15.067-18.856c-0.63-0.07-1.263-0.106-1.897-0.105H51.2c-9.426,0-17.067-7.641-17.067-17.067V51.202
							c0-9.426,7.641-17.067,17.067-17.067h375.467c9.426,0,17.067,7.641,17.067,17.067V324.269z"/>
					</g>
				</g>
				</svg>
				<span class="count">{{countMessages}}</span>
			</a>
			<a class="panelheader-header-box-action-alerts" [routerLink]="['/panel/powiadomienia']" matTooltip="powiadomienia">
				<svg id="Capa_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m450.201 407.453c-1.505-.977-12.832-8.912-24.174-32.917-20.829-44.082-25.201-106.18-25.201-150.511 0-.193-.004-.384-.011-.576-.227-58.589-35.31-109.095-85.514-131.756v-34.657c0-31.45-25.544-57.036-56.942-57.036h-4.719c-31.398 0-56.942 25.586-56.942 57.036v34.655c-50.372 22.734-85.525 73.498-85.525 132.334 0 44.331-4.372 106.428-25.201 150.511-11.341 24.004-22.668 31.939-24.174 32.917-6.342 2.935-9.469 9.715-8.01 16.586 1.473 6.939 7.959 11.723 15.042 11.723h109.947c.614 42.141 35.008 76.238 77.223 76.238s76.609-34.097 77.223-76.238h109.947c7.082 0 13.569-4.784 15.042-11.723 1.457-6.871-1.669-13.652-8.011-16.586zm-223.502-350.417c0-14.881 12.086-26.987 26.942-26.987h4.719c14.856 0 26.942 12.106 26.942 26.987v24.917c-9.468-1.957-19.269-2.987-29.306-2.987-10.034 0-19.832 1.029-29.296 2.984v-24.914zm29.301 424.915c-25.673 0-46.614-20.617-47.223-46.188h94.445c-.608 25.57-21.549 46.188-47.222 46.188zm60.4-76.239c-.003 0-213.385 0-213.385 0 2.595-4.044 5.236-8.623 7.861-13.798 20.104-39.643 30.298-96.129 30.298-167.889 0-63.417 51.509-115.01 114.821-115.01s114.821 51.593 114.821 115.06c0 .185.003.369.01.553.057 71.472 10.25 127.755 30.298 167.286 2.625 5.176 5.267 9.754 7.861 13.798z"/></svg>
				<span class="count">{{countAlerts}}</span>
			</a>
			<a class="panelheader-header-box-action-deleteuser" *ngIf="userData.user?.isTrainer" (click)="deleteUserFromTrainer(true)" matTooltip="usuń podopiecznego">
				<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
				<g>
					<g>
						<path d="M407.672,280.596c-21.691-15.587-45.306-27.584-70.182-35.778C370.565,219.986,392,180.449,392,136
							C392,61.01,330.991,0,256,0S120,61.01,120,136c0,44.504,21.488,84.084,54.633,108.911c-30.368,9.998-58.863,25.555-83.803,46.069
							c-45.732,37.617-77.529,90.086-89.532,147.743c-3.762,18.066,0.745,36.622,12.363,50.908C25.222,503.847,42.365,512,60.693,512
							H267c11.046,0,20-8.954,20-20c0-11.046-8.954-20-20-20H60.693c-8.538,0-13.689-4.766-15.999-7.606
							c-3.989-4.905-5.533-11.29-4.236-17.519c20.756-99.695,108.691-172.521,210.24-174.977c1.759,0.068,3.526,0.102,5.302,0.102
							c1.788,0,3.569-0.035,5.341-0.104c44.466,1.052,86.883,15.236,122.988,41.182c8.969,6.446,21.467,4.399,27.913-4.569
							C418.688,299.539,416.642,287.042,407.672,280.596z M260.869,231.878c-1.621-0.03-3.244-0.045-4.869-0.045
							c-1.614,0-3.228,0.016-4.84,0.046C200.465,229.35,160,187.312,160,136c0-52.935,43.065-96,96-96s96,43.065,96,96
							C352,187.302,311.55,229.334,260.869,231.878z"/>
					</g>
				</g>
				<g>
					<g>
						<path d="M455.285,427l50.857-50.857c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.811-20.474-7.811-28.284,0L427,398.715
							l-50.858-50.858c-7.811-7.811-20.474-7.811-28.284,0c-7.81,7.811-7.811,20.475,0,28.285L398.715,427l-50.857,50.857
							c-7.811,7.811-7.811,20.475,0,28.285C351.763,510.048,356.881,512,362,512c5.119,0,10.237-1.952,14.142-5.857L427,455.285
							l50.858,50.858C481.763,510.048,486.881,512,492,512c5.119,0,10.237-1.952,14.142-5.857c7.811-7.811,7.811-20.475,0-28.285
							L455.285,427z"/>
					</g>
				</g>
				</svg>
			</a>
			<a class="panelheader-header-box-action-deleteuser" *ngIf="!userData.user?.isTrainer && userData.user?.idTrainer" (click)="deleteUserFromTrainer(false)" matTooltip="zrezygnuj z trenera">
				<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
				<g>
					<g>
						<path d="M407.672,280.596c-21.691-15.587-45.306-27.584-70.182-35.778C370.565,219.986,392,180.449,392,136
							C392,61.01,330.991,0,256,0S120,61.01,120,136c0,44.504,21.488,84.084,54.633,108.911c-30.368,9.998-58.863,25.555-83.803,46.069
							c-45.732,37.617-77.529,90.086-89.532,147.743c-3.762,18.066,0.745,36.622,12.363,50.908C25.222,503.847,42.365,512,60.693,512
							H267c11.046,0,20-8.954,20-20c0-11.046-8.954-20-20-20H60.693c-8.538,0-13.689-4.766-15.999-7.606
							c-3.989-4.905-5.533-11.29-4.236-17.519c20.756-99.695,108.691-172.521,210.24-174.977c1.759,0.068,3.526,0.102,5.302,0.102
							c1.788,0,3.569-0.035,5.341-0.104c44.466,1.052,86.883,15.236,122.988,41.182c8.969,6.446,21.467,4.399,27.913-4.569
							C418.688,299.539,416.642,287.042,407.672,280.596z M260.869,231.878c-1.621-0.03-3.244-0.045-4.869-0.045
							c-1.614,0-3.228,0.016-4.84,0.046C200.465,229.35,160,187.312,160,136c0-52.935,43.065-96,96-96s96,43.065,96,96
							C352,187.302,311.55,229.334,260.869,231.878z"/>
					</g>
				</g>
				<g>
					<g>
						<path d="M455.285,427l50.857-50.857c7.811-7.811,7.811-20.475,0-28.285c-7.811-7.811-20.474-7.811-28.284,0L427,398.715
							l-50.858-50.858c-7.811-7.811-20.474-7.811-28.284,0c-7.81,7.811-7.811,20.475,0,28.285L398.715,427l-50.857,50.857
							c-7.811,7.811-7.811,20.475,0,28.285C351.763,510.048,356.881,512,362,512c5.119,0,10.237-1.952,14.142-5.857L427,455.285
							l50.858,50.858C481.763,510.048,486.881,512,492,512c5.119,0,10.237-1.952,14.142-5.857c7.811-7.811,7.811-20.475,0-28.285
							L455.285,427z"/>
					</g>
				</g>
				</svg>
			</a>
			<a class="panelheader-header-box-action-exit" *ngIf="userData.user?.isTrainer" [routerLink]="['/panel/trener/lista']">
				<span>wyjdź z widoku podopiecznego</span><svg height="365.696pt" viewBox="0 0 365.696 365.696" width="365.696pt" xmlns="http://www.w3.org/2000/svg"><path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0"/></svg>
			</a>
		</div>
	</div>
</div>

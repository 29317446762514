import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';
import { Alert } from 'src/app/models/alert.model';
import {MatDialog} from '@angular/material/dialog';
import { FiguredialogComponent } from '../components/figuredialog/figuredialog.component';

@Component({
  selector: 'app-paneltrainercard',
  templateUrl: './paneltrainercard.component.html',
  styleUrls: ['./paneltrainercard.component.scss']
})
export class PaneltrainercardComponent implements OnInit {

  public sizesForm: FormGroup;
  userData: UsersData.User = {};
  serie: UsersData.Serie = {};
  clientid: number = 0;
  client: UsersData.UserData = {};
  disabled: boolean = true;
  regexGetGroupTime = /([^\s]+)$/;
  regex = /-/g;
  history: UsersData.UserHistory[] = [];
  serieStart: string = '';
  serieEnd: string = '';
  alerts: Alert[] = [];
  serieEndTime: number = 0;
  isSerieTime: boolean = false;

  constructor(public dialog: MatDialog, private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) {
	this.sizesForm = new FormGroup({
      'neck': new FormControl('', []),
      'thorax': new FormControl('', []),
      'waist': new FormControl('', []),
      'stomach': new FormControl('', []),
      'hips': new FormControl('', []),
      'thighs': new FormControl('', []),
      'calves': new FormControl('', []),
      'arm': new FormControl('', []),
      'wrist': new FormControl('', []),
      'height': new FormControl('', []),
      'weight': new FormControl('', []),
      'age': new FormControl('', []),
      'gender': new FormControl('', []),
      'comment': new FormControl('', []),
      'kcal': new FormControl('', []),
      'protein': new FormControl('', []),
      'carbohydrates': new FormControl('', []),
      'fat': new FormControl('', []),
      'serie': new FormControl('', []),
      'trainingstart': new FormControl('', []),
      'serietime': new FormControl('', [])
    });
	var _self = this;
    this.activatedRoute.params.subscribe(params => {
      _self.clientid = params['clientid'];
    });
    if(!this.cookieService.get('sessionToken'))
        this.router.navigate(['/logowanie']);
  }

  ngOnInit(): void {
	this.getUserData();
	this.getClientData();
  }
  
  getClientData(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getClientData(this.clientid, token).subscribe(
	  (client: UsersData.UserData) => {
		_self.client = client;
		_self.changeSerie(_self.client?.defaultSerie ? _self.client?.defaultSerie : 1);
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  getUserData(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken')
	this._loginService.getUserData(token).subscribe(
	  (userData: UsersData.UserData) => {
		_self.userData.user = userData;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  getDimensions(serie: number): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken')
	this._loginService.getDimensions(serie, this.clientid, token).subscribe(
	  (serie: UsersData.Serie) => {
		_self.serie = serie;
		this.sizesForm.controls['serietime'].setValue(serie.serietime, {onlySelf: true});
		if(_self.serie?.id) {
			var numbers = Array(this.serie?.serietime).fill(this.serie?.serietime).map((x,i)=>i);
			if(_self.serie?.user?.trainingstart && serie?.number && this.serie?.serietime) {
				for(var i = 0; i < numbers.length; i++) {
					var date = new Date(_self.serie?.user?.trainingstart);
					date.setDate(date.getDate() + (i+((serie.number-1)*this.serie?.serietime)));
					if(i == 0)
						_self.serieStart = date.getDate()+'.'+(date.getMonth()+1);
					if(i == numbers.length-1) {
						_self.serieEnd = date.getDate()+'.'+(date.getMonth()+1);
						_self.serieEndTime = date.getTime();
					}
				}
				
			}
			_self.getHistory();
		}
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  getHistory(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getHistory(token, _self.serie?.id).subscribe(
	  (history: UsersData.UserHistory[]) => {
		_self.history = history;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  changeSerie(serie: number): void {
	this.sizesForm.value.serie = serie;
	this.getDimensions(serie);
  }
  
  changeFigure(): void {
  	const dialogRef = this.dialog.open(FiguredialogComponent, {
      data: this.client
    });
	var _self = this;
  	dialogRef.afterClosed().subscribe(result => {
  		if(result) {
			_self.alerts = [];
			_self.alerts.push({message: 'Sylwetka została zmieniona.', type: 'success'});
			window.scroll(0,0);
			this.getClientData();
  		}
    });
  }

  onSubmit(event: any): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this.alerts = [];
	var dimensions = {
		kcal: parseInt(this.sizesForm.value.kcal ? this.sizesForm.value.kcal : this.serie?.dimensions?.kcal || 0),
		protein: parseInt(this.sizesForm.value.protein ? this.sizesForm.value.protein : this.serie?.dimensions?.protein || 0),
		carbohydrates: parseInt(this.sizesForm.value.carbohydrates ? this.sizesForm.value.carbohydrates : this.serie?.dimensions?.carbohydrates || 0),
		fat: parseInt(this.sizesForm.value.fat ? this.sizesForm.value.fat : this.serie?.dimensions?.fat || 0),
	};
	this._loginService.saveSerie((this.serie?.number ? this.serie?.number : 1), this.clientid, token, this.sizesForm.value.trainingstart, this.sizesForm.value.serietime, dimensions).subscribe(
	  (serie: UsersData.Serie) => {
		_self.serie = serie;
		_self.alerts.push({message: 'Zmiany zostały zapisane poprawnie.', type: 'success'});
		this.getDimensions(this.serie?.number);
		window.scroll(0,0);
		if(this.sizesForm.value.trainingstart) {
			this.isSerieTime = true;
		}
		else {
			this.isSerieTime = false;
		}
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }
}

<div class="container">
	<div class="row">
		<div class="col-sm-12">
			<app-page-header></app-page-header>
			<div class="breadcrumb">
				<div class="breadcrumb-content">
					<a [routerLink]="['/']"><span class="breadcrumb-content-main">Strona główna</span></a>
					<span class="breadcrumb-content-separator">></span>
					<span class="breadcrumb-content-page">{{client.firstname}} {{client.lastname}}</span>
				</div>
			</div>
		</div>
		<app-alert [alerts]="alerts" style="width: 100%;"></app-alert>
		<div class="col-xl-1 client-padding"></div>
		<div class="col-sm-12 col-xl-10 client-content">
			<div class="row">
				<div class="col-sm-12 col-xl-12 client-table">
					<div class="client-table-header">
						<div class="client-table-header-photo">
							<img class="client-table-header-photo-img" *ngIf="client.photo" src="{{client.photo}}"/>
							<img class="client-table-header-photo-img" *ngIf="!client.photo" src="/assets/img/default_img.svg"/>
						</div>
						<div class="client-table-header-info">
							<div class="row d-flex flex-column flex-lg-row">
								<div class="col-12 col-xl-4 client-table-header-info-left ">
									<div class="client-table-header-info-name"><span class="client-table-header-info-name-span">{{client.firstname}} {{client.lastname}}</span></div>
									<div class="client-table-header-info-id"><span class="client-table-header-info-id-span"><b>ID:</b> {{client.id}}</span></div>
								</div>
								<div class="col-12 col-xl-4 client-table-header-info-center">
									<div class="client-table-header-icons" *ngIf="client?.specializations?.length">
										<div class="client-table-header-icons-title">Obszary, które chcę usprawnić:</div>
										<div class="client-table-header-icons-element" *ngFor="let userSpecialization of client.specializations">
											<img class="client-table-header-icons-element-img" matTooltip="{{userSpecialization.specialization?.name}}" src="https://api.fitbook.pro{{userSpecialization.specialization?.icon}}"/>
										</div>
									</div>
								</div>
								<div class="col-12 col-xl-4 client-table-header-info-right">
									<div class="client-table-header-info-additional" *ngIf="client.gender">
										<span class="client-table-header-info-additional-span">
											<svg *ngIf="client.gender === 'male'" id="Capa_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m276.956 0v57.674h136.589l-101.389 101.389c-32.544-24.144-72.837-38.431-116.471-38.431-108.074 0-195.685 87.61-195.685 195.684 0 108.073 87.611 195.684 195.684 195.684s195.684-87.611 195.684-195.684c0-43.634-14.287-83.928-38.431-116.472l101.389-101.388v136.589h57.674v-235.045zm-81.272 447.552c-72.48 0-131.237-58.757-131.237-131.237s58.757-131.237 131.237-131.237 131.237 58.757 131.237 131.237c0 72.481-58.757 131.237-131.237 131.237z"/></g></svg>
											<svg *ngIf="client.gender !== 'male'" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
												 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
											<g>
												<g>
													<path d="M376.264,290.173c66.314-66.293,66.314-174.16,0-240.453c-66.314-66.294-174.214-66.294-240.529,0
														c-66.314,66.293-66.314,174.16,0,240.453c28.07,28.061,63.591,44.24,100.254,48.546v56.923h-40.018
														c-11.051,0-20.009,8.956-20.009,20.003s8.958,20.003,20.009,20.003h40.018v56.348c0.001,11.047,8.959,20.003,20.011,20.003
														c11.051,0,20.009-8.956,20.009-20.003v-56.348h40.019c11.051,0,20.009-8.956,20.009-20.003s-8.958-20.003-20.009-20.003h-40.019
														V338.72C312.673,334.413,348.194,318.234,376.264,290.173z M164.033,261.884c-50.711-50.695-50.711-133.181,0-183.876
														c50.71-50.693,133.221-50.696,183.934,0c50.711,50.695,50.711,133.181,0,183.876C297.256,312.578,214.744,312.578,164.033,261.884
														z"/>
												</g>
											</g>
											</svg>
											<span>{{client.gender === 'male' ? 'Mężczyzna' : 'Kobieta'}}, {{client.age}} lat(a)</span>
										</span>
									</div>
									<div class="client-table-header-info-address">
										<span class="client-table-header-info-address-content">
											<span class="client-table-header-info-address-content-span">
												<svg height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Pin-2" data-name="Pin"><path d="m25.0464 8.4834a10 10 0 0 0 -7.9116-5.4258 11.3644 11.3644 0 0 0 -2.2691 0 10.0027 10.0027 0 0 0 -7.9121 5.4253 10.8062 10.8062 0 0 0 1.481 11.8936l6.7929 8.2588a1 1 0 0 0 1.545 0l6.7929-8.2588a10.8055 10.8055 0 0 0 1.481-11.8931zm-9.0464 8.5166a4 4 0 1 1 4-4 4.0047 4.0047 0 0 1 -4 4z"/></g></svg>
												<span class="client-table-header-info-address-content-span-sub">
													<span>{{client?.address?.city}}, {{client?.address?.state}}</span>
													<a href="https://www.google.pl/maps/place/{{client?.address?.city}}" target="_blank">
														zobacz obszar działalności
													</a>
												</span>
											</span>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-xl-10 pr-xl-5 client-table-description-column-left">
					<div class="client-table-description">
						<div class="client-table-description-title">
							Opis:
						</div>
						<div class="client-table-description-content">
							{{client.short_description}}
						</div>
						<div class="client-table-description-documents">
							<div class="client-table-description-documents-title">
							</div>
							<ul class="client-table-description-documents-list">
							</ul>
						</div>
					</div>
					<div class="client-table-button-right" *ngIf="userData?.user?.id && userData?.user?.firstname && userData?.user?.lastname">
						<a class="client-table-button-message" *ngIf="userData?.user?.id != clientid" (click)="messageDialogOpen()">wyślij zapytanie</a>
						<a class="client-table-button-invitation" *ngIf="userData?.user?.isTrainer && userData?.user?.id != clientid" (click)="inviteDialogOpen()">zaproś</a>
					</div>
				</div>
				<div class="col-sm-12 col-xl-2 px-0 client-table-description-column-right">
					<div class="client-table-informations">
						<div class="client-table-informations-title">
							<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
								 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
							<g>
								<g>
									<path d="M256,0C114.497,0,0,114.507,0,256c0,141.503,114.507,256,256,256c141.503,0,256-114.507,256-256
										C512,114.497,397.493,0,256,0z M256,472c-119.393,0-216-96.615-216-216c0-119.393,96.615-216,216-216
										c119.393,0,216,96.615,216,216C472,375.393,375.385,472,256,472z"/>
								</g>
							</g>
							<g>
								<g>
									<path d="M256,128.877c-11.046,0-20,8.954-20,20V277.67c0,11.046,8.954,20,20,20s20-8.954,20-20V148.877
										C276,137.831,267.046,128.877,256,128.877z"/>
								</g>
							</g>
							<g>
								<g>
									<circle cx="256" cy="349.16" r="27"/>
								</g>
							</g>
							</svg>
							<span>Informacje</span>
						</div>
						<ul class="client-table-informations-attrs">
							<li class="client-table-informations-attrs-attr">
								<div class="client-table-informations-attrs-attr-name">Preferencje płci trenera:</div>
								<span>..............................................................................................................................................................</span>
								<div class="client-table-informations-attrs-attr-value">{{client.gender_client === 'male' ? 'Mężczyzna' : (client.gender_client === 'female' ? 'Kobieta' : 'Brak preferencji')}}</div>
							</li>
							<li class="client-table-informations-attrs-attr">
								<div class="client-table-informations-attrs-attr-name">Trening online:</div>
								<span>..............................................................................................................................................................</span>
								<div class="client-table-informations-attrs-attr-value">{{client.isOnline ? 'Tak' : 'Nie'}}</div>
							</li>
							<li class="client-table-informations-attrs-attr">
								<div class="client-table-informations-attrs-attr-name">Preferowany wiek trenera:</div>
								<span>..............................................................................................................................................................</span>
								<div class="client-table-informations-attrs-attr-value">{{client.preferredAgeMin}} - {{client.preferredAgeMax}} lat</div>
							</li>
							<li class="client-table-informations-attrs-attr-list" *ngIf="getCities(client).length">
								<div class="client-table-informations-attrs-attr-label">Miejsca, w których trenuję:</div>
								<ul class="client-table-informations-attrs-attr-values">
									<li *ngFor="let city of getCities(client)" class="client-table-informations-attrs-attr-values-item row">
										<div class="client-table-informations-attrs-attr-values-item-triangle col-sm-3"></div>
										<div class="client-table-informations-attrs-attr-values-item-text col-sm-9 px-0 text-right">
											<span><b>{{city.value}}</b></span>
										</div>
									</li>
								</ul>
							</li>
							<li class="client-table-informations-attrs-attr" *ngIf="getGyms(client).length">
								<div class="client-table-informations-attrs-attr-label">Preferowane siłownie:</div>
								<ul class="client-table-informations-attrs-attr-values">
									<li *ngFor="let gym of getGyms(client)" class="client-table-informations-attrs-attr-values-item row">
										<div class="client-table-informations-attrs-attr-values-item-triangle col-sm-3"></div>
										<div class="client-table-informations-attrs-attr-values-item-text col-sm-9 px-0 text-right">
											<span><b>{{gym.value}}</b></span>
											<span>{{gym.secondValue}}</span>
										</div>
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="col-sm-12 px-0">
			<app-page-footer></app-page-footer>
		</div>
	</div>
</div>
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Options } from "@angular-slider/ngx-slider";
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
  @Output() changeFilters = new EventEmitter<any>();
  @Input() isTrainerList: boolean = false;
	
  value: number = 0;
  highValue: number = 100;
  options: Options = {
    floor: 0,
    ceil: 100,
    showTicks: true
  };
  filters: UsersData.FiltersObject[] = [];
  filtersForSearch: UsersData.Filters = {
  	address_city: '',
    address_state: '',
  	work_address_city: '',
    work_address_state: '',
  	isOnline: false,
	gender: '',
    gender_client: '',
  	isTrainer: false,
    query: '',
    specializations: [],
  	ageMin: 0,
  	ageMax: 100,
	preferredAgeMin: 0,
	preferredAgeMax: 100,
  	isDietician: 2,
  	review: 0,
  	sortBy: 'firstname:ASC',
  };

  constructor(private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) { }

  ngOnInit(): void {
	this.getFilters();
  }
  
  displayChange(index: number) {
	if(this.filters[index].display)
		this.filters[index].display = false;
	else
		this.filters[index].display = true;
  }

  getFilters() {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	if(this.isTrainerList) {
		this._loginService.getFiltersTrainer(token).subscribe(
		  (filters: UsersData.FiltersObject[]) => {
			_self.filters = filters;
		  },
		  (error: HttpErrorResponse) => {
			
		  },
		);
	}
	else {
		this._loginService.getFiltersClient(token).subscribe(
		  (filters: UsersData.FiltersObject[]) => {
			_self.filters = filters;
		  },
		  (error: HttpErrorResponse) => {
			
		  },
		);
	}
  }

  onChangeFilters(name?: string, value?: any, id?: number) {
	if(name) {
		if(name === 'address_city')
			this.filtersForSearch.address_city = value;
		else if(name === 'address_state')
			this.filtersForSearch.address_state = value;
		else if(name === 'work_address_city')
			this.filtersForSearch.work_address_city = value;
		else if(name === 'work_address_state')
			this.filtersForSearch.work_address_state = value;
		else if(name === 'isOnline')
			this.filtersForSearch.isOnline = value;
		else if(name === 'isDietician_1') {
			if(!value)
				this.filtersForSearch.isDietician = 2;
			else
				this.filtersForSearch.isDietician = 1;
		}
		else if(name === 'isDietician_0') {
			if(!value)
				this.filtersForSearch.isDietician = 2;
			else
				this.filtersForSearch.isDietician = 0;
		}
		else if(name === 'review_1') {
			if(!value)
				this.filtersForSearch.review = 0;
			else 
				this.filtersForSearch.review = 1;
		}
		else if(name === 'review_2') {
			if(!value)
				this.filtersForSearch.review = 0;
			else 
				this.filtersForSearch.review = 2;
		}
		else if(name === 'review_3') {
			if(!value)
				this.filtersForSearch.review = 0;
			else 
				this.filtersForSearch.review = 3;
		}
		else if(name === 'review_4') {
			if(!value)
				this.filtersForSearch.review = 0;
			else 
				this.filtersForSearch.review = 4;
		}
		else if(name === 'review_5') {
			if(!value)
				this.filtersForSearch.review = 0;
			else 
				this.filtersForSearch.review = 5;
		}
		else if(name === 'specialization') {
			if(!value) {
				if(this.filtersForSearch?.specializations?.includes(id || 0))
					this.filtersForSearch.specializations.splice(this.filtersForSearch.specializations.indexOf(id || 0), 1);
			}
			else {
				if(!this.filtersForSearch?.specializations?.includes(id || 0))
					this.filtersForSearch?.specializations?.push(id || 0);
			}
		}
		else if(name === 'gender_female') {
			if(value) {
				this.filtersForSearch.gender = 'female';
			}
			if(!value) {
				if(this.filtersForSearch?.gender === 'female')
					this.filtersForSearch.gender = '';
			}
		}
		else if(name === 'gender_male') {
			if(value) {
				this.filtersForSearch.gender = 'male';
			}
			if(!value) {
				if(this.filtersForSearch?.gender === 'male')
					this.filtersForSearch.gender = '';
			}
		}
		else if(name === 'gender_prefer_female') {
			if(value) {
				this.filtersForSearch.gender_client = 'female';
			}
			if(!value) {
				if(this.filtersForSearch?.gender_client === 'female')
					this.filtersForSearch.gender_client = '';
			}
		}
		else if(name === 'gender_prefer_male') {
			if(value) {
				this.filtersForSearch.gender_client = 'male';
			}
			if(!value) {
				if(this.filtersForSearch?.gender_client === 'male')
					this.filtersForSearch.gender_client = '';
			}
		}
		this.changeFilters.emit(this.filtersForSearch);
	}
  	
  }
}

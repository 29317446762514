import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';
import { Alert } from 'src/app/models/alert.model';

@Component({
  selector: 'app-paneladminpayment',
  templateUrl: './paneladminpayment.component.html',
  styleUrls: ['./paneladminpayment.component.scss']
})
export class PaneladminpaymentComponent implements OnInit {
  userData: UsersData.User = {};
  histories: UsersData.HistoryBalance[] = [];
  clientid: number = 0;
  public paymentForm: FormGroup;
  alerts: Alert[] = [];

  constructor(private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) {
	this.paymentForm = new FormGroup({
      'balance': new FormControl('', [
        Validators.required
      ])
    });
	var _self = this;
    this.activatedRoute.params.subscribe(params => {
      _self.clientid = params['clientid'];
    });
	if(!this.cookieService.get('sessionToken') || (!this.cookieService.get('sessionFA') || this.cookieService.get('sessionFA') !== '1'))
        this.router.navigate(['/logowanie']);
	this.getUserData();
  }

  ngOnInit(): void {
  }

  getUserData(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getClientData(this.clientid, token).subscribe(
	  (userData: UsersData.UserData) => {
		_self.userData.user = userData;
	    this.paymentForm.controls['balance'].setValue(_self.userData?.user?.balance);
		_self.getHistoryBalance();
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  getHistoryBalance(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getHistoryBalance(this.clientid, token).subscribe(
	  (histories: UsersData.HistoryBalance[]) => {
		_self.histories = histories;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  onSubmit(event: any): void {
  	this.alerts = [];
	if (this.paymentForm.invalid) {
		this.alerts.push({message: 'Prosimy wypełnić wszystkie wymagane pola', type: 'danger'});
		for (var key in this.paymentForm.errors) {
			this.alerts.push({message: key, type: 'danger'});
		}
	  return;
	}
	
    var _self = this;
	var token = this.cookieService.get('sessionToken');
    this._loginService.adminPayment(this.paymentForm.value.balance, this.clientid, token).subscribe(
      (messageFromAPI: UsersData.MessageFromAPI) => {
		this.alerts.push({message: messageFromAPI.message || '', type: messageFromAPI.result || 'success'});
		window.scroll(0,0);
		_self.getHistoryBalance();
      },
      (error: HttpErrorResponse) => {
		this.alerts.push({message: 'Wystąpił błąd podczas zmiany salda', type: 'danger'});
		window.scroll(0,0);
      },
    );
  }
}

import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Alert } from 'src/app/models/alert.model';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';

@Component({
  selector: 'app-messagedialog',
  templateUrl: './messagedialog.component.html',
  styleUrls: ['./messagedialog.component.scss']
})
export class MessagedialogComponent implements OnInit {

  public messageForm: FormGroup;
  message: UsersData.Invite;
  alerts: Alert[] = [];

  constructor(private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService, public dialogRef: MatDialogRef<MessagedialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UsersData.Invite) {
    this.message = data;
    this.messageForm = new FormGroup({
      'message': new FormControl('', [
        Validators.required
      ])
    });
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

  onSubmit(event: any): void {
  	this.alerts = [];
	if (this.messageForm.invalid) {
		this.alerts.push({message: 'Prosimy uzupełnić treść wiadomości', type: 'danger'});
	  return;
	}
    var _self = this;
    this.message.title = 'Wiadomość od '+this.data.name+' ('+this.data.email+')';
    this.message.message = this.messageForm.value.message;
    this._loginService.message(this.cookieService.get( 'sessionToken'), this.message).subscribe(
      (message: UsersData.Invite) => {
		//this.message = message;
	    this.dialogRef.close(message);
      },
      (error: HttpErrorResponse) => {

      },
    );
  }

  getErrors = (formGroup: FormGroup, errors: any = {}) => {
	  Object.keys(formGroup.controls).forEach(field => {
	    const control = formGroup.get(field);
	    if (control instanceof FormControl) {
	      errors[field] = control.errors;
	    } else if (control instanceof FormGroup) {
	      errors[field] = this.getErrors(control);
	    }
	  });
	  return errors;
  }
}

<div class="container">
	<div class="row">
		<div class="col-sm-12">
			<app-page-header></app-page-header>
			<div class="breadcrumb">
				<div class="breadcrumb-content">
					<a [routerLink]="['/']"><span class="breadcrumb-content-main">Strona główna</span></a>
					<span class="breadcrumb-content-separator">></span>
					<span class="breadcrumb-content-page">Baza trenerów</span>
				</div>
			</div>
		</div>
		<div class="col-sm-12 trainerslist-column-left">
			<app-filters [isTrainerList]="true" (changeFilters)="changeFilters($event)"></app-filters>
		</div>
		<div class="col-sm-12 trainerslist-column-right">
			<div>
				<div class="trainerslist col-sm-12">
					<div class="trainerslist-title">
						<h1 class="trainerslist-title-h1">Baza trenerów</h1>
						<div class="trainerslist-title-pagination">
							<select id="sortBy" name="sortBy" (change)="onChangeOrder($event.target.value)" class="trainerslist-title-pagination-sort">
								<option [value]="'datecreatestamp:DESC'">od najnowszych</option>
								<option [value]="'datecreatestamp:ASC'">od najstarszych</option>
								<option [value]="'firstname:ASC'">od A do Z</option>
								<option [value]="'firstname:DESC'">od Z do A</option>
							</select>
							<div class="trainerslist-title-pagination-pages">
								<span (click)="changePage(1)" *ngIf="(currentPage) > 1" class="trainerslist-title-pagination-page">1</span>
								<span *ngIf="(currentPage) > 1" class="trainerslist-title-pagination-page-arrows">&lt;&lt;</span>
								<span (click)="changePage(currentPage-1)" *ngIf="(currentPage) > 2" class="trainerslist-title-pagination-page">{{currentPage-1}}</span>
								<span *ngIf="(currentPage) > 2" class="trainerslist-title-pagination-page-arrows">&lt;</span>
								<span class="trainerslist-title-pagination-page-current">{{currentPage}}</span>
								<span *ngIf="pages > (currentPage+1)" class="trainerslist-title-pagination-page-arrows">&gt;</span>
								<span (click)="changePage(currentPage+1)" *ngIf="pages > (currentPage+1)" class="trainerslist-title-pagination-page">{{currentPage+1}}</span>
								<span *ngIf="pages > currentPage" class="trainerslist-title-pagination-page-arrows">&gt;&gt;</span>
								<span (click)="changePage(pages)" *ngIf="pages > currentPage" class="trainerslist-title-pagination-page">{{pages}}</span>
							</div>
						</div>
					</div>
					<div class="trainerslist-table row">
						<app-alert [alerts]="alerts" style="width: 100%;"></app-alert>
						<a *ngFor="let item of trainers" class="col-sm-12 col-md-6 col-xl-4 trainerslist-table-item-content">
							<div class="trainerslist-table-item">
								<div class="row">
									<div class="col-sm-12">
										<div class="trainerslist-table-item-photo">
											<img class="trainerslist-table-item-photo-img" *ngIf="item.photo" src="{{item.photo}}"/>
											<img class="trainerslist-table-item-photo-img" *ngIf="!item.photo" src="/assets/img/default_img.svg"/>
										</div>
										<div class="trainerslist-table-item-info">
											<div class="trainerslist-table-item-info-name"><span class="trainerslist-table-item-info-name-span">{{item.firstname}} {{item.lastname}}</span></div>
											<div class="trainerslist-table-item-info-id"><span class="trainerslist-table-item-info-id-span"><strong>ID:</strong> {{item.id}}</span></div>
											<div class="trainerslist-table-item-info-address">
												<span class="trainerslist-table-item-info-address-span">
													<svg height="512" viewBox="0 0 32 32" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Pin-2" data-name="Pin"><path d="m25.0464 8.4834a10 10 0 0 0 -7.9116-5.4258 11.3644 11.3644 0 0 0 -2.2691 0 10.0027 10.0027 0 0 0 -7.9121 5.4253 10.8062 10.8062 0 0 0 1.481 11.8936l6.7929 8.2588a1 1 0 0 0 1.545 0l6.7929-8.2588a10.8055 10.8055 0 0 0 1.481-11.8931zm-9.0464 8.5166a4 4 0 1 1 4-4 4.0047 4.0047 0 0 1 -4 4z"/></g></svg>
													<span>{{item?.address?.city}}, {{item?.address?.state}}</span>
													<a href="https://www.google.pl/maps/place/{{item?.address?.city}}" target="_blank" class="trainerslist-table-item-info-address-span-localization">zobacz obszar działalności</a>
												</span>
											</div>
											<div class="trainerslist-table-item-info-additional"><span class="trainerslist-table-item-info-additional-span">
												<svg *ngIf="item.gender === 'male'" id="Capa_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m276.956 0v57.674h136.589l-101.389 101.389c-32.544-24.144-72.837-38.431-116.471-38.431-108.074 0-195.685 87.61-195.685 195.684 0 108.073 87.611 195.684 195.684 195.684s195.684-87.611 195.684-195.684c0-43.634-14.287-83.928-38.431-116.472l101.389-101.388v136.589h57.674v-235.045zm-81.272 447.552c-72.48 0-131.237-58.757-131.237-131.237s58.757-131.237 131.237-131.237 131.237 58.757 131.237 131.237c0 72.481-58.757 131.237-131.237 131.237z"/></g></svg>
												<svg *ngIf="item.gender !== 'male'" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
													<g>
														<g>
															<path d="M376.264,290.173c66.314-66.293,66.314-174.16,0-240.453c-66.314-66.294-174.214-66.294-240.529,0
																c-66.314,66.293-66.314,174.16,0,240.453c28.07,28.061,63.591,44.24,100.254,48.546v56.923h-40.018
																c-11.051,0-20.009,8.956-20.009,20.003s8.958,20.003,20.009,20.003h40.018v56.348c0.001,11.047,8.959,20.003,20.011,20.003
																c11.051,0,20.009-8.956,20.009-20.003v-56.348h40.019c11.051,0,20.009-8.956,20.009-20.003s-8.958-20.003-20.009-20.003h-40.019
																V338.72C312.673,334.413,348.194,318.234,376.264,290.173z M164.033,261.884c-50.711-50.695-50.711-133.181,0-183.876
																c50.71-50.693,133.221-50.696,183.934,0c50.711,50.695,50.711,133.181,0,183.876C297.256,312.578,214.744,312.578,164.033,261.884
																z"/>
														</g>
													</g>
												</svg>
												<span>{{item.gender === 'male' ? 'Mężczyzna' : 'Kobieta'}}, {{item.age}} lat(a)</span></span>
											</div>
										</div>
									</div>
								</div>
								<div class="col-sm-12 px-0">
									<div class="trainerslist-table-item-icons">
										<div class="trainerslist-table-item-icons-element" *ngFor="let userSpecialization of item.specializations">
											<img class="trainerslist-table-item-icons-element-img" matTooltip="{{userSpecialization.specialization?.name}}" src="https://api.fitbook.pro{{userSpecialization.specialization?.icon}}"/>
										</div>
									</div>
								</div>
								<div class="col-sm-12">
									<ul class="trainerslist-table-item-attrs">
										<li class="trainerslist-table-item-attrs-attr">
											<div class="trainerslist-table-item-attrs-attr-name">Trenuję:</div>
											<span>............................................................................................................................................................................................................................................................................................................................</span>
  											<div class="trainerslist-table-item-attrs-attr-value">{{item.gender_client === 'male' ? 'Mężczyzn' : (item.gender_client === 'female' ? 'Kobiety' : 'Brak preferencji')}}</div>
										</li>
										<li class="trainerslist-table-item-attrs-attr">
											<div class="trainerslist-table-item-attrs-attr-name">Układanie diety:</div>
											<span>............................................................................................................................................................................................................................................................................................................................</span>
  											<div class="trainerslist-table-item-attrs-attr-value">{{item.isDietician ? 'Tak' : 'Nie'}}</div>
										</li>
										<li class="trainerslist-table-item-attrs-attr">
											<div class="trainerslist-table-item-attrs-attr-name">Trening online:</div>
											<span>............................................................................................................................................................................................................................................................................................................................</span>
  											<div class="trainerslist-table-item-attrs-attr-value">{{item.isOnline ? 'Tak' : 'Nie'}}</div>
										</li>
										<li class="trainerslist-table-item-attrs-attr-list">
											<div class="trainerslist-table-item-attrs-attr-name">Siłownie:</div>
											<ul class="trainerslist-table-item-attrs-attr-values">
												<li *ngFor="let gym of getGyms(item)" class="trainerslist-table-item-attrs-attr-values-item">{{gym.value}}</li>
											</ul>
										</li>
									</ul>
								</div>
								<div class="col-sm-12">
									<div class="trainerslist-table-item-info-desc">{{item.short_description}}</div>
								</div>
								<div class="col-sm-12 statistics-bottom">
									<div class="trainerslist-table-item-info-reviews row">
										<div class="col-sm-6 trainerslist-table-item-info-reviews-rating"><img class="trainerslist-table-item-info-reviews-rating-icon" src="/assets/img/star.png"/>{{item.rating}} / 5</div>
										<div class="col-sm-6 trainerslist-table-item-info-reviews-count"><img class="trainerslist-table-item-info-reviews-count-icon" src="/assets/img/cloud.png"/><span><strong>{{item?.reviews?.length}}</strong> opinii</span></div>
									</div>
								</div>
								<div class="col-sm-12 buttons-bottom">
									<div class="trainerslist-table-item-info-button row">
										<div class="col-sm-6 px-1">
											<button class="trainerslist-table-item-info-button-profile" (click)="goToTrainerPage(item.id || 0)">zobacz profil</button>
										</div>
										<div class="col-sm-6 px-1">
											<button class="trainerslist-table-item-info-button-contact" (click)="messageDialogOpen(item.id || 0)">skontaktuj się</button>
										</div>
									</div>
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="col-sm-12 px-0">
			<app-page-footer></app-page-footer>
		</div>
	</div>
</div>
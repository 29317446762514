<div class="messagedialog">
	<div class="row">
		<div class="col-sm-12">
			<div class="messagedialog-content">
				<app-alert [alerts]="alerts" style="width: 100%;"></app-alert>
				<form class="messagedialog-form" [formGroup]="messageForm" (ngSubmit)="onSubmit($event)">
					<h1 class="messagedialog-form-title">
						Wyślij wiadomość
					</h1>
					<div class="col-sm-12 messagedialog-form-field-textarea">
						<label class="messagedialog-form-input-label">Treść wiadomości</label>
						<textarea [value]="this.message?.message" formControlName="message" name="message" class="messagedialog-form-input" rows="5"></textarea>
					</div>
					<div class="col-sm-12">
						<button class="messagedialog-form-button" type="submit">wyślij</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
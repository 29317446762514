import { Component, OnInit } from '@angular/core';
import { MenuElement } from 'src/app/models/menu.model';
import { CookieService } from 'ngx-cookie-service';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginService } from 'src/app/client/services/login/login.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-social-menu',
  templateUrl: './social-menu.component.html',
  styleUrls: ['./social-menu.component.scss']
})
export class SocialMenuComponent implements OnInit {

  isLogged: boolean = false;
  userData: UsersData.UserData = {};

  constructor(private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) {
	if(this.cookieService.get( 'sessionToken'))
		this.isLogged = true;
  }
	
  ngOnInit(): void {
	this.getUserData();
  }
	
  getUserData(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getUserData(token).subscribe(
	  (userData: UsersData.UserData) => {
		_self.userData = userData;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

}

<div class="container">
	<div class="row">
		<div class="col-sm-12">
			<app-page-header></app-page-header>
		</div>
		<div class="col-sm-12">
			<app-panelheader *ngIf="!userData.user?.isTrainer" [id_tab]="4"></app-panelheader>
			<div class="panelpayment">
				<app-alert [alerts]="alerts" style="width: 100%;"></app-alert>
				<div class="panelpayment-header">
					<span class="panelpayment-header-title">Płatności i rozliczenia</span>
				</div>
				<div class="panelpayment-content">
					<div class="panelpayment-content-summary row">
						<div class="panelpayment-content-summary-title col-sm-8">Obecne saldo:</div>
						<div class="panelpayment-content-summary-text col-sm-4">{{userData?.user?.balance}} zł</div>
					</div>
					<div class="panelpayment-content-payment row">
						<div class="panelpayment-content-payment-title col-sm-8">
							Aktualne koszty:
						</div>
						<div class="panelpayment-content-payment-text col-sm-4">
							{{userData?.user?.cost}} zł
						</div>
					</div>
				</div>
				<div class="panelpayment-content">
					<div class="panelpayment-content-summary row">
						<div class="panelpayment-content-summary-title col-sm-12">
							<div class="panelpayment-history-title">
								Historia salda:
							</div>
							<ul class="panelpayment-history">
								<li class="panelpayment-history-element" *ngFor="let history of histories">Data: {{history.date}} - Typ modyfikacji: {{history.name}} - Kwota: <span *ngIf="history.price >= 0" style="color: green;">+{{history.price}}zł</span><span *ngIf="history.price < 0" style="color: red;">{{history.price}}zł</span></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-sm-12 px-0">
			<app-page-footer></app-page-footer>
		</div>
	</div>
</div>
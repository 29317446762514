<div class="container">
	<div class="row">
		<div class="col-sm-12">
			<app-header></app-header>
		</div>
		<div class="col-sm-12">
			<app-content></app-content>
		</div>
	</div>
</div>

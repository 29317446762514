<div class="container">
	<div class="row">
		<div class="col-sm-12">
			<app-page-header></app-page-header>
		</div>
		<div class="col-sm-12 px-0">
			<div class="contact">
				<img class="contact-background" src="/assets/img/contact.jpg" alt="image">
				<div class="contact-content">
					<ul class="contact-content-tabs">
						<li class="contact-content-tabs-tab">
							<a href="{{socials.instagram}}" target="_blank">
								<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
									 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
									<g>
										<g>
											<path d="M352,0H160C71.648,0,0,71.648,0,160v192c0,88.352,71.648,160,160,160h192c88.352,0,160-71.648,160-160V160
												C512,71.648,440.352,0,352,0z M464,352c0,61.76-50.24,112-112,112H160c-61.76,0-112-50.24-112-112V160C48,98.24,98.24,48,160,48
												h192c61.76,0,112,50.24,112,112V352z"/>
										</g>
									</g>
									<g>
										<g>
											<path d="M256,128c-70.688,0-128,57.312-128,128s57.312,128,128,128s128-57.312,128-128S326.688,128,256,128z M256,336
												c-44.096,0-80-35.904-80-80c0-44.128,35.904-80,80-80s80,35.872,80,80C336,300.096,300.096,336,256,336z"/>
										</g>
									</g>
									<g>
										<g>
											<circle cx="393.6" cy="118.4" r="17.056"/>
										</g>
									</g>
								</svg>
							</a>
						</li>
						<li class="contact-content-tabs-tab">
							<a href="{{socials.facebook}}" target="_blank">
								<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
									 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
									<g>
										<g>
											<path d="M448,0H64C28.704,0,0,28.704,0,64v384c0,35.296,28.704,64,64,64h192V336h-64v-80h64v-64c0-53.024,42.976-96,96-96h64v80
												h-32c-17.664,0-32-1.664-32,16v64h80l-32,80h-48v176h96c35.296,0,64-28.704,64-64V64C512,28.704,483.296,0,448,0z"/>
										</g>
									</g>
								</svg>
							</a>
						</li>
					</ul>
					<app-alert [alerts]="alerts" style="width: 100%;"></app-alert>
					<form class="contact-form" [formGroup]="contactForm" (ngSubmit)="onSubmit($event)">
						<h1 class="contact-form-title">
							Kontakt
						</h1>
						<div class="col-sm-12 contact-form-field">
							<label class="contact-form-input-label">Imię<span class="contact-form-input-label-sup">*</span></label>
							<input formControlName="firstname" type="text" name="firstname" class="contact-form-input"/>
						</div>
						<div class="col-sm-12 contact-form-field">
							<label class="contact-form-input-label">Nazwisko</label>
							<input formControlName="lastname" type="text" name="lastname" class="contact-form-input"/>
						</div>
						<div class="col-sm-12 contact-form-field">
							<label class="contact-form-input-label">E-mail<span class="contact-form-input-label-sup">*</span></label>
							<input formControlName="email" type="email" name="email" class="contact-form-input"/>
						</div>
						<div class="col-sm-12 contact-form-field">
							<label class="contact-form-input-label">Telefon kontaktowy</label>
							<input formControlName="phone" type="tel" name="phone" class="contact-form-input"/>
						</div>
						<div class="col-sm-12 contact-form-field-textarea">
							<label class="contact-form-input-label">Treść wiadomości</label>
							<textarea formControlName="message" name="message" class="contact-form-input" rows="5"></textarea>
						</div>
						<div class="col-sm-12 contact-form-field">
							<re-captcha (resolved)="resolved($event)" siteKey="6LfPX6soAAAAACwz7FlOoohY4iNaCpWHXy-SMyvc"></re-captcha>
						</div>
						<div class="col-sm-12">
							<button class="contact-form-button" type="submit">wyślij<svg id="Layer_1" enable-background="new 0 0 90 90" height="512" viewBox="0 0 90 90" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m39.595 58.907 5.699 2.375-8.234 7.031zm38.219-32.347-12.201 32.519c-.877 1.772-1.373 3.31-1.928 3.896-.277.302-.502.447-1.07.547-.559.093-1.516.057-2.916-.307l-17.738-7.41zm-7.064-4.604-44.542 27.253-15.518-6.761c-.553-.327-.836-.66-.939-.889-.11-.24-.115-.379-.011-.66.214-.562 1.296-1.72 3.878-2.407.014 0 .025-.005.041-.005l55.79-16.157zm12.936-7.624c-.025 0-.051 0-.078 0-.229.016-.453.068-.662.161l-14.557 4.215-55.755 16.143c-.005 0-.005.005-.009.005l-.032.005c-3.271.883-5.559 2.463-6.41 4.723-.424 1.139-.387 2.438.114 3.535.501 1.102 1.4 1.979 2.523 2.624.063.037.126.067.192.094l15.807 6.891 3.626 11.158c.302 1.019 1.39 1.596 2.407 1.268 1.014-.333 1.557-1.429 1.197-2.432l-3.428-10.56 47.369-28.985-38.866 31.698c-.011.009-.016.015-.026.025-.434.333-.695.842-.722 1.387l-4.572 16.953c-.48 1.793 1.652 3.124 3.066 1.923l.053-.042c.011-.009.021-.02.031-.031l14.249-12.172 9.227 3.852c.078.036.156.063.236.083 1.775.474 3.254.613 4.576.39s2.449-.877 3.213-1.693c1.531-1.632 1.922-3.528 2.586-4.854.027-.062.053-.12.074-.188l16.273-43.364c0-.005 0-.005 0-.011.258-.467.299-1.023.121-1.527-.063-.177-.152-.348-.266-.499-.021-.027-.043-.052-.064-.079-.016-.025-.035-.046-.057-.066-.021-.026-.047-.052-.072-.073-.021-.021-.043-.041-.059-.063-.025-.021-.053-.041-.084-.062-.343-.286-.776-.437-1.221-.432z"/></g></svg></button>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div class="col-sm-12 px-0">
			<app-page-footer></app-page-footer>
		</div>
	</div>
</div>
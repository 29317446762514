import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';
import {MatDialog} from '@angular/material/dialog';
import { ConfirmdialogComponent } from 'src/app/client/page/components/confirmdialog/confirmdialog.component';
import { Alert } from 'src/app/models/alert.model';

@Component({
  selector: 'app-panelclientcard',
  templateUrl: './panelclientcard.component.html',
  styleUrls: ['./panelclientcard.component.scss']
})
export class PanelclientcardComponent implements OnInit {

  public sizesForm: FormGroup;
  userData: UsersData.User = {};
  serie: UsersData.Serie = {};
  alerts: Alert[] = [];
  serieStart: string = '';
  serieEnd: string = '';
  history: UsersData.UserHistory[] = [];
  reportDate: string = '00.00.0000';
  serieEndTime: number = 0;
  disabled: boolean = true;

  constructor(public dialog: MatDialog, private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) {
	this.sizesForm = new FormGroup({
      'neck': new FormControl('', []),
      'thorax': new FormControl('', []),
      'waist': new FormControl('', []),
      'stomach': new FormControl('', []),
      'hips': new FormControl('', []),
      'thighs': new FormControl('', []),
      'calves': new FormControl('', []),
      'arm': new FormControl('', []),
      'wrist': new FormControl('', []),
      'height': new FormControl('', []),
      'weight': new FormControl('', []),
      'age': new FormControl('', []),
      'gender': new FormControl('', []),
      'comment': new FormControl('', []),
      'kcal': new FormControl('', []),
      'protein': new FormControl('', []),
      'carbohydrates': new FormControl('', []),
      'fat': new FormControl('', []),
      'serie': new FormControl('', [])
    });
    if(!this.cookieService.get('sessionToken'))
        this.router.navigate(['/logowanie']);
  }

  ngOnInit(): void {
	this.getUserData();
  }

  getUserData(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken')
	this._loginService.getUserData(token).subscribe(
	  (userData: UsersData.UserData) => {
		if(!userData.idTrainer) {
	        this.router.navigate(['/panel/profil']);
		}
		_self.userData.user = userData;
		_self.changeSerie(_self.userData?.user?.defaultSerie ? _self.userData?.user?.defaultSerie : 1);
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  getHistory(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken')
	this._loginService.getHistory(token, _self.serie?.id).subscribe(
	  (history: UsersData.UserHistory[]) => {
		_self.history = history;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  sendReport(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	var currentDate = new Date();
    var message = {
		from: this.userData?.user?.id || 0,
		title: 'Raport z dnia '+currentDate.getDate()+'.'+(currentDate.getMonth()+1)+'.'+currentDate.getFullYear(),
		email: this.userData?.user?.email,
		name: this.userData?.user?.firstname+' '+this.userData?.user?.lastname,
		serieid: this.serie.id || 0,
		to: this.userData?.user?.idTrainer || 0
	};
	this.alerts = [];
	if(_self.serieEndTime <= currentDate.getTime()) {
		this._loginService.sendReport(token, message).subscribe(
		  (messageFromAPI: UsersData.MessageFromAPI) => {
			this.alerts.push({message: messageFromAPI.message || '', type: messageFromAPI.result || 'success'});
			this.getDimensions(this.serie?.number || 0);
		  },
		  (error: HttpErrorResponse) => {
			
		  },
		);
	}
	else {
		this.alerts.push({message: 'Cykl jeszcze nie został zakończony', type: 'danger'});
	}
  }

  getSerieTime(serie_number: number): number {
	for(var i = 0; i < this.serie?.serietime_list.length; i++) {
		if(this.serie?.serietime_list[i].number == serie_number)
			return this.serie?.serietime_list[i].serietime;
	}
	return 7;
  }

  getDimensions(serie_id: number): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	if(this.userData?.user?.id) {
		this._loginService.getDimensions(serie_id, this.userData.user.id, token).subscribe(
		  (serie: UsersData.Serie) => {
			_self.serie = serie;
			var numbers = Array(serie?.serietime).fill(serie?.serietime).map((x,i)=>i);
			if(_self.userData?.user?.trainingstart && serie?.number && serie?.serietime_list) {
				for(var i = 0; i < numbers.length; i++) {
					var date = new Date(_self.userData?.user?.trainingstart);
					date.setDate(date.getDate() + (i+((serie.number-1)*_self.getSerieTime(serie.number))));
					if(i == 0)
						_self.serieStart = date.getDate()+'.'+(date.getMonth()+1);
					if(i == numbers.length-1) {
						_self.serieEnd = date.getDate()+'.'+(date.getMonth()+1);
						_self.serieEndTime = date.getTime();
						_self.reportDate = date.getDate()+'.'+(date.getMonth()+1)+'.'+date.getFullYear();
					}
				}
				
			}
			_self.getHistory();
		  },
		  (error: HttpErrorResponse) => {
			
		  },
		);
	}
  }

  changeSerie(serie: number): void {
	this.sizesForm.value.serie = serie;
	this.getDimensions(serie);
  }

  onFileChanged(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
		var _self = this;
		var token = this.cookieService.get('sessionToken');
	    this._loginService.addSerieImage(reader.result+'', this.serie.id || 0, token).subscribe(
	      (message: any) => {
			if(message?.result === 'success') {
	    	  this.getDimensions(this.serie.number || 0);
			}
			else if(message?.message) {
				this.alerts.push({message: message?.message, type: 'danger'});
				window.scroll(0,0);
			}
	      },
	      (error: HttpErrorResponse) => {
			
	      },
	    );
    };
  }
  
  deleteImageSerie(id: number) {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
    var confirm_item = {
		title: "Czy na pewno chcesz usunąć zdjęcie?"
	};
  	const dialogRef = this.dialog.open(ConfirmdialogComponent, {
      data: confirm_item
    });
  	dialogRef.afterClosed().subscribe(result => {
		if(result) {
		    this._loginService.deleteImageSerie(id, token).subscribe(
		      (message: any) => {
		    	  this.getDimensions(this.serie.number || 0);
		      },
		      (error: HttpErrorResponse) => {
				
		      },
		    );
		}
    });
  }

  onSubmit(event: any): void {
  	this.alerts = [];
	if (this.sizesForm.invalid) {
		this.alerts.push({message: 'Prosimy wypełnić wszystkie wymagane pola', type: 'danger'});
		for (var key in this.sizesForm.errors) {
			this.alerts.push({message: key, type: 'danger'});
		}
	  return;
	}

    var _self = this;
	var token = this.cookieService.get('sessionToken');
	var dimensions = {
		neck: parseInt(this.sizesForm.value.neck ? this.sizesForm.value.neck : this.serie?.dimensions?.neck || 0),
		thorax: parseInt(this.sizesForm.value.thorax ? this.sizesForm.value.thorax : this.serie?.dimensions?.thorax || 0),
		waist: parseInt(this.sizesForm.value.waist ? this.sizesForm.value.waist : this.serie?.dimensions?.waist || 0),
		stomach: parseInt(this.sizesForm.value.stomach ? this.sizesForm.value.stomach : this.serie?.dimensions?.stomach || 0),
		hips: parseInt(this.sizesForm.value.hips ? this.sizesForm.value.hips : this.serie?.dimensions?.hips || 0),
		thighs: parseInt(this.sizesForm.value.thighs ? this.sizesForm.value.thighs : this.serie?.dimensions?.thighs || 0),
		calves: parseInt(this.sizesForm.value.calves ? this.sizesForm.value.calves : this.serie?.dimensions?.calves || 0),
		arm: parseInt(this.sizesForm.value.arm ? this.sizesForm.value.arm : this.serie?.dimensions?.arm || 0),
		wrist: parseInt(this.sizesForm.value.wrist ? this.sizesForm.value.wrist : this.serie?.dimensions?.wrist || 0),
		height: parseInt(this.sizesForm.value.height ? this.sizesForm.value.height : this.serie?.dimensions?.height || 0),
		weight: parseInt(this.sizesForm.value.weight ? this.sizesForm.value.weight : this.serie?.dimensions?.weight || 0),
		age: parseInt(this.sizesForm.value.age ? this.sizesForm.value.age : this.serie?.dimensions?.age || 0),
		gender: this.sizesForm.value.gender ? this.sizesForm.value.gender : this.serie?.dimensions?.gender || '',
		comment: this.sizesForm.value.comment ? this.sizesForm.value.comment : this.serie?.dimensions?.comment || '',
		serie: this.sizesForm.value.serie ? this.sizesForm.value.serie : this.serie?.dimensions?.serie || ''
	};
    this._loginService.updateDimensions(token, dimensions, this.serie?.id || 0).subscribe(
      (message: UsersData.MessageFromAPI) => {
		if(message?.result === 'success') {
			this.alerts.push({message: (message?.message ? message?.message : ''), type: 'success'});
			this.getDimensions(this.serie?.number);
			window.scroll(0,0);
		}
		else
			this.alerts.push({message: (message?.message ? message?.message : ''), type: 'danger'});
      },
      (error: HttpErrorResponse) => {
		this.alerts.push({message: 'Wystąpił błąd podczas zapisu danych', type: 'danger'});
      },
    );
  }
}

<div class="container">
	<div class="row">
		<div class="col-sm-12">
			<app-page-header></app-page-header>
		</div>
		<div class="col-sm-12">
			<div class="row content">
				<app-alert [alerts]="alerts" style="width: 100%;"></app-alert>
				<img class="login-background" src="/assets/img/logowanie.jpg" alt="image"/>
				<div class="login-text">
					<span class="login-text-logo">FITBOOK.pro </span><span class="login-text-normal">to doskonałe narzędzie<br/>dla trenerów personalnych i podopiecznych,<br/></span>
					<span class="login-text-bold">trening nigdy nie był prostszy!<br/></span>
				</div>
				<a [routerLink]="['/logowanie']" class="login-button"><span class="login-button-link">zaloguj się</span></a>
				<div class="login-content">
					<form class="login-form" [formGroup]="registerForm" (ngSubmit)="onSubmit($event)">
						<div class="login-form-title">
							<img class="login-logo" src="/assets/img/logo_logowanie.jpg" alt="image"/>
						</div>
						<div class="col-sm-12 login-form-field">
							<mat-form-field class="login-form-input" floatLabel="never">
								<span matPrefix><img class="login-form-icon" src="/assets/img/login_icon.png" alt="image"/></span>
								<input formControlName="login" type="text" name="login" matInput placeholder="e-mail">
							</mat-form-field>
						</div>
						<div class="col-sm-12 login-form-field">
							<mat-form-field class="login-form-input" floatLabel="never">
								<span matPrefix><img class="login-form-icon" src="/assets/img/password_icon.png" alt="image"/></span>
								<input formControlName="password" type="password" name="password" matInput placeholder="hasło">
							</mat-form-field>
						</div>
						<div class="col-sm-12 login-form-field">
							<mat-form-field class="login-form-input" floatLabel="never">
								<span matPrefix><img class="login-form-icon" src="/assets/img/password_icon.png" alt="image"/></span>
								<input formControlName="repeat_password" type="password" name="repeat_password" matInput placeholder="powtórz hasło">
							</mat-form-field>
						</div>
						<div class="col-sm-12 login-form-field">
							<mat-slide-toggle formControlName="isTrainer">zarejestruj jako trener</mat-slide-toggle>
						</div>
						<div class="col-sm-12 login-form-field">
							<mat-form-field class="login-form-input" floatLabel="never">
								<input formControlName="name" type="text" name="name" matInput placeholder="imię i nazwisko">
							</mat-form-field>
						</div>
						<div class="col-sm-12 login-form-field" *ngIf="this.registerForm.value.isTrainer">
							<mat-form-field class="login-form-input" floatLabel="never">
								<input formControlName="nip" type="text" name="nip" matInput placeholder="nip">
							</mat-form-field>
						</div>
						<div class="col-sm-12 login-form-field">
							<select class="login-form-input select" formControlName="state" type="text" name="state">
								<option [value]="''" [selected]="true">Województwo</option>
								<option *ngFor="let state of states" [value]="state.name">{{state.name}}</option>
							</select>
						</div>
						<div class="col-sm-12 login-form-field">
							<mat-form-field class="login-form-input" floatLabel="never">
								<input formControlName="city" type="text" name="city" matInput placeholder="miasto" [matAutocomplete]="auto" (input)="onSearchChange($event.target.value)">
								<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
							      <mat-option *ngFor="let city of cities" [value]="city.name">
								    {{city.name}}
								  </mat-option>
							    </mat-autocomplete>
							</mat-form-field>
						</div>
						<div class="col-sm-12 login-form-field">
							<mat-slide-toggle formControlName="isOnline">zajęcia online</mat-slide-toggle>
						</div>
						<div class="col-sm-12 login-form-field">
							<mat-form-field class="login-form-input" floatLabel="never">
								<input formControlName="phone" type="text" name="phone" matInput placeholder="telefon">
							</mat-form-field>
						</div>
						<div class="col-sm-12">
							<button class="login-form-button" type="submit">zarejestruj się</button>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div class="col-sm-12 px-0">
			<app-page-footer></app-page-footer>
		</div>
	</div>
</div>
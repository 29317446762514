import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { ContactComponent } from './contact/contact.component';
import { LoginComponent } from './login/login.component';
import { TrainerslistComponent } from './trainerslist/trainerslist.component';
import { ClientslistComponent } from './clientslist/clientslist.component';
import { TrainerComponent } from './trainer/trainer.component';
import { RegisterComponent } from './register/register.component';
import { PaymentsComponent } from './payments/payments.component';
import { PaneltrainerdietComponent } from './paneltrainerdiet/paneltrainerdiet.component';
import { PaneltrainerlistComponent } from './paneltrainerlist/paneltrainerlist.component';
import { ClientComponent } from './client/client.component';
import { PanelclientcardComponent } from './panelclientcard/panelclientcard.component';
import { PanelclientdietComponent } from './panelclientdiet/panelclientdiet.component';
import { PanelclienttrainingComponent } from './panelclienttraining/panelclienttraining.component';
import { PanelprofileComponent } from './panelprofile/panelprofile.component';
import { PaneltrainertrainingComponent } from './paneltrainertraining/paneltrainertraining.component';
import { PanelmessagesComponent } from './panelmessages/panelmessages.component';
import { PaneltrainercardComponent } from './paneltrainercard/paneltrainercard.component';
import { PanelpaymentComponent } from './panelpayment/panelpayment.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { PanelalertsComponent } from './panelalerts/panelalerts.component';
import { PanelpreferencesComponent } from './panelpreferences/panelpreferences.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { PaneladminComponent } from './paneladmin/paneladmin.component';
import { PaneladminprofileComponent } from './paneladminprofile/paneladminprofile.component';
import { PaneladminsocialsComponent } from './paneladminsocials/paneladminsocials.component';
import { PaneladmincontentsComponent } from './paneladmincontents/paneladmincontents.component';
import { PaneladmincontentComponent } from './paneladmincontent/paneladmincontent.component';
import { PaneladmincmslistComponent } from './paneladmincmslist/paneladmincmslist.component';
import { PaneladmincmsComponent } from './paneladmincms/paneladmincms.component';
import { PanelpasswordComponent } from './panelpassword/panelpassword.component';
import { PaneladminpaymentComponent } from './paneladminpayment/paneladminpayment.component';
import { PaneladminingredientsComponent } from './paneladminingredients/paneladminingredients.component';
import { CmsComponent } from './cms/cms.component';

const routes: Routes = [
  {
    path: 'kontakt',
    pathMatch: 'full',
    component: ContactComponent,
    data: {
      breadcrumbs: [
        {
          name: 'BREADCRUMBS_CONTACT',
          routerLinkPath: 'kontakt',
        },
      ],
    },
  },
  {
    path: 'o-nas',
    pathMatch: 'full',
    component: AboutusComponent,
    data: {
      breadcrumbs: [
        {
          name: 'BREADCRUMBS_ABOUT_US',
          routerLinkPath: 'o-nas',
        },
      ],
    },
  },
  {
    path: 'logowanie',
    pathMatch: 'full',
    component: LoginComponent,
    data: {
      breadcrumbs: [
        {
          name: 'BREADCRUMBS_LOGIN',
          routerLinkPath: 'logowanie',
        },
      ],
    },
  },
  {
    path: 'rejestracja',
    pathMatch: 'full',
    component: RegisterComponent,
    data: {
      breadcrumbs: [
        {
          name: 'BREADCRUMBS_REGISTER',
          routerLinkPath: 'rejestracja',
        },
      ],
    },
  },
  {
    path: 'bazatrenerow',
    pathMatch: 'full',
    component: TrainerslistComponent,
    data: {
      breadcrumbs: [
        {
          name: 'BREADCRUMBS_TRAINERSLIST',
          routerLinkPath: 'bazatrenerow',
        },
      ],
    },
  },
  {
    path: 'trener/:trainerid',
    pathMatch: 'full',
    component: TrainerComponent,
    data: {
      breadcrumbs: [
        {
          name: 'BREADCRUMBS_TRAINER',
          routerLinkPath: 'trener/:trainerid',
        },
      ],
    },
  },
  {
    path: 'opinie/:trainerid',
    pathMatch: 'full',
    component: ReviewsComponent,
    data: {
      breadcrumbs: [
        {
          name: 'BREADCRUMBS_TRAINER',
          routerLinkPath: 'opinie/:trainerid',
        },
      ],
    },
  },
  {
    path: 'ofertadlatrenerow',
    pathMatch: 'full',
    component: ClientslistComponent,
    data: {
      breadcrumbs: [
        {
          name: 'BREADCRUMBS_CLIENTSLIST',
          routerLinkPath: 'ofertadlatrenerow',
        },
      ],
    },
  },
  {
    path: 'cennik',
    pathMatch: 'full',
    component: PaymentsComponent,
    data: {
      breadcrumbs: [
        {
          name: 'BREADCRUMBS_PAYMENTS',
          routerLinkPath: 'cennik',
        },
      ],
    },
  },
  {
    path: 'podopieczny/:clientid',
    pathMatch: 'full',
    component: ClientComponent,
    data: {
      breadcrumbs: [
        {
          name: 'BREADCRUMBS_PAYMENTS',
          routerLinkPath: 'podopieczny/:clientid',
        },
      ],
    },
  },
  {
    path: 'panel/trener/dieta/:clientid',
    pathMatch: 'full',
    component: PaneltrainerdietComponent,
    data: {
      breadcrumbs: [
        {
          name: 'BREADCRUMBS_PANELTRAINERDIET',
          routerLinkPath: 'panel/trener/dieta/:clientid',
        },
      ],
    },
  },
  {
    path: 'panel/preferencje/:clientid',
    pathMatch: 'full',
    component: PanelpreferencesComponent,
    data: {
      breadcrumbs: [
        {
          name: 'BREADCRUMBS_PANELPREFERENCES',
          routerLinkPath: 'panel/preferencje/:clientid',
        },
      ],
    },
  },
  {
    path: 'panel/trener/lista',
    pathMatch: 'full',
    component: PaneltrainerlistComponent,
    data: {
      breadcrumbs: [
        {
          name: 'BREADCRUMBS_PANELTRAINERDIET',
          routerLinkPath: 'panel/trener/lista',
        },
      ],
    },
  },
  {
    path: 'panel/trener/trening/:clientid',
    pathMatch: 'full',
    component: PaneltrainertrainingComponent,
    data: {
      breadcrumbs: [
        {
          name: 'BREADCRUMBS_PANELTRAINERTRAINING',
          routerLinkPath: 'panel/trener/trening/:clientid',
        },
      ],
    },
  },
  {
    path: 'panel/admin/profile/:clientid',
    pathMatch: 'full',
    component: PaneladminprofileComponent,
    data: {
      breadcrumbs: [
        {
          name: 'BREADCRUMBS_PANELPROFILE',
          routerLinkPath: 'panel/admin/profile/:clientid',
        },
      ],
    },
  },
  {
    path: 'panel/admin/payment/:clientid',
    pathMatch: 'full',
    component: PaneladminpaymentComponent,
    data: {
      breadcrumbs: [
        {
          name: 'BREADCRUMBS_PANELPROFILE',
          routerLinkPath: 'panel/admin/payment/:clientid',
        },
      ],
    },
  },
  {
    path: 'panel/admin/ingredients',
    pathMatch: 'full',
    component: PaneladminingredientsComponent,
    data: {
      breadcrumbs: [
        {
          name: 'BREADCRUMBS_PANELPROFILE',
          routerLinkPath: 'panel/admin/ingredients',
        },
      ],
    },
  },
  {
    path: 'cms/:cmsid',
    pathMatch: 'full',
    component: CmsComponent,
    data: {
      breadcrumbs: [
        {
          name: 'BREADCRUMBS_PANELPROFILE',
          routerLinkPath: 'cms/:cmsid',
        },
      ],
    },
  },
  {
    path: 'panel/admin/dhn438dnx6',
    pathMatch: 'full',
    component: PanelpasswordComponent,
    data: {
      breadcrumbs: [
        {
          name: 'BREADCRUMBS_PANELPROFILE',
          routerLinkPath: 'panel/admin/dhn438dnx6',
        },
      ],
    },
  },
  {
    path: 'panel/admin/cms/:cmsid',
    pathMatch: 'full',
    component: PaneladmincmsComponent,
    data: {
      breadcrumbs: [
        {
          name: 'BREADCRUMBS_PANELPROFILE',
          routerLinkPath: 'panel/admin/cms/:cmsid',
        },
      ],
    },
  },
  {
    path: 'panel/admin/cms',
    pathMatch: 'full',
    component: PaneladmincmsComponent,
    data: {
      breadcrumbs: [
        {
          name: 'BREADCRUMBS_PANELPROFILE',
          routerLinkPath: 'panel/admin/cms',
        },
      ],
    },
  },
  {
    path: 'panel/admin/cmslist',
    pathMatch: 'full',
    component: PaneladmincmslistComponent,
    data: {
      breadcrumbs: [
        {
          name: 'BREADCRUMBS_PANELPROFILE',
          routerLinkPath: 'panel/admin/cmslist',
        },
      ],
    },
  },
  {
    path: 'panel/admin/content/:contentid',
    pathMatch: 'full',
    component: PaneladmincontentComponent,
    data: {
      breadcrumbs: [
        {
          name: 'BREADCRUMBS_PANELPROFILE',
          routerLinkPath: 'panel/admin/content/:contentid',
        },
      ],
    },
  },
  {
    path: 'panel/admin/contents',
    pathMatch: 'full',
    component: PaneladmincontentsComponent,
    data: {
      breadcrumbs: [
        {
          name: 'BREADCRUMBS_PANELPROFILE',
          routerLinkPath: 'panel/admin/contents',
        },
      ],
    },
  },
  {
    path: 'panel/admin/socials',
    pathMatch: 'full',
    component: PaneladminsocialsComponent,
    data: {
      breadcrumbs: [
        {
          name: 'BREADCRUMBS_PANELPROFILE',
          routerLinkPath: 'panel/admin/socials',
        },
      ],
    },
  },
  {
    path: 'panel/admin',
    pathMatch: 'full',
    component: PaneladminComponent,
    data: {
      breadcrumbs: [
        {
          name: 'BREADCRUMBS_PANELPROFILE',
          routerLinkPath: 'panel/admin',
        },
      ],
    },
  },
  {
    path: 'panel/profil',
    pathMatch: 'full',
    component: PanelprofileComponent,
    data: {
      breadcrumbs: [
        {
          name: 'BREADCRUMBS_PANELPROFILE',
          routerLinkPath: 'panel/profil',
        },
      ],
    },
  },
  {
    path: 'panel/poczta',
    pathMatch: 'full',
    component: PanelmessagesComponent,
    data: {
      breadcrumbs: [
        {
          name: 'BREADCRUMBS_PANELMESSAGES',
          routerLinkPath: 'panel/poczta',
        },
      ],
    },
  },
  {
    path: 'panel/powiadomienia',
    pathMatch: 'full',
    component: PanelalertsComponent,
    data: {
      breadcrumbs: [
        {
          name: 'BREADCRUMBS_PANELALERTS',
          routerLinkPath: 'panel/powiadomienia',
        },
      ],
    },
  },
  {
    path: 'panel/platnosci',
    pathMatch: 'full',
    component: PanelpaymentComponent
  },
  {
    path: 'panel/trener/karta/:clientid',
    pathMatch: 'full',
    component: PaneltrainercardComponent
  },
  {
    path: 'panel/podopieczny/mojakarta',
    pathMatch: 'full',
    component: PanelclientcardComponent
  },
  {
    path: 'panel/podopieczny/dieta',
    pathMatch: 'full',
    component: PanelclientdietComponent
  },
  {
    path: 'panel/podopieczny/trening',
    pathMatch: 'full',
    component: PanelclienttrainingComponent
  },
  {
      path: '404',
      pathMatch: 'full',
      component: NotFoundComponent,
      data: {
          breadcrumbs: [
              {
                  name: 'BREADCRUMBS_NOT_FOUND',
                  routerLinkPath: '404',
              },
          ],
      },
  },
  {
    path: '**',
    redirectTo: '404'
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PageRouting {}

import { Component, OnInit } from '@angular/core';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';
import { MessagedialogComponent } from '../components/messagedialog/messagedialog.component';
import {MatDialog} from '@angular/material/dialog';
import { Alert } from 'src/app/models/alert.model';

@Component({
  selector: 'app-panelmessages',
  templateUrl: './panelmessages.component.html',
  styleUrls: ['./panelmessages.component.scss']
})
export class PanelmessagesComponent implements OnInit {

  userData: UsersData.User = {};
  messages: UsersData.Invite[] = [];
  activeMessage: UsersData.Invite = {};
  alerts: Alert[] = [];

  constructor(public dialog: MatDialog, private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) {
    if(!this.cookieService.get('sessionToken'))
        this.router.navigate(['/logowanie']);
  }

  ngOnInit(): void {
	this.getUserData();
  }

  getUserData(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getUserData(token).subscribe(
	  (userData: UsersData.UserData) => {
		_self.userData.user = userData;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
	this.getMessages();
  }
  
  getMessages() {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getMessages(token).subscribe(
	  (messages: UsersData.Invite[]) => {
		_self.messages = messages;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  acceptInvite(activeMessage: UsersData.Invite) {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.acceptInvite(token, activeMessage).subscribe(
	  (messageFromAPI: UsersData.MessageFromAPI) => {
		if(messageFromAPI.result === 'success') {
			_self.activeMessage.isAccepted = true;
			_self.alerts = [];
			_self.alerts.push({message: 'Zaproszenie zostało zaakceptowane.', type: 'success'});
			window.scroll(0,0);
		}
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }
  
  deleteMessage(activeMessage: UsersData.Invite) {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.deleteMessage(activeMessage?.id || 0, token).subscribe(
	  (messageFromAPI: UsersData.MessageFromAPI) => {
		  this.activeMessage = {};
		  this.getMessages();
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }
  
  replyMessage() {
	this.messageDialogOpen();
  }

  messageDialogOpen() {
    var toId = this.activeMessage?.toId;
	if(this.userData.user.id == this.activeMessage?.toId)
	    toId = this.activeMessage?.fromId;
		
    var message = {
		to: toId,
		from: this.userData.user.id,
		replay: this.activeMessage?.id,
    	message: '---------\n'+this.activeMessage?.message
	};
  	const dialogRef = this.dialog.open(MessagedialogComponent, {
      data: message
    });
	var _self = this;
  	dialogRef.afterClosed().subscribe(result => {
  		if(result) {
			_self.alerts = [];
			_self.alerts.push({message: 'Wiadomość została wysłana', type: 'success'});
			window.scroll(0,0);
			_self.activeMessage = result;
  		}
    });
  }

  readMessage(message: UsersData.Invite) {
	this.activeMessage = message;
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.readMessage(token, this.activeMessage?.id || 0).subscribe(
	  (messageFromAPI: UsersData.MessageFromAPI) => {
		this.getMessages();
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }
}

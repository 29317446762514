<div class="container">
	<div class="row">
		<div class="col-sm-12">
			<app-page-header></app-page-header>
		</div>
		<div class="col-sm-12">
			<app-panelheader [id_tab]="1"></app-panelheader>
			<div class="panelclientcard-column row">
				<app-alert [alerts]="alerts" style="width: 100%;"></app-alert>
				<div class="panelclientcard-column-header col-sm-12">
					<div class="panelclientcard-column-header-list-item">
						<div class="panelclientcard-column-header-list-item-button">Cykl <b>{{serie?.number}}</b> ({{serieStart}} -  {{serieEnd}})</div>
						<div class="panelclientcard-column-header-list-item-background"></div>
					</div>
					<div class="panelclientcard-column-header-list-report">
						<div class="panelclientcard-column-header-list-report-notsend">
							<div class="panelclientcard-column-header-list-report-notsend-label">Raport:</div>
							<div class="panelclientcard-column-header-list-report-notsend-value" *ngIf="!serie?.sendReport">
								<svg height="365.696pt" viewBox="0 0 365.696 365.696" width="365.696pt" xmlns="http://www.w3.org/2000/svg"><path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0"/></svg>
								<span>niedostarczony</span>
							</div>
							<div class="panelclientcard-column-header-list-report-send-value" *ngIf="serie?.sendReport">
								<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
									 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
									<g>
										<g>
											<path d="M416,16c-88.637,95.933-151.68,187.167-215.936,306.749C160.573,271.324,128.799,237.757,96,208H0
												c74.947,87.68,115.267,154.911,192,288h64c61.44-167.521,117.443-285.507,256-480H416z"/>
										</g>
									</g>
								</svg>
								<span>dostarczony</span>
							</div>
						</div>
					</div>
					<div class="panelclientcard-column-header-list-date">
						<div class="panelclientcard-column-header-list-date-report">
							<div class="panelclientcard-column-header-list-date-report-label">Termin raport:</div>
							<div class="panelclientcard-column-header-list-date-report-value">{{reportDate}}</div>
						</div>
					</div>
					<div class="panelclientcard-column-header-list-action">
						<button class="panelclientcard-column-header-list-action-send" (click)="sendReport()">
							Wyślij raport
							<svg class="panelclientcard-column-header-list-action-send-icon" id="_x31__x2C_5_px" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m20.5 24c-1.93 0-3.5-1.57-3.5-3.5v-5.25c0-1.24 1.01-2.25 2.25-2.25s2.25 1.01 2.25 2.25v5c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-5c0-.413-.337-.75-.75-.75s-.75.337-.75.75v5.25c0 1.103.897 2 2 2s2-.897 2-2v-6.75c0-.414.336-.75.75-.75s.75.336.75.75v6.75c0 1.93-1.57 3.5-3.5 3.5z"/><path d="m14.81 22h-12.06c-1.517 0-2.75-1.233-2.75-2.75v-16.5c0-1.517 1.233-2.75 2.75-2.75h12.5c1.517 0 2.75 1.233 2.75 2.75v8.48c0 .414-.336.75-.75.75s-.75-.336-.75-.75v-8.48c0-.689-.561-1.25-1.25-1.25h-12.5c-.689 0-1.25.561-1.25 1.25v16.5c0 .689.561 1.25 1.25 1.25h12.06c.414 0 .75.336.75.75s-.336.75-.75.75z"/><path d="m14.25 15h-10.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h10.5c.414 0 .75.336.75.75s-.336.75-.75.75z"/><path d="m14.25 11h-10.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h10.5c.414 0 .75.336.75.75s-.336.75-.75.75z"/><path d="m9.25 7h-5.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h5.5c.414 0 .75.336.75.75s-.336.75-.75.75z"/></svg>
							<svg class="panelclientcard-column-header-list-action-send-arrow" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
								viewBox="0 0 512.002 512.002" style="enable-background:new 0 0 512.002 512.002;" xml:space="preserve">
								<g>
									<g>
										<path d="M388.425,241.951L151.609,5.79c-7.759-7.733-20.321-7.72-28.067,0.04c-7.74,7.759-7.72,20.328,0.04,28.067l222.72,222.105
											L123.574,478.106c-7.759,7.74-7.779,20.301-0.04,28.061c3.883,3.89,8.97,5.835,14.057,5.835c5.074,0,10.141-1.932,14.017-5.795
											l236.817-236.155c3.737-3.718,5.834-8.778,5.834-14.05S392.156,245.676,388.425,241.951z"/>
									</g>
								</g>
							</svg>
						</button>
					</div>
				</div>
				<div class="panelclientcard-column-left col-sm-12 col-xl-3">
					<ul class="panelclientcard-column-left-list">
						<li class="panelclientcard-column-left-list-item" *ngFor="let item of this.serie?.serietime_list | slice:0:5" [ngClass]="{'active': serie?.number === item?.number}">
							<button class="panelclientcard-column-left-list-item-button" (click)="changeSerie(item?.number)">Cykl <b>{{item?.number}}</b></button>
							<div class="panelclientcard-column-left-list-item-background"></div>
						</li>
					</ul>
					<ul class="panelclientcard-column-left-tabs row">
						<li class="panelclientcard-column-left-tabs-tab col-sm-4" *ngFor="let item of this.serie?.serietime_list | slice:0:5" [ngClass]="{'active': serie?.number === item?.number}"><a (click)="changeSerie(item?.number)">Cykl {{item?.number}}</a></li>
					</ul>
					<div class="row panelclientcard-column-left-tabs-content-serie-images">
						<div *ngFor="let image of serie?.images;" class="col-sm-6 px-0" [ngClass]="{'d-none': image.isDeleted}">
							<div *ngIf="!image.isDeleted" class="panelclientcard-column-left-tabs-content-serie-images-image">
								<a href="https://api.fitbook.pro/upload/{{image?.img}}" target="_blank"><img class="panelclientcard-column-left-tabs-content-serie-images-image-img" src="https://api.fitbook.pro/upload/{{image?.imgThumb}}" /></a>
								<a class="panelclientcard-column-left-tabs-content-serie-images-image-delete" (click)="deleteImageSerie((image.id || 0))">
									<svg width="512px" height="512px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><title>ionicons-v5-e</title><path d="M296,64H216a7.91,7.91,0,0,0-8,8V96h96V72A7.91,7.91,0,0,0,296,64Z" style="fill:none"/><path d="M432,96H336V72a40,40,0,0,0-40-40H216a40,40,0,0,0-40,40V96H80a16,16,0,0,0,0,32H97L116,432.92c1.42,26.85,22,47.08,48,47.08H348c26.13,0,46.3-19.78,48-47L415,128h17a16,16,0,0,0,0-32ZM192.57,416H192a16,16,0,0,1-16-15.43l-8-224a16,16,0,1,1,32-1.14l8,224A16,16,0,0,1,192.57,416ZM272,400a16,16,0,0,1-32,0V176a16,16,0,0,1,32,0ZM304,96H208V72a7.91,7.91,0,0,1,8-8h80a7.91,7.91,0,0,1,8,8Zm32,304.57A16,16,0,0,1,320,416h-.58A16,16,0,0,1,304,399.43l8-224a16,16,0,1,1,32,1.14Z"/></svg>
								</a>
							</div>
						</div>
					</div>
					<div class="col-sm-12">
						<input type="file" (change)="onFileChanged($event)" style="display: none;" #fileInput>
						<button class="panelclientcard-column-left-tabs-content-button" (click)="fileInput.click()">Dodaj zdjęcie</button>
					</div>
				</div>
				<div class="panelclientcard-column-center col-sm-12 col-xl-6">
					<form class="row" [formGroup]="sizesForm" (ngSubmit)="onSubmit($event)">
						<div class="panelclientcard-column-center-first col-sm-12 col-xl-3">
							<h3>Wymiary (cm)</h3>
							<label class="panelclientcard-column-center-label">Obwód karku</label>
							<div class="panelclientcard-column-center-input-box">
								<input formControlName="neck" type="text" [value]="serie?.dimensions?.neck">
							</div>
							<label class="panelclientcard-column-center-label">Obwód klatki piersiowej</label>
							<div class="panelclientcard-column-center-input-box">
								<input formControlName="thorax" type="text" [value]="serie?.dimensions?.thorax">
							</div>
							<label class="panelclientcard-column-center-label">Obwód talii</label>
							<div class="panelclientcard-column-center-input-box">
								<input formControlName="waist" type="text" [value]="serie?.dimensions?.waist">
							</div>
							<label class="panelclientcard-column-center-label">Obwód brzucha</label>
							<div class="panelclientcard-column-center-input-box">
								<input formControlName="stomach" type="text" [value]="serie?.dimensions?.stomach">
							</div>
							<label class="panelclientcard-column-center-label">Obwód bioder</label>
							<div class="panelclientcard-column-center-input-box">
								<input formControlName="hips" type="text" [value]="serie?.dimensions?.hips">
							</div>
							<label class="panelclientcard-column-center-label">Obwód uda</label>
							<div class="panelclientcard-column-center-input-box">
								<input formControlName="thighs" type="text" [value]="serie?.dimensions?.thighs">
							</div>
							<label class="panelclientcard-column-center-label">Obwód łydki</label>
							<div class="panelclientcard-column-center-input-box">
								<input formControlName="calves" type="text" [value]="serie?.dimensions?.calves">
							</div>
							<label class="panelclientcard-column-center-label">Obwód ramienia</label>
							<div class="panelclientcard-column-center-input-box">
								<input formControlName="arm" type="text" [value]="serie?.dimensions?.arm">
							</div>
							<label class="panelclientcard-column-center-label">Obwód nadgarstka</label>
							<div class="panelclientcard-column-center-input-box">
								<input formControlName="wrist" type="text" [value]="serie?.dimensions?.wrist">
							</div>
						</div>
						<div class="panelclientcard-column-center-second col-sm-12 col-xl-6">
							<img *ngIf="!userData?.user?.figure?.img" src="/assets/img/figure_male_1.svg" />
							<img *ngIf="userData?.user?.figure?.img" src="{{userData?.user?.figure?.img}}"/>
						</div>
						<div class="panelclientcard-column-center-third col-sm-12 col-xl-3">
							<label class="panelclientcard-column-center-label">Wzrost (cm)</label>
							<div class="panelclientcard-column-center-input-box">
								<input formControlName="height" type="text" [value]="serie?.dimensions?.height">
							</div>
							<label class="panelclientcard-column-center-label">Waga (kg)</label>
							<div class="panelclientcard-column-center-input-box">
								<input formControlName="weight" type="text" [value]="serie?.dimensions?.weight">
							</div>
							<label class="panelclientcard-column-center-label">Wiek (lat(a))</label>
							<div class="panelclientcard-column-center-input-box">
								<input formControlName="age" type="text" [value]="serie?.dimensions?.age">
							</div>
							<label class="panelclientcard-column-center-label">Płeć</label>
							<select formControlName="gender" class="panelclientcard-column-center-select-box">
								<option id="male" [value]="'male'" [selected]="(serie?.dimensions?.gender && serie?.dimensions?.gender === 'male') || (!serie?.dimensions?.gender && userData?.user?.gender === 'male')">Mężczyzna</option>
								<option id="female" [value]="'female'" [selected]="(serie?.dimensions?.gender && serie?.dimensions?.gender === 'female') || (!serie?.dimensions?.gender && userData?.user?.gender === 'female')">Kobieta</option>
							</select>
							<div class="panelclientcard-column-center-attrs">
								<div class="panelclientcard-column-center-attrs-icon" matTooltip="k - kcal&#13;b - białko&#13;w - węglowodany&#13;t - tłuszcze" [matTooltipClass]="'my-tooltip'">
									<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
									 	viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001 512.001;" xml:space="preserve">
										<g>
											<g>
												<g>
													<path d="M252.692,139.985c-47.057,0-68.668,27.89-68.668,46.715c0,13.597,11.502,19.872,20.914,19.872
														c18.822,0,11.154-26.844,46.708-26.844c17.429,0,31.372,7.671,31.372,23.707c0,18.826-19.52,29.633-31.023,39.394
														c-10.108,8.716-23.354,23.01-23.354,52.991c0,18.128,4.879,23.357,19.171,23.357c17.079,0,20.565-7.669,20.565-14.293
														c0-18.129,0.349-28.588,19.52-43.578c9.412-7.321,39.04-31.028,39.04-63.798S297.308,139.986,252.692,139.985z"/>
													<path d="M248.16,343.232c-14.639,0-26.491,12.202-26.491,26.844c0,14.293,11.503,26.844,26.491,26.844
														c14.988,0,26.84-12.55,26.84-26.844C275,355.434,262.799,343.232,248.16,343.232z"/>
													<path d="M447.717,97.795L297.208,10.994c-25.417-14.659-56.997-14.659-82.414,0L64.285,97.795
														c-25.465,14.686-41.284,42.072-41.284,71.472v173.467c0,29.399,15.819,56.786,41.284,71.472l150.509,86.801
														c25.412,14.657,56.993,14.661,82.414,0l150.509-86.801c25.465-14.687,41.284-42.073,41.284-71.473V169.267
														C489.001,139.867,473.182,112.481,447.717,97.795z M449.001,342.733c0,15.144-8.148,29.25-21.266,36.815l-150.509,86.801
														c-13.093,7.55-29.358,7.55-42.451,0l-150.508-86.8c-13.118-7.565-21.266-21.672-21.266-36.816V169.267
														c0-15.144,8.148-29.25,21.266-36.815l150.509-86.801c13.095-7.551,29.358-7.55,42.451,0l150.509,86.801
														c13.117,7.564,21.265,21.671,21.265,36.815V342.733z"/>
												</g>
											</g>
										</g>
									</svg>
								</div>
								<div class="panelclientcard-column-center-attrs-box">
									<input formControlName="kcal" type="text" [value]="serie?.dimensions?.kcal" [readonly]="disabled">
									<input formControlName="protein" type="text" [value]="serie?.dimensions?.protein" [readonly]="disabled">
									<input formControlName="carbohydrates" type="text" [value]="serie?.dimensions?.carbohydrates" [readonly]="disabled">
									<input formControlName="fat" type="text" [value]="serie?.dimensions?.fat" [readonly]="disabled">
								</div>
								<div class="panelclientcard-column-center-attrs-units">
									<span class="panelclientcard-column-center-attrs-units-item">k</span>
									<span class="panelclientcard-column-center-attrs-units-item">b</span>
									<span class="panelclientcard-column-center-attrs-units-item">w</span>
									<span class="panelclientcard-column-center-attrs-units-item">t</span>
								</div>
							</div>
							<label class="panelclientcard-column-center-label">Komentarz dla trenera</label>
							<div class="panelclientcard-column-center-textarea-box">
								<textarea formControlName="comment" [value]="serie?.dimensions?.comment"></textarea>
							</div>
						</div>
						<button class="panelclientcard-column-center-button">Zapisz zmiany</button>
					</form>
				</div>
				<div class="panelclientcard-column-right col-sm-12 col-xl-3">
					<div class="panelclientcard-column-right-action">
						<a class="panelclientcard-column-right-action-preferences" [routerLink]="['/panel/preferencje/'+(userData.user?.id || 0)]">Moje preferencje</a>
						<a class="panelclientcard-column-right-action-profile" [routerLink]="['/panel/profil']">Mój profil</a>
					</div>
					<div class="panelclientcard-column-right-history">
						<div class="panelclientcard-column-right-history-title"><b>Cykl {{serie?.number}}</b> - Historia zmian statusu</div>
						<ul class="panelclientcard-column-right-history-list">
							<li class="panelclientcard-column-right-history-list-item row" *ngFor="let item of history">
								<div class="panelclientcard-column-right-history-list-item-text col-sm-8 col-xl-10"><span><b *ngIf="item.history?.isFromTrainer">Trener</b><b *ngIf="!item.history?.isFromTrainer">Podopieczny</b> {{item.history?.name}}</span></div>
								<div class="panelclientcard-column-right-history-list-item-svg col-sm-4 col-xl-2"><svg id="Layer_1" enable-background="new 0 0 128 128" height="512" viewBox="0 0 128 128" width="512" xmlns="http://www.w3.org/2000/svg"><path id="External_Link" d="m124 8v36c0 2.211-1.789 4-4 4s-4-1.789-4-4v-26.344l-57.172 57.172c-.781.781-1.805 1.172-2.828 1.172s-2.047-.391-2.828-1.172c-1.563-1.563-1.563-4.094 0-5.656l57.172-57.172h-26.344c-2.211 0-4-1.789-4-4s1.789-4 4-4h36c2.211 0 4 1.789 4 4zm-16 104v-48c0-2.211-1.789-4-4-4s-4 1.789-4 4v48c0 2.207-1.793 4-4 4h-80c-2.207 0-4-1.793-4-4v-80c0-2.207 1.793-4 4-4h48c2.211 0 4-1.789 4-4s-1.789-4-4-4h-48c-6.617 0-12 5.383-12 12v80c0 6.617 5.383 12 12 12h80c6.617 0 12-5.383 12-12z"/></svg></div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="col-sm-12 px-0">
			<app-page-footer></app-page-footer>
		</div>
	</div>
</div>
<div class="header__menu">
    <ul class="menu">
      <li class="menu__element" *ngFor="let element of menuElements">
        <a
          [routerLink]="element.url"
          class="menu__element-anchor"
          >{{ element.name }}</a
        >
      </li>
	</ul>
</div>

<div class="menu-mobile">
  <button [matMenuTriggerFor]="menu" #myMenu="matMenuTrigger" class="menu-mobile__button">
    <svg xmlns="http://www.w3.org/2000/svg" width="33.619" height="10.582" viewBox="0 0 33.619 10.582" mat-button>
      <g id="Group_497" data-name="Group 497" transform="translate(-4711.453 1553.293)">
        <g id="Group_182" data-name="Group 182" transform="translate(4713.453 -1551.293)">
          <circle id="Ellipse_62" data-name="Ellipse 62" cx="3.291" cy="3.291" r="3.291" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
          <circle id="Ellipse_63" data-name="Ellipse 63" cx="3.291" cy="3.291" r="3.291" transform="translate(11.518)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
          <circle id="Ellipse_64" data-name="Ellipse 64" cx="3.291" cy="3.291" r="3.291" transform="translate(23.037)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
        </g>
      </g>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none"  width="40" height="40" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
      <line x1="18" x2="6" y1="6" y2="18"/>
      <line x1="6" x2="18" y1="6" y2="18"/>
    </svg>
  </button>
	<mat-menu #menu="matMenu" class="custom-menu" [class.open] = "myMenu.menuOpen">
		<a class="menu-button" mat-menu-item [routerLink]="element.url" *ngFor="let element of menuElements">{{ element.name }}</a>
		<a class="menu-button" mat-menu-item [routerLink]="['/bazatrenerow']" *ngIf="isLogged">Baza trenerów</a>
		<a class="menu-button" mat-menu-item [routerLink]="['/ofertadlatrenerow']" *ngIf="isLogged">Oferta dla trenerów</a>
		<a class="menu-button" mat-menu-item [routerLink]="['/logowanie']" *ngIf="!isLogged">Baza trenerów</a>
		<a class="menu-button" mat-menu-item [routerLink]="['/logowanie']" *ngIf="!isLogged">Oferta dla trenerów</a>
		<a class="menu-button" mat-menu-item [routerLink]="['/logowanie']">Zaloguj mnie <mat-icon></mat-icon></a>
	</mat-menu>
</div>

import { Component, OnInit } from '@angular/core';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';
import {MatDialog} from '@angular/material/dialog';
import { ConfirmdialogComponent } from 'src/app/client/page/components/confirmdialog/confirmdialog.component';
import { MessagedialogComponent } from '../components/messagedialog/messagedialog.component';
import { Alert } from 'src/app/models/alert.model';

@Component({
  selector: 'app-paneltrainerlist',
  templateUrl: './paneltrainerlist.component.html',
  styleUrls: ['./paneltrainerlist.component.scss']
})
export class PaneltrainerlistComponent implements OnInit {
	
  alerts: Alert[] = [];
  userData: UsersData.User = {};
  clients: UsersData.UserData[] = [];
  countAlerts: number = 0;
  countMessages: number = 0;

  constructor(public dialog: MatDialog, private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) {
  	if(!this.cookieService.get('sessionToken'))
		this.router.navigate(['/logowanie']);
  }

  ngOnInit(): void {
	this.getUserData();
	this.getAlertsCount();
  }

  getUserData(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getUserData(token).subscribe(
	  (userData: UsersData.UserData) => {
		_self.userData.user = userData;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
	this._loginService.getClients(token).subscribe(
	  (clients: UsersData.UserData[]) => {
		_self.clients = clients;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  goToClientSettings(client_id: number|undefined) {
	this.router.navigate(['panel/trener/karta/'+client_id]);
  }

  messageDialogOpen(clientid: number) {
    var message = {
		from: this.userData?.user?.id || 0,
		email: this.userData?.user?.email,
		name: this.userData?.user?.firstname+' '+this.userData?.user?.lastname,
		to: clientid
	};
  	const dialogRef = this.dialog.open(MessagedialogComponent, {
      data: message
    });
	var _self = this;
  	dialogRef.afterClosed().subscribe(result => {
  		if(result) {
			_self.alerts = [];
			_self.alerts.push({message: 'Wiadomość została wysłana', type: 'success'});
			window.scroll(0,0);
  		}
    });
  }

  deleteClient(id_client: number) {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
    var confirm_item = {
		title: "Czy na pewno chcesz usunąć podopiecznego ?"
	};
  	const dialogRef = this.dialog.open(ConfirmdialogComponent, {
      data: confirm_item
    });
  	dialogRef.afterClosed().subscribe(result => {
		if(result) {
			this._loginService.deleteUserFromTrainer(token, id_client).subscribe(
			  (messageFromAPI: UsersData.MessageFromAPI) => {
    			window.location.reload();
			  },
			  (error: HttpErrorResponse) => {
				
			  },
			);
		}
    });
  }

  getAlertsCount(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getAlertsCount(token).subscribe(
	  (count: UsersData.Count) => {
		_self.countAlerts = count.countAlerts || 0;
		_self.countMessages = count.countMessages || 0;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }
}

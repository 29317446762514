import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Alert } from 'src/app/models/alert.model';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';

@Component({
  selector: 'app-practicedialog',
  templateUrl: './practicedialog.component.html',
  styleUrls: ['./practicedialog.component.scss']
})
export class PracticedialogComponent implements OnInit {

  public practiceForm: FormGroup;
  practice: UsersData.Practice;

  constructor(private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService, public dialogRef: MatDialogRef<PracticedialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UsersData.Practice) {
    this.practice = data;
    this.practiceForm = new FormGroup({
      'name': new FormControl('', [
        
      ])
    });
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit(event: any): void {
    var _self = this;
    this.practice.name = this.practiceForm.value.name;
	if(typeof this.practice.serienumber !== 'undefined' && typeof this.practice.clientid !== 'undefined' && typeof this.practice.name !== 'undefined' && typeof this.practice.seriedate !== 'undefined' && typeof this.practice.position !== 'undefined')
	    this._loginService.addPractice(this.practice.serienumber, this.practice.clientid, this.cookieService.get( 'sessionToken'), this.practice.name, this.practice.seriedate, this.practice.position).subscribe(
	      (messageFromAPI: UsersData.MessageFromAPI) => {
			this.dialogRef.close();
	      },
	      (error: HttpErrorResponse) => {
	
	      },
	    );
  }
}

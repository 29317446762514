<div class="container">
	<div class="row">
		<div class="col-sm-12 px-0">
			<app-page-header></app-page-header>
		</div>
		<div class="col-sm-12 px-0">
			<app-panelheader [id_tab]="2" [id_client]="clientid"></app-panelheader>
		</div>
		<div class="col-sm-12">
			<form class="paneltrainerdiet row" (keydown.enter)="$event.preventDefault()" (ngSubmit)="onSubmit($event)" cdkDropListGroup>
				<app-alert [alerts]="alerts" style="width: 100%;"></app-alert>
				<div class="col-sm-12 col-xl-4">
					<div class="paneltrainerdiet-column row">
						<div class="col-sm-5">
							<ul class="paneltrainerdiet-column-tabs">
								<li class="paneltrainerdiet-column-tabs-tab col-sm-6" *ngFor="let item of tabs" (click)="changeSerie(item?.number)"><span class="paneltrainerdiet-column-tabs-tab-title">Cykl {{item?.number}} ({{getSeriesDatesItem(item?.number)}})</span><span class="paneltrainerdiet-column-tabs-tab-date"></span></li>
							</ul>
						</div>
						<div class="col-sm-7">
							<div class="row">
								<div class="paneltrainerdiet-column-box">
									<div class="paneltrainerdiet-column-box-title">Cykl {{serie.number}} ({{serieStart}} - {{serieEnd}})</div>
									<div class="paneltrainerdiet-column-box-content">
										<select [(ngModel)]="typeOfMeal" [ngModelOptions]="{standalone: true}" (change)="change()" class="paneltrainerdiet-column-box-content-select">
											<option [value]="1">Śniadanie</option>
											<option [value]="2">Obiad</option>
											<option [value]="3">Kolacja</option>
											<option [value]="4">Przekąska</option>
											<option [value]="5">Przed Treningiem</option>
											<option [value]="6">Po Treningu</option>
										</select>
										<button class="paneltrainerdiet-column-box-content-ingredients-add" type="button" (click)="addIngredient()"><svg id="Capa_1" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><g id="_x33_"><path d="m18 2c2.206 0 4 1.794 4 4v12c0 2.206-1.794 4-4 4h-12c-2.206 0-4-1.794-4-4v-12c0-2.206 1.794-4 4-4zm0-2h-12c-3.314 0-6 2.686-6 6v12c0 3.314 2.686 6 6 6h12c3.314 0 6-2.686 6-6v-12c0-3.314-2.686-6-6-6z"/></g><g id="_x32_"><path d="m12 18c-.552 0-1-.447-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10c0 .553-.448 1-1 1z"/></g><g id="_x31_"><path d="m6 12c0-.552.447-1 1-1h10c.552 0 1 .448 1 1s-.448 1-1 1h-10c-.553 0-1-.448-1-1z"/></g></svg><span>Dodaj składnik ręcznie</span></button>
										<select class="paneltrainerdiet-column-box-content-ingredients-select" [(ngModel)]="selectedIngredientData" [ngModelOptions]="{standalone: true}" (ngModelChange)="addIngredientFromList($event)">
											<option [value]="0">Wybierz składnik z bazy</option>
											<option *ngFor="let adminIngredient of adminIngredients; let adminIngredient_index = index;" [value]="adminIngredient_index+1">{{adminIngredient?.name}}</option>
										</select>
										<ul class="paneltrainerdiet-column-box-content-ingredients col-sm-12" cdkDropList [cdkDropListData]="ingredients" (cdkDropListDropped)="drop($event)">
											<li class="paneltrainerdiet-column-box-content-ingredients-item row" *ngFor="let ingredient of ingredients" cdkDrag>
												<div class="paneltrainerdiet-column-box-content-ingredients-item-desc col-sm-8 col-xl-9">
													<div class="paneltrainerdiet-column-box-content-ingredients-item-desc-name">{{ingredient.name}}</div>
													<div class="paneltrainerdiet-column-box-content-ingredients-item-desc-list">
														<div class="paneltrainerdiet-column-box-content-ingredients-item-desc-list-element"><b>k</b>-{{ingredient.kcal}}</div>
														<div class="paneltrainerdiet-column-box-content-ingredients-item-desc-list-element"><b>b</b>-{{ingredient.protein}}</div>
														<div class="paneltrainerdiet-column-box-content-ingredients-item-desc-list-element"><b>w</b>-{{ingredient.carbohydrates}}</div>
														<div class="paneltrainerdiet-column-box-content-ingredients-item-desc-list-element"><b>t</b>-{{ingredient.fat}}</div>
													</div>
												</div>
												<div class="paneltrainerdiet-column-box-content-ingredients-item-field col-sm-4 col-xl-3">
													<input type="text" class="paneltrainerdiet-column-box-content-ingredients-item-field-input" value="{{ingredient.amount}}" [readonly]="true"/>
													<span class="paneltrainerdiet-column-box-content-ingredients-item-field-label">{{ingredient.unit}}</span>
												</div>
												<a class="paneltrainerdiet-column-box-content-ingredients-item-field-delete" (click)="deleteAdminIngredient((ingredient.id || 0))">
													<svg width="512px" height="512px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><title>ionicons-v5-e</title><path d="M296,64H216a7.91,7.91,0,0,0-8,8V96h96V72A7.91,7.91,0,0,0,296,64Z" style="fill:none"/><path d="M432,96H336V72a40,40,0,0,0-40-40H216a40,40,0,0,0-40,40V96H80a16,16,0,0,0,0,32H97L116,432.92c1.42,26.85,22,47.08,48,47.08H348c26.13,0,46.3-19.78,48-47L415,128h17a16,16,0,0,0,0-32ZM192.57,416H192a16,16,0,0,1-16-15.43l-8-224a16,16,0,1,1,32-1.14l8,224A16,16,0,0,1,192.57,416ZM272,400a16,16,0,0,1-32,0V176a16,16,0,0,1,32,0ZM304,96H208V72a7.91,7.91,0,0,1,8-8h80a7.91,7.91,0,0,1,8,8Zm32,304.57A16,16,0,0,1,320,416h-.58A16,16,0,0,1,304,399.43l8-224a16,16,0,1,1,32,1.14Z"/></svg>
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="paneltrainerdiet-column-box-action">
									<button class="paneltrainerdiet-column-box-action-clear" (click)="clearDiet()">wyczyść cykl</button>
									<button class="paneltrainerdiet-column-box-action-copy" (click)="copyDiet()">skopiuj cykl</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="paneltrainerdiet-content-form col-sm-12 col-xl-8">
					<carousel class="paneltrainerdiet-content-form-calendar" [cellWidth]="width" [height]="920">
						<li class="carousel-cell" *ngFor="let date of listOfDate; let i = index;">
							<div class="paneltrainerdiet-content-form-calendar-date">
								<span class="paneltrainerdiet-content-form-calendar-date-title">{{date}}</span>
								<ul class="paneltrainerdiet-content-form-calendar-date-list">
									<li class="paneltrainerdiet-content-form-calendar-date-list-item" *ngFor="let meal of getMealsForSerieDate(i); let meal_index = index;">
										<div class="paneltrainerdiet-content-form-calendar-date-list-item-title">
											<select (change)="itemChange((meal.id ? meal.id : 0), $event.target.value)" class="paneltrainerdiet-content-form-calendar-date-list-item-title-select">
												<option [value]="1" [selected]="meal.type == 1">Śniadanie</option>
												<option [value]="2" [selected]="meal.type == 2">Obiad</option>
												<option [value]="3" [selected]="meal.type == 3">Kolacja</option>
												<option [value]="4" [selected]="meal.type == 4">Przekąska</option>
												<option [value]="5" [selected]="meal.type == 5">Przed Treningiem</option>
												<option [value]="6" [selected]="meal.type == 6">Po Treningu</option>
											</select>
											<span class="paneltrainerdiet-content-form-calendar-date-list-item-title-time" (click)="editMeal(meal)"><svg id="Capa_1" enable-background="new 0 0 359.286 359.286" height="512" viewBox="0 0 359.286 359.286" width="512" xmlns="http://www.w3.org/2000/svg"><g><g><path d="m179.643 359.286c99.043 0 179.643-80.6 179.643-179.643s-80.599-179.643-179.643-179.643-179.643 80.6-179.643 179.643 80.6 179.643 179.643 179.643zm0-335.334c85.869 0 155.691 69.821 155.691 155.691s-69.821 155.691-155.691 155.691-155.691-69.821-155.691-155.691 69.822-155.691 155.691-155.691z"/><path d="m232.039 236.89c2.216 1.796 4.85 2.635 7.485 2.635 3.533 0 7.006-1.557 9.341-4.491 4.132-5.15 3.293-12.695-1.856-16.827l-55.39-44.312v-90.061c0-6.587-5.389-11.976-11.976-11.976s-11.976 5.389-11.976 11.976v95.81c0 3.653 1.677 7.066 4.491 9.341z"/></g></g></svg><span>{{meal.time}}</span></span>
										</div>
										<ul class="paneltrainerdiet-content-form-calendar-date-list-item-series" *ngIf="meal.ingredients" cdkDropList [cdkDropListData]="meal.ingredients" (cdkDropListDropped)="drop($event)">
											<li class="paneltrainerdiet-column-box-content-ingredients-item row" *ngFor="let ingredient of meal.ingredients">
												<div class="paneltrainerdiet-column-box-content-ingredients-item-desc col-sm-8 col-xl-9">
													<div class="paneltrainerdiet-column-box-content-ingredients-item-desc-name">{{ingredient?.ingredient?.name || ingredient?.name}}</div>
													<div class="paneltrainerdiet-column-box-content-ingredients-item-desc-list">
														<div class="paneltrainerdiet-column-box-content-ingredients-item-desc-list-element"><b>k</b>-{{ingredient.kcal}}</div>
														<div class="paneltrainerdiet-column-box-content-ingredients-item-desc-list-element"><b>b</b>-{{ingredient.protein}}</div>
														<div class="paneltrainerdiet-column-box-content-ingredients-item-desc-list-element"><b>w</b>-{{ingredient.carbohydrates}}</div>
														<div class="paneltrainerdiet-column-box-content-ingredients-item-desc-list-element"><b>t</b>-{{ingredient.fat}}</div>
													</div>
												</div>
												<div class="paneltrainerdiet-column-box-content-ingredients-item-field col-sm-4 col-xl-3">
													<input type="text" [(ngModel)]="ingredient.amount" (change)="saveChanges()" [ngModelOptions]="{standalone: true}" class="paneltrainerdiet-column-box-content-ingredients-item-field-input" value="{{ingredient.amount}}"/>
													<span class="paneltrainerdiet-column-box-content-ingredients-item-field-label">{{ingredient?.ingredient?.unit || ingredient?.unit}}</span>
												</div>
												<a class="paneltrainerdiet-column-box-content-ingredients-item-field-delete" (click)="deleteIngredient(meal_index, (ingredient.id || 0), i)">
													<svg width="512px" height="512px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><title>ionicons-v5-e</title><path d="M296,64H216a7.91,7.91,0,0,0-8,8V96h96V72A7.91,7.91,0,0,0,296,64Z" style="fill:none"/><path d="M432,96H336V72a40,40,0,0,0-40-40H216a40,40,0,0,0-40,40V96H80a16,16,0,0,0,0,32H97L116,432.92c1.42,26.85,22,47.08,48,47.08H348c26.13,0,46.3-19.78,48-47L415,128h17a16,16,0,0,0,0-32ZM192.57,416H192a16,16,0,0,1-16-15.43l-8-224a16,16,0,1,1,32-1.14l8,224A16,16,0,0,1,192.57,416ZM272,400a16,16,0,0,1-32,0V176a16,16,0,0,1,32,0ZM304,96H208V72a7.91,7.91,0,0,1,8-8h80a7.91,7.91,0,0,1,8,8Zm32,304.57A16,16,0,0,1,320,416h-.58A16,16,0,0,1,304,399.43l8-224a16,16,0,1,1,32,1.14Z"/></svg>
												</a>
											</li>
										</ul>
										<div class="paneltrainerdiet-content-form-calendar-date-list-item-ingredients">
											<label class="paneltrainerdiet-content-form-calendar-date-list-item-ingredients-label">Razem:</label>
											<div class="paneltrainerdiet-content-form-calendar-date-list-item-ingredients-list">
												<div class="paneltrainerdiet-content-form-calendar-date-list-item-ingredients-list-element"><b>k</b>-{{getForMeal(meal, 1)}}</div>
												<div class="paneltrainerdiet-content-form-calendar-date-list-item-ingredients-list-element"><b>b</b>-{{getForMeal(meal, 2)}}</div>
												<div class="paneltrainerdiet-content-form-calendar-date-list-item-ingredients-list-element"><b>w</b>-{{getForMeal(meal, 3)}}</div>
												<div class="paneltrainerdiet-content-form-calendar-date-list-item-ingredients-list-element"><b>t</b>-{{getForMeal(meal, 4)}}</div>
											</div>
										</div>
									</li>
									<li class="paneltrainerdiet-content-form-calendar-date-list-item empty" *ngFor="let empty of [].constructor(getMealsForSerieDateEmptyCount(i))">
										<button class="paneltrainerdiet-content-form-calendar-date-list-item-button" (click)="addMeal(i)">+</button>
									</li>
								</ul>
							</div>
							<div class="paneltrainerdiet-content-form-calendar-date-box">
								<button class="paneltrainerdiet-content-form-calendar-date-box-add" (click)="addMeal(i)">+</button>
								<div class="paneltrainerdiet-content-form-calendar-date-box-item">
									<label class="paneltrainerdiet-content-form-calendar-date-box-item-label">Podsumowanie:</label>
									<div class="paneltrainerdiet-content-form-calendar-date-box-item-list">
										<div class="paneltrainerdiet-content-form-calendar-date-box-item-list-element"><b>k</b>-{{getForMeals(getMealsForSerieDate(i), 1)}}</div>
										<div class="paneltrainerdiet-content-form-calendar-date-box-item-list-element"><b>b</b>-{{getForMeals(getMealsForSerieDate(i), 2)}}</div>
										<div class="paneltrainerdiet-content-form-calendar-date-box-item-list-element"><b>w</b>-{{getForMeals(getMealsForSerieDate(i), 3)}}</div>
										<div class="paneltrainerdiet-content-form-calendar-date-box-item-list-element"><b>t</b>-{{getForMeals(getMealsForSerieDate(i), 4)}}</div>
									</div>
								</div>
							</div>
						</li>
					</carousel>
				</div>
				<button type="button" class="paneltrainerdiet-footer-form-button" (click)="saveChanges()">Zapisz zmiany</button>
			</form>
		</div>
		<div class="col-sm-12 px-0">
			<app-page-footer></app-page-footer>
		</div>
	</div>
</div>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PageContentComponent } from './components/page-content/page-content.component';
import { PageFooterComponent } from './components/page-footer/page-footer.component';
import { MainComponent } from './main/main.component';
import { TranslateModule } from '@ngx-translate/core';
import { PageRouting } from './page.routing';
import { NotFoundComponent } from './not-found/not-found.component';
import { SearcherComponent } from './components/searcher/searcher.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {A11yModule} from '@angular/cdk/a11y';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {OverlayModule} from '@angular/cdk/overlay';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SubmenuComponent } from './components/submenu/submenu.component';
import { ContactComponent } from './contact/contact.component';
import { MenuComponent } from './components/menu/menu.component';
import { LoginComponent } from './login/login.component';
import { TrainerslistComponent } from './trainerslist/trainerslist.component';
import { ClientslistComponent } from './clientslist/clientslist.component';
import { TrainerComponent } from './trainer/trainer.component';
import { RegisterComponent } from './register/register.component';
import { AlertComponent } from './components/alert/alert.component';
import { PanelheaderComponent } from './components/panelheader/panelheader.component';
import { PaneltrainerdietComponent } from './paneltrainerdiet/paneltrainerdiet.component';
import { SocialIconsComponent } from './components/social-icons/social-icons.component';
import { PaymentsComponent } from './payments/payments.component';
import { PaneltrainerlistComponent } from './paneltrainerlist/paneltrainerlist.component';
import { ClientComponent } from './client/client.component';
import { PanelclientcardComponent } from './panelclientcard/panelclientcard.component';
import { PanelclientdietComponent } from './panelclientdiet/panelclientdiet.component';
import { PanelclienttrainingComponent } from './panelclienttraining/panelclienttraining.component';
import { InvitedialogComponent } from './components/invitedialog/invitedialog.component';
import { PanelprofileComponent } from './panelprofile/panelprofile.component';
import { PaneltrainertrainingComponent } from './paneltrainertraining/paneltrainertraining.component';
import { PanelmessagesComponent } from './panelmessages/panelmessages.component';
import { PaneltrainercardComponent } from './paneltrainercard/paneltrainercard.component';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import { PracticedialogComponent } from './components/practicedialog/practicedialog.component';
import { TrainingseriedialogComponent } from './components/trainingseriedialog/trainingseriedialog.component';
import { SupplementdialogComponent } from './components/supplementdialog/supplementdialog.component';
import { ConfirmdialogComponent } from './components/confirmdialog/confirmdialog.component';
import { IngredientdialogComponent } from './components/ingredientdialog/ingredientdialog.component';
import { MealdialogComponent } from './components/mealdialog/mealdialog.component';
import { FiltersComponent } from './components/filters/filters.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MessagedialogComponent } from './components/messagedialog/messagedialog.component';
import { ReviewdialogComponent } from './components/reviewdialog/reviewdialog.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GymdialogComponent } from './components/gymdialog/gymdialog.component';
import { PanelpaymentComponent } from './panelpayment/panelpayment.component';
import { CopyseriedialogComponent } from './components/copyseriedialog/copyseriedialog.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { RecaptchaModule } from "ng-recaptcha";
import { PanelalertsComponent } from './panelalerts/panelalerts.component';
import { PanelpreferencesComponent } from './panelpreferences/panelpreferences.component';
import { CopydietdialogComponent } from './components/copydietdialog/copydietdialog.component';
import { FiguredialogComponent } from './components/figuredialog/figuredialog.component';
import { QualificationdialogComponent } from './components/qualificationdialog/qualificationdialog.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { SeriedatedialogComponent } from './components/seriedatedialog/seriedatedialog.component';
import { PaneladminComponent } from './paneladmin/paneladmin.component';
import { PaneladminprofileComponent } from './paneladminprofile/paneladminprofile.component';
import { PaneladmincontentsComponent } from './paneladmincontents/paneladmincontents.component';
import { PaneladmincontentComponent } from './paneladmincontent/paneladmincontent.component';
import { PaneladminsocialsComponent } from './paneladminsocials/paneladminsocials.component';
import { PaneladminsocialComponent } from './paneladminsocial/paneladminsocial.component';
import { PaneladmincmslistComponent } from './paneladmincmslist/paneladmincmslist.component';
import { PaneladmincmsComponent } from './paneladmincms/paneladmincms.component';
import { HttpClientModule} from '@angular/common/http';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CmsComponent } from './cms/cms.component';
import { PanelpasswordComponent } from './panelpassword/panelpassword.component';
import { PaneladminpaymentComponent } from './paneladminpayment/paneladminpayment.component';
import { PaneladminingredientsComponent } from './paneladminingredients/paneladminingredients.component';
import { IngredientadmindialogComponent } from './components/ingredientadmindialog/ingredientadmindialog.component';

const modules = [
    A11yModule,
    ClipboardModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    OverlayModule,
    PortalModule,
    ScrollingModule,
    FormsModule,
    ReactiveFormsModule,
	NgxSliderModule,
	NgbModule,
	RecaptchaModule
];
@NgModule({
  exports: [
    ...modules
  ],
  declarations: [
    PageHeaderComponent,
    PageContentComponent,
    PageFooterComponent,
    MainComponent,
    NotFoundComponent,
    SearcherComponent,
    SubmenuComponent,
    ContactComponent,
    MenuComponent,
    LoginComponent,
    TrainerslistComponent,
    ClientslistComponent,
    TrainerComponent,
    RegisterComponent,
	AlertComponent,
	PanelheaderComponent,
	PaneltrainerdietComponent,
 SocialIconsComponent,
 PaymentsComponent,
 PaneltrainerlistComponent,
 ClientComponent,
 PanelclientcardComponent,
 PanelclientdietComponent,
 PanelclienttrainingComponent,
 InvitedialogComponent,
 PanelprofileComponent,
 PaneltrainertrainingComponent,
 PanelmessagesComponent,
 PaneltrainercardComponent,
 PracticedialogComponent,
 TrainingseriedialogComponent,
 SupplementdialogComponent,
 ConfirmdialogComponent,
 IngredientdialogComponent,
 MealdialogComponent,
 FiltersComponent,
 MessagedialogComponent,
 ReviewdialogComponent,
 GymdialogComponent,
 PanelpaymentComponent,
 CopyseriedialogComponent,
 AboutusComponent,
 PanelalertsComponent,
 PanelpreferencesComponent,
 CopydietdialogComponent,
 FiguredialogComponent,
 QualificationdialogComponent,
 ReviewsComponent,
 SeriedatedialogComponent,
 PaneladminComponent,
 PaneladminprofileComponent,
 PaneladmincontentsComponent,
 PaneladmincontentComponent,
 PaneladminsocialsComponent,
 PaneladminsocialComponent,
 PaneladmincmslistComponent,
 PaneladmincmsComponent,
 CmsComponent,
 PanelpasswordComponent,
 PaneladminpaymentComponent,
 PaneladminingredientsComponent,
 IngredientadmindialogComponent
  ],
  imports: [
    CommonModule,
    PageRouting,
    TranslateModule,
	IvyCarouselModule,
	HttpClientModule,
	AngularEditorModule,
    ...modules
  ],
  providers: [],
  bootstrap: [MainComponent,PanelheaderComponent]
})
export class PageModule { }

import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Alert } from 'src/app/models/alert.model';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';

@Component({
  selector: 'app-gymdialog',
  templateUrl: './gymdialog.component.html',
  styleUrls: ['./gymdialog.component.scss']
})
export class GymdialogComponent implements OnInit {

  
  public gymForm: FormGroup;
  gym: UsersData.AttributeValue;
  alerts: Alert[] = [];

  constructor(private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService, public dialogRef: MatDialogRef<GymdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UsersData.AttributeValue) {
    this.gym = data;
    this.gymForm = new FormGroup({
      'value': new FormControl(this.gym?.value, [
        Validators.required
      ]),
      'secondValue': new FormControl(this.gym?.secondValue, [
        Validators.required
      ])
    });
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit(event: any): void {
  	this.alerts = [];
	if (this.gymForm.invalid) {
		let formErrors = this.getErrors(this.gymForm);
		if(formErrors?.value && formErrors?.value?.required)
			this.alerts.push({message: 'Prosimy wypełnić wszystkie pola', type: 'danger'});
		else if(formErrors?.secondValue && formErrors?.secondValue?.required)
			this.alerts.push({message: 'Prosimy wypełnić wszystkie pola', type: 'danger'});
	  return;
	}
    var _self = this;
    this.gym.value = this.gymForm.value.value;
    this.gym.secondValue = this.gymForm.value.secondValue;
    this._loginService.addAttributeValue(this.gym, this.cookieService.get( 'sessionToken')).subscribe(
      (messageFromAPI: UsersData.MessageFromAPI) => {
		//this.message = message;
		this.closeDialog();
      },
      (error: HttpErrorResponse) => {

      },
    );
  }

  getErrors = (formGroup: FormGroup, errors: any = {}) => {
	  Object.keys(formGroup.controls).forEach(field => {
	    const control = formGroup.get(field);
	    if (control instanceof FormControl) {
	      errors[field] = control.errors;
	    } else if (control instanceof FormGroup) {
	      errors[field] = this.getErrors(control);
	    }
	  });
	  return errors;
  }

}

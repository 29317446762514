import { Component, OnInit } from '@angular/core';
import {FormControl} from '@angular/forms';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';

@Component({
  selector: 'app-searcher',
  templateUrl: './searcher.component.html',
  styleUrls: ['./searcher.component.scss']
})
export class SearcherComponent implements OnInit {
  myControl = new FormControl();
  options: string[] = [];
  searchTimeout: any;
  trainers: number = 0;
  clients: number = 0;
  searchText: string = '';
  searchType: boolean = true;
  isLogged: boolean = false;

  constructor(private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) {
	var _self = this;
	this.activatedRoute.queryParams.subscribe(params => {
		if(typeof params['isTrainer'] !== 'undefined')
	      _self.searchType = (params['isTrainer'] !== 'false' ? true : false);
    });
    if(this.cookieService.get( 'sessionToken')) {
		this.isLogged = true;
	}
  }

  ngOnInit(): void {
  }

  onSubmit(event: any): void {
	var params = {query: this.searchText, isTrainer: this.searchType};
	if(this.isLogged) {
		if(this.searchType)
			this.router.navigate(['/bazatrenerow'], {queryParams: params});
		else
			this.router.navigate(['/ofertadlatrenerow'], {queryParams: params});
	}
	else {
		this.router.navigate(['/logowanie']);
	}
  }

  /*onChange() {
	clearTimeout(this.searchTimeout);
	var _self = this;
	this.searchTimeout = setTimeout(function() {
		if(_self.searchText && _self.searchText.length > 0)
			_self.getUsersList(_self.searchText);
	}, 1000);
  }*/

  /*getUsersList(value: string): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken')
	var filters = {query: value};
	this._loginService.getUsersList(filters, token).subscribe(
	  (search: UsersData.Search) => {
		_self.trainers = (search?.trainers || []).length;
		_self.clients = (search?.clients || []).length;
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }*/

}

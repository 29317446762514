<div class="mealdialog">
	<div class="row">
		<div class="col-sm-12">
			<div class="mealdialog-content">
				<form class="mealdialog-form" [formGroup]="mealForm" (ngSubmit)="onSubmit($event)">
					<h1 class="mealdialog-form-title">
						Dodaj posiłek
					</h1>
					<div class="col-sm-12 mealdialog-form-field-textarea">
						<label class="mealdialog-form-input-label">Godzina</label>
						<input formControlName="time" name="time" type="time" class="mealdialog-form-input">
					</div>
					<div class="col-sm-12 mealdialog-form-field-textarea">
						<label class="mealdialog-form-input-label">Rodzaj posiłku</label>
						<select formControlName="type" class="mealdialog-form-select">
							<option [value]="1">Śniadanie</option>
							<option [value]="2">Obiad</option>
							<option [value]="3">Kolacja</option>
							<option [value]="4">Przekąska</option>
							<option [value]="5">Przed Treningiem</option>
							<option [value]="6">Po Treningu</option>
						</select>
					</div>
					<div class="col-sm-12">
						<button *ngIf="!meal?.id" class="mealdialog-form-button" type="submit">dodaj</button>
						<button *ngIf="meal?.id" class="mealdialog-form-button" type="submit">zapisz</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
import { Component, OnInit } from '@angular/core';
import { UsersData } from 'src/app/models/users.namespace';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginService } from 'src/app/client/services/login/login.service';
import { Alert } from 'src/app/models/alert.model';
import {MatDialog} from '@angular/material/dialog';
import { MessagedialogComponent } from '../components/messagedialog/messagedialog.component';

@Component({
  selector: 'app-clientslist',
  templateUrl: './clientslist.component.html',
  styleUrls: ['./clientslist.component.scss']
})
export class ClientslistComponent implements OnInit {

  clients: UsersData.UserData[] = [];
  filters: UsersData.Filters = {
  	ageMin: 0,
  	ageMax: 100,
	preferredAgeMin: 0,
	preferredAgeMax: 100
  };
  pages: number = 1;
  currentPage: number = 1;
  limit: number = 6;
  alerts: Alert[] = [];
  
  constructor(public dialog: MatDialog, private _loginService: LoginService, private activatedRoute: ActivatedRoute, private router: Router, private cookieService: CookieService) {
	this.router.routeReuseStrategy.shouldReuseRoute = () => false;
	var _self = this;
    this.activatedRoute.queryParams.subscribe(params => {
		if(typeof params['query'] !== 'undefined')
	      _self.filters.query = params['query'];
    });
	_self.filters.isTrainer = false;
  }

  ngOnInit(): void {
	this.getUsersList();
  }
  
  goToClientPage(clientid: number) {
	this.router.navigate(['/podopieczny/'+clientid]);
  }

  getRandomInt(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  getUsersList(): void {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	this._loginService.getUsersList(_self.filters, token, _self.currentPage, _self.limit).subscribe(
	  (search: UsersData.Search) => {
		_self.clients = search?.items || [];
		_self.pages = search?.pages || 0;
		if(_self.clients.length == 0) {
			_self.alerts = [];
			_self.alerts.push({message: 'Nie znaleziono podopiecznych.', type: 'info'});
		}
	  },
	  (error: HttpErrorResponse) => {
		
	  },
	);
  }

  getPlaces(attributes?: UsersData.AttributeValue[]): string {
	var places = [];
	if(attributes) {
		for(var i = 0; i < attributes.length; i++) {
			if(attributes[i].attribute?.id == 2) {
				places.push(attributes[i].value);
			}
		}
		return places.join(', ');
	}
	else {
		return '';
	}
  }

  getGyms(attributes?: UsersData.AttributeValue[]): string {
	var gyms = [];
	if(attributes) {
		for(var i = 0; i < attributes.length; i++) {
			if(attributes[i].attribute?.id == 1) {
				gyms.push(attributes[i].value);
			}
		}
		return gyms.join(', ');
	}
	else {
		return '';
	}
  }

  onChangeOrder(value: any) {
	this.currentPage = 1;
	this.filters.sortBy = value;
	this.changeFilters(this.filters);
  }

  changeFilters(filters: UsersData.Filters) {
	var _self = this;
	var token = this.cookieService.get('sessionToken');
	filters.isTrainer = false;
	this.filters = filters;
	this.getUsersList();
  }
  
  changePage(page: number) {
	  this.currentPage = page;
	  this.getUsersList();
  }
}

<div class="container">
	<div class="row">
		<div class="col-sm-12">
			<app-page-header></app-page-header>
		</div>
		<div class="col-sm-12">
			<div class="panelprofile">
				<app-alert [alerts]="alerts" style="width: 100%;"></app-alert>
				<form class="panelprofile-form" [formGroup]="profileForm" (ngSubmit)="onSubmit($event)">
					<div class="panelprofile-form-header">
						<div class="panelprofile-form-header-title">
							Dane podstawowe
						</div>
						<div class="panelprofile-form-header-content">
							<div class="row">
								<div class="panelprofile-form-header-content-photo col-sm-12 col-xl-4 photo">
									<img class="panelprofile-form-header-content-photo-img" *ngIf="userData.user?.photo" src="{{userData.user?.photo}}"/>
									<img class="panelprofile-form-header-content-photo-img" *ngIf="!userData.user?.photo" src="/assets/img/default_img.svg"/>
								</div>
								<div class="panelprofile-form-header-content-info-box col-sm-12 col-xl-8">
									<div class="panelprofile-form-header-content-info row">
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-3">
											<label class="panelprofile-form-header-content-info-field-label">Imię <sup style="color: red;">*wymagane</sup></label>
											<input [attr.disabled]="true" formControlName="firstname" class="panelprofile-form-header-content-info-field-input" type="text" value="{{userData.user?.firstname}}">
										</div>
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-3">
											<label class="panelprofile-form-header-content-info-field-label">Nazwisko <sup style="color: red;">*wymagane</sup></label>
											<input [attr.disabled]="true" formControlName="lastname" class="panelprofile-form-header-content-info-field-input" type="text" value="{{userData.user?.lastname}}">
										</div>
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-3">
											<label class="panelprofile-form-header-content-info-field-label">Płeć</label>
											<select [attr.disabled]="true" formControlName="gender" class="panelprofile-form-header-content-info-field-input">
												<option [ngValue]="'male'" [selected]="userData.user?.gender === 'male'">Mężczyzna</option>
												<option [ngValue]="'female'" [selected]="userData.user?.gender === 'female'">Kobieta</option>
											</select>
										</div>
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-3">
											<label class="panelprofile-form-header-content-info-field-label">Data urodzin</label>
											<input [attr.disabled]="true" formControlName="birthday" class="panelprofile-form-header-content-info-field-input" type="date" value="{{userData.user?.birthday}}">
										</div>
									</div>
									<div class="panelprofile-form-header-content-info row">
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-3">
											<label class="panelprofile-form-header-content-info-field-label">Województwo</label>
											<select [attr.disabled]="true" class="panelprofile-form-header-content-info-field-input" [value]="this.userData.user?.address?.state" formControlName="state" type="text" name="state">
												<option *ngFor="let state of states" [value]="state.name" [selected]="state.name === this.userData.user?.address?.state">{{state.name}}</option>
											</select>
										</div>
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-3">
											<label class="panelprofile-form-header-content-info-field-label">Miasto</label>
											<mat-form-field class="login-form-input" floatLabel="never">
												<input [attr.disabled]="true" formControlName="city" type="text" value="{{userData.user?.address?.city}}" name="city" matInput placeholder="miasto">
											</mat-form-field>
										</div>
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-3">
											<label *ngIf="!userData?.user?.isTrainer" class="panelprofile-form-header-content-info-field-label">Preferencje płci trenera</label>
											<label *ngIf="userData?.user?.isTrainer" class="panelprofile-form-header-content-info-field-label">Preferencje płci podopiecznego</label>
											<select [attr.disabled]="true" formControlName="gender_client" class="panelprofile-form-header-content-info-field-input">
												<option [ngValue]="''" [selected]="!userData.user?.gender_client"></option>
												<option [ngValue]="'male'" [selected]="userData.user?.gender_client === 'male'">Mężczyzna</option>
												<option [ngValue]="'female'" [selected]="userData.user?.gender_client === 'female'">Kobieta</option>
											</select>
										</div>
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-3">
											<label class="panelprofile-form-header-content-info-field-label">Trening online</label>
											<select [attr.disabled]="true" formControlName="isOnline" class="panelprofile-form-header-content-info-field-input">
												<option [ngValue]="true" [selected]="userData.user?.isOnline">Tak</option>
												<option [ngValue]="false" [selected]="!userData.user?.isOnline">Nie</option>
											</select>
										</div>
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-3" *ngIf="userData?.user?.isTrainer">
											<label class="panelprofile-form-header-content-info-field-label">Układanie diety</label>
											<select [attr.disabled]="true" formControlName="isDietician" class="panelprofile-form-header-content-info-field-input">
												<option [ngValue]="true" [selected]="userData.user?.isDietician">Tak</option>
												<option [ngValue]="false" [selected]="!userData.user?.isDietician">Nie</option>
											</select>
										</div>
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-3">
											<label class="panelprofile-form-header-content-info-field-label">Wyświetl profil w serwisie</label>
											<select [attr.disabled]="true" formControlName="displayAsClient" class="panelprofile-form-header-content-info-field-input">
												<option [ngValue]="true" [selected]="userData.user?.displayAsClient">Tak</option>
												<option [ngValue]="false" [selected]="!userData.user?.displayAsClient">Nie</option>
											</select>
										</div>
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-3">
											<label *ngIf="!userData?.user?.isTrainer" class="panelprofile-form-header-content-info-field-label">Preferowany wiek trenera:</label>
											<label *ngIf="userData?.user?.isTrainer" class="panelprofile-form-header-content-info-field-label">Preferowany wiek podopiecznego:</label>
											<ngx-slider [(value)]="preferredAgeMin" [(highValue)]="preferredAgeMax" [options]="options"></ngx-slider>
											<!-- <input formControlName="preferredAge" class="panelprofile-form-header-content-info-field-input" type="text" value="{{userData.user?.preferredAge}}"> -->
										</div>
									</div>
									<div class="panelprofile-form-header-content-info row">
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-3">
											<label class="panelprofile-form-header-content-info-field-label">Telefon</label>
											<input [attr.disabled]="true" formControlName="phone" class="panelprofile-form-header-content-info-field-input" type="text" value="{{userData.user?.phone}}">
										</div>
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-3">
											<label class="panelprofile-form-header-content-info-field-label">E-mail</label>
											<input [attr.disabled]="true" formControlName="email" class="panelprofile-form-header-content-info-field-input" type="text" value="{{(userData.user?.email ? userData.user?.email : userData.user?.login)}}">
										</div>
									</div>
								</div>
							</div>
						</div>
					</div><div class="panelprofile-form-header">
						<div class="panelprofile-form-header-title">
							Dane dodatkowe
						</div>
						<div class="panelprofile-form-header-content">
							<div class="row">
								<div class="panelprofile-form-header-content-info-box col-sm-12 col-xl-6">
									<div class="panelprofile-form-header-content-info row">
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-4">
											<label *ngIf="!userData?.user?.isTrainer" class="panelprofile-form-header-content-info-field-label">Obszar, który chcę usprawnić:</label>
											<label *ngIf="userData?.user?.isTrainer" class="panelprofile-form-header-content-info-field-label">Specjalizacja:</label>
											<ul class="panelprofile-form-header-content-info-field-list" *ngIf="userData?.user?.specializations">
												<li class="panelprofile-form-header-content-info-field-list-item" *ngFor="let object of userData?.user?.specializations">
													<span>{{object?.specialization?.name}}</span><button class="panelprofile-form-header-content-info-field-list-item-button" type="button" (click)="deleteSpecialization(object?.id || 0)"><svg height="365.696pt" viewBox="0 0 365.696 365.696" width="365.696pt" xmlns="http://www.w3.org/2000/svg"><path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0"/></svg></button>
												</li>
											</ul>
										</div>
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-4">
											<label class="panelprofile-form-header-content-info-field-label">Miejsca, w których trenuję</label>
											<ul class="panelprofile-form-header-content-info-field-list">
												<li class="panelprofile-form-header-content-info-field-list-item" *ngFor="let place of getPlaces()">
													<span>{{place.value}}</span><button class="panelprofile-form-header-content-info-field-list-item-button" type="button" (click)="deleteAttr(place.id)"><svg height="365.696pt" viewBox="0 0 365.696 365.696" width="365.696pt" xmlns="http://www.w3.org/2000/svg"><path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0"/></svg></button>
												</li>
											</ul>
										</div>
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-4">
											<label class="panelprofile-form-header-content-info-field-label">Preferowane siłownie</label>
											<ul class="panelprofile-form-header-content-info-field-list">
												<li class="panelprofile-form-header-content-info-field-list-item" *ngFor="let gym of getGyms()">
													<span>{{gym.value}}</span><button class="panelprofile-form-header-content-info-field-list-item-button" type="button" (click)="deleteAttr(gym.id)"><svg height="365.696pt" viewBox="0 0 365.696 365.696" width="365.696pt" xmlns="http://www.w3.org/2000/svg"><path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0"/></svg></button>
												</li>
											</ul>
										</div>
										<div class="panelprofile-form-header-content-info-field col-xs-12 col-sm-6 col-md-4 col-xl-4" *ngIf="userData?.user?.isTrainer">
											<label class="panelprofile-form-header-content-info-field-label">Kwalifikacje</label>
											<ul class="panelprofile-form-header-content-info-field-list">
												<li class="panelprofile-form-header-content-info-field-list-item" *ngFor="let qualification of userData.user?.qualifications">
													<span>{{qualification.name}}</span><button class="panelprofile-form-header-content-info-field-list-item-button" type="button" (click)="deleteQualification(qualification.id)"><svg height="365.696pt" viewBox="0 0 365.696 365.696" width="365.696pt" xmlns="http://www.w3.org/2000/svg"><path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0"/></svg></button>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="panelprofile-form-header-content-info-box col-sm-12">
									<div class="panelprofile-form-header-content-info row">
										<div class="panelprofile-form-header-content-info-field col-sm-12 description">
											<label class="panelprofile-form-header-content-info-field-label">Dodaj swój opis <sup style="color: red;">*wymagane</sup></label>
											<textarea [attr.disabled]="true" [value]="userData.user?.short_description" formControlName="short_description" class="panelprofile-form-header-content-info-field-textarea"></textarea>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
		<div class="col-sm-12 px-0">
			<app-page-footer></app-page-footer>
		</div>
	</div>
</div>
<div class="row content mainpage" *ngIf="contents && contents.length > 0">
	<div class="content-element-left position-relative col-12 col-md-6 text-left justify-content-end">
		<img class="d-none d-md-block img-responsive-background w-md-100" src="{{contents[1].photo}}" alt="image">
		<div class="col-12 col-md-8 col-xl-6 offset-xl-6">
			<div class="content-element-left-text" [innerHTML]="contents[1].content">
			</div>
		</div>
	</div>

	<div class="content-element-right position-relative col-12 col-md-6 text-right justify-content-end">
		<img class="d-none d-md-block img-responsive-background w-md-100" src="{{contents[2].photo}}" alt="image">
		<div class="col-12 col-md-8 col-xl-6 -offset-xl-6 mw-440">
			<div class="content-element-right-text" [innerHTML]="contents[2].content">
			</div>
		</div>
	</div>

</div>

<div class="row content">
	<div class="content-element-video-left col-12 col-md-6">
		<p class="content-element-title text-right" [innerHTML]="contents[3].content"></p>
		<video class="img-responsive-video-right" controls>
			<source src="https://dd1studio.pl/aplikacje/3RejestracjaPodopiecznego.mp4" type="video/mp4"/>
		</video>
		<!--<img class="img-responsive-video-left" src="{{contents[3].photo}}" alt="image">-->
	</div>
	<div class="content-element-video-right col-12 col-md-6">
		<p class="content-element-title text-left" [innerHTML]="contents[4].content"></p>
		<video class="img-responsive-video-right" controls>
			<source src="https://dd1studio.pl/aplikacje/1RejestracjaTrenera.mp4" type="video/mp4"/>
		</video>
		<!--<img class="img-responsive-video-right" src="{{contents[4].photo}}" alt="image">-->
	</div>
</div>

<div class="row content">

	<div class="content-element-full-width banner-with-icons col-12 px-0">
		<img class="content-element-full-width-background" src="{{contents[5].photo}}" alt="image">
		<div class="content-element-full-width-content" [innerHTML]="contents[5].content">
		</div>
	</div>
	<div class="content-element-full-width col-12 text-center" [innerHTML]="contents[6].content">
	</div>
	<div class="content-element-column col-12 col-xl-4 px-0" [innerHTML]="contents[7].content">
	</div>
	<div class="content-element-column col-12 col-xl-4 px-0">
		<div class="content-element-column-content" [innerHTML]="contents[8].content">
		</div>
	</div>
	<div class="content-element-column fluid-img col-12 col-xl-4 px-0">
		<img class="img-responsive" src="{{contents[8].photo}}" alt="image">
	</div>
	<div class="content-element-column col-12 col-xl-7 px-0">
		<img class="content-element-column-image img-responsive" src="{{contents[9].photo}}" alt="image">
	</div>
	<div class="content-element-column col-12 col-xl-5 px-0">
		<div class="content-element-column-content-align-left padding-top" [innerHTML]="contents[9].content">
		</div>
	</div>
	<div class="content-element-column col-12 col-xl-12 px-0">
		<div class="content-element-column-content-align-left padding-top-second" [innerHTML]="contents[10].content">
		</div>
		<img class="content-element-column-image-second img-responsive" src="{{contents[10].photo}}" alt="image">
	</div>
	<div class="content-element-column col-12 col-xl-6 px-0">
		<img class="content-element-column-image-third img-responsive" src="{{contents[11].photo}}" alt="image">
	</div>
	<div class="content-element-column col-12 col-xl-6 px-0">
		<div class="content-element-column-content-align-left padding-top-third" [innerHTML]="contents[11].content">
		</div>
	</div>
	<div class="content-element-column col-12 col-xl-12 px-0">
		<div class="content-element-column-content-align-left padding-top-fourth" [innerHTML]="contents[12].content">
		</div>
		<img class="content-element-column-image-fourth img-responsive" src="{{contents[12].photo}}" alt="image">
	</div>
	<div class="content-element-full-width-newsletter col-12 px-0">
		<img class="content-element-full-width-newsletter-background-bottom" src="{{contents[13].photo}}" alt="image">
		<div class="content-element-full-width-newsletter-content" [innerHTML]="contents[13].content">
		</div>
	</div>
	<div class="content-element-full-width-footer col-12 px-0">
		<img class="content-element-full-width-footer-background" src="/assets/img/stopka_tlo.jpg" alt="image" />
		<a [routerLink]="['/']"><img class="content-element-full-width-footer-logo" src="/assets/img/stopka_logo.png" alt="image" /></a>
		<div class="content-element-full-width-footer-content">
			<div class="content-element-full-width-footer-content-buttons">
				<ul class="content-element-full-width-footer-content-buttons-menu">
			      <li class="content-element-full-width-footer-content-buttons-menu__element-social">
			        <a
			          *ngIf="isLogged"
			          [routerLink]="['/bazatrenerow']"
			          class="content-element-full-width-footer-content-buttons-menu__element-anchor-blue"
			          >Baza trenerów</a
			        >
			        <a
			          *ngIf="!isLogged"
			          [routerLink]="['/logowanie']"
			          class="content-element-full-width-footer-content-buttons-menu__element-anchor-blue"
			          >Baza trenerów</a
			        >
			      </li>
			      <li class="content-element-full-width-footer-content-buttons-menu__element-social">
			        <a
			          *ngIf="isLogged"
			          [routerLink]="['/ofertadlatrenerow']"
			          class="content-element-full-width-footer-content-buttons-menu__element-anchor-pink"
			          >Oferta dla trenerów</a
			        >
			        <a
			          *ngIf="!isLogged"
			          [routerLink]="['/logowanie']"
			          class="content-element-full-width-footer-content-buttons-menu__element-anchor-pink"
			          >Oferta dla trenerów</a
			        >
			      </li>
				</ul>
			</div>
			<ul class="content-element-full-width-footer-content-links">
				<li class="content-element-full-width-footer-content-links-item">
					<a
			          [routerLink]="['/cms/1']"
			          class="content-element-full-width-footer-content-links-item-url"
			          >O nas</a>
				</li>
				<li class="content-element-full-width-footer-content-links-item">
					<a
			          [routerLink]="['/cms/2']"
			          class="content-element-full-width-footer-content-links-item-url"
			          >Cennik</a>
				</li>
				<li class="content-element-full-width-footer-content-links-item">
					<a
			          [routerLink]="['/kontakt']"
			          class="content-element-full-width-footer-content-links-item-url"
			          >Kontakt</a>
				</li>
				<li class="content-element-full-width-footer-content-links-item">
					<a
			          [routerLink]="['/cms/3']"
			          class="content-element-full-width-footer-content-links-item-url"
			          >Polityka prywatności</a>
				</li>
				<li class="content-element-full-width-footer-content-links-item">
					<a
			          [routerLink]="['/bazatrenerow']"
			          class="content-element-full-width-footer-content-links-item-url"
			          >Baza trenerów</a>
				</li>
				<li class="content-element-full-width-footer-content-links-item">
					<a
			          [routerLink]="['/ofertadlatrenerow']"
			          class="content-element-full-width-footer-content-links-item-url"
			          >Oferty dla trenerów</a>
				</li>
			</ul>
			<div class="content-element-full-width-footer-content-icons">
				<app-social-icons></app-social-icons>
			</div>
		</div>
	</div>
</div>
